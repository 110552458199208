const debounce = (fn: (...args: unknown[]) => void, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>
  return (...args: unknown[]) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn.apply(this, args), ms)
  }
}

// const debounce = (fn: (...args: unknown[]) => void, delay: number) => {
//   let timeout: NodeJS.Timeout
//   return (...args: unknown[]) => {
//     clearTimeout(timeout)
//     timeout = setTimeout(() => fn(...args), delay)
//   }
// }

export default debounce
