import { cva } from 'class-variance-authority'

export const card = cva([
  'bg-white',
  'border border-gray-300',
  'rounded-xl',
  'p-2 px-4',
])

export const customCard = cva([
  'bg-white',
  'border border-gray-300',
  'rounded-xl',
  ' px-4',
])
