import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button, DeleteSVG } from '~/shared/ui'
import { useStore } from '~/app/store'

export const DeleteCategory = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const path = pathname.split('/')
  const categoryId = path[path.length - 2]

  const { categoriesStore } = useStore()
  const { deleteCategory, isDeleting } = categoriesStore

  const handleClick = () => {
    deleteCategory(categoryId).then(() => navigate('/categories'))
  }

  return (
    <Button
      isLoading={isDeleting}
      lIcon="delete"
      intent="danger"
      onClick={handleClick}
    >
      <DeleteSVG color="white" />

      {t('delete')}
    </Button>
  )
})
