export const Skeleton = () => {
  return (
    <div role="status" className="w-full h-[500px] animate-pulse">
      <div className="h-2.5 bg-gray-200 rounded-full  w-48 mb-4" />
      <div className="h-2 bg-gray-200 rounded-full max-w-[360px] mb-2.5" />
      <div className="h-2 bg-gray-200 rounded-full  mb-2.5" />
      <div className="h-2 bg-gray-200 rounded-full  mb-2.5" />
      <div className="h-2 bg-gray-200 rounded-full  mb-2.5" />
      <div className="h-2 bg-gray-200 rounded-full" />
      <span className="sr-only">Loading...</span>
    </div>
  )
}
