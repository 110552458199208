import { Table } from './components'
import { TableProps } from '~/shared/lib'
import { Skeleton } from './components/skeleton'

export const TableContainer = <T, K extends keyof T>({
  data,
  columns,
  pagination,
  aroundwithout,
  onRowClick,
  contextMenu,
  loadMore,
  fixedWidth,
  isLoading,
  className,
  hasSubRowContent,
  subRowComponent,
}: TableProps<T, K>) => {
  return isLoading ? (
    <Skeleton />
  ) : (
    <div className="w-full">
      <Table
        aroundwithout={aroundwithout}
        className={className}
        columns={columns}
        data={data}
        pagination={pagination}
        onRowClick={onRowClick}
        rowContextMenu={contextMenu}
        loadMore={loadMore}
        fixedWidth={fixedWidth}
        hasSubRowContent={hasSubRowContent}
        subRowComponent={subRowComponent}
      />
    </div>
  )
}
