/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosError } from 'axios'
import { OrdersService } from '~/shared/api/services/orders'
import {
  Orders,
  Filters,
  Loaders,
  EditableOrdertModel,
  Infos,
  HistoryArray,
  Items,
  DigitalProducts,
  Related,
  ProductsArray,
  Shipping,
  Productavailable,
  Attachments,
  OrdersInWork,
  OrderInWorkActions,
} from '~/shared/api/services/orders/types'
import { ProductsService, ShippingModel } from '~/shared/api'
import { StoresService } from '~/shared/api/services/stores'
import {
  Countries,
  Currencie,
  PaymentSystem,
  Regions,
} from '~/shared/api/services/stores/types'
import {
  AvailableProductModel,
  Product,
} from '~/shared/api/services/products/types'
import { Store } from '~/shared/api/services/global/types'

export class OrdersModel {
  [key: string]: any

  tempID = ''

  ordersError = false

  error404 = false

  infos: Infos | null = null

  history: HistoryArray = []

  related: Related[] = []

  loaders: Loaders = {
    orders: false,
    loadMoreOrders: false,
    shipping: false,
    store: false,
    payment: false,
    products: false,
    newDocument: false,
    reshipOrder: false,
    date: false,
    comment: false,
    editRecipient: false,
    shippingByProducts: false,
    availabilitesProducts: false,
    ordersDemandProductVariationId: false,
    paymentCheck: false,
    assembly: false,
    dispatch: false,
    itemsRequired: false,
    shippedLastDay: false,
    removeProductFromOrder: false,
    addProductForOrder: false,
    cancelOrder: false,
  }

  availabilitesProducts: AvailableProductModel[] = []

  availabilitesProductOptions: Productavailable | null = null

  orders: Orders[] = []

  items: Items[] = []

  digitalProducts: DigitalProducts[] = []

  currentOrder: EditableOrdertModel | null = null

  currentOrderProducts: ProductsArray = []

  currencies: Currencie[] = []

  attachments: Attachments[] = []

  ordersInWorkPaymentCheck: OrdersInWork[] = []

  ordersInWorkAssembly: OrdersInWork[] = []

  ordersInWorkDispatch: OrdersInWork[] = []

  ordersInWorkItemsRequired: OrdersInWork[] = []

  ordersInWorkShippedLastDay: OrdersInWork[] = []

  updatedStoreErrors: { status: boolean; updeter: string }[] = []

  requestSettings = {
    limit: 20,
    offset: 20,
    isAllLoaded: false,
  }

  filters: Filters = {
    state: [
      'created',
      'assembly',
      'dispatch',
      'shipped',
      'payment invoice',
      'cancel',
      'completed',
    ],
    products: [],
    store: [],
    shipping: [],
    payment: [],
    orderNumber: '',
    customerName: '',
    customerLastName: '',
    customerEmail: '',
    marketplaceId: '',
    ordersQ: '',
    date: [],
  }

  ordersDemandProductVariationId: Orders[] = []

  productsForFilter: Product[] = []

  originalProductsForChangedFilter: Product[] = []

  shipping: ShippingModel[] = []

  shippingByProducts: Shipping[] = []

  store: Store[] = []

  payment: PaymentSystem[] = []

  state = [
    { name: 'payment', label: 'Payment' },
    { name: 'created', label: 'Created' },
    { name: 'assembly', label: 'Assembly' },
    { name: 'dispatch', label: 'Dispatch' },
    { name: 'shipped', label: 'Shipped' },
    { name: 'checkout', label: 'Checkout' },
    { name: 'payment invoice', label: 'Payment invoice' },
    { name: 'cancel', label: 'Cancel' },
    { name: 'failed', label: 'Failed' },
    { name: 'completed', label: 'Completed' },
  ]

  countries: Countries[] = []

  regions: Regions[] = []

  q = ''

  changedTab = 'listOfOrders'

  constructor() {
    makeAutoObservable(this)
  }

  setTempID = (id: string) => {
    this.tempID = id
  }

  activeLoader = (key: keyof Loaders) => {
    this.loaders = { ...this.loaders, [key]: true }
  }

  closeLoader = (key: keyof Loaders) => {
    this.loaders = { ...this.loaders, [key]: false }
  }

  getShippings = async () => {
    try {
      this.activeLoader('shipping')
      const { data } = await ProductsService.getShipping()
      runInAction(() => {
        this.shipping = data
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.closeLoader('shipping')
      })
    }
  }

  getShippingMethodsByProducts = async () => {
    this.activeLoader('shippingByProducts')
    try {
      if (
        this.currentOrder?.storeId &&
        this.currentOrder?.items?.length !== 0
      ) {
        const { data } = await OrdersService.getShippingMethodsByProducts({
          storeId: this.currentOrder.storeId,
          region: this.currentOrder.recipient.countryZone as string,
          products: this.currentOrderProducts,
        })
        this.shippingByProducts = data
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.closeLoader('shippingByProducts')
      })
    }
  }

  getCurrencies = async () => {
    try {
      if (this.currentOrder?.storeId) {
        const { data } = await OrdersService.getCurrencies(
          this.currentOrder.storeId,
        )
        this.currencies = data
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.closeLoader('shippingByProducts')
      })
    }
  }

  getPayments = async () => {
    try {
      this.activeLoader('payment')
      const { data } = await StoresService.getPaymentSystem()
      runInAction(() => {
        this.payment = data
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.closeLoader('payment')
      })
    }
  }

  getInfos = async (id: string) => {
    try {
      const { data } = await OrdersService.getInfos(id)
      runInAction(() => {
        this.infos = data
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getHistory = async (id: string) => {
    try {
      const { data } = await OrdersService.getHistory(id)
      runInAction(() => {
        this.history = data
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getRelated = async (id: string) => {
    try {
      const { data } = await OrdersService.getRelated(id)
      runInAction(() => {
        this.related = data
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getCountries = async () => {
    try {
      const { data } = await StoresService.getCountries()
      this.countries = data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getRegions = async (newCountry?: string) => {
    try {
      if (this.currentOrder) {
        const { data } = await StoresService.getRegions(
          newCountry || this.currentOrder.recipient.country,
        )
        this.regions = data
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getProducts = async () => {
    try {
      this.activeLoader('products')
      const params = this.q ? { params: { q: this.q } } : {}
      const { data } = await ProductsService.getProducts(params)
      runInAction(() => {
        this.productsForFilter = data
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.closeLoader('products')
      })
    }
  }

  getAvailabilitesProducts = async () => {
    try {
      this.activeLoader('availabilitesProducts')
      if (this.currentOrder?.storeId) {
        const { data } = await ProductsService.getAvailableProducts({
          params: {
            limit: 20,
            offset: 0,
            storeId: this.currentOrder.storeId,
          },
        })
        this.availabilitesProducts = data
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.closeLoader('availabilitesProducts')
      })
    }
  }

  getDemandProductVariationId = async (
    id: string,
    demand: boolean,
    orderNumber?: string,
  ) => {
    this.activeLoader('ordersDemandProductVariationId')
    const params = {
      limit: 20,
      offset: 0,
      orderNumber,
    } as {
      limit: number
      offset: number
      orderNumber?: string
      itemProductVariationId?: string
      state?: string[]
      demandProductVariationId?: string
    }
    if (demand) {
      params.itemProductVariationId = id
      params.state = ['created', 'assembly']
    } else {
      params.demandProductVariationId = id
    }
    if (!orderNumber) {
      delete params.orderNumber
    }
    try {
      const { data } = await OrdersService.getDemandProductVariationId({
        params,
      })

      runInAction(() => {
        this.ordersDemandProductVariationId = data
      })
      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      this.closeLoader('ordersDemandProductVariationId')
    }
  }

  getOrdersInWork = async (action: OrderInWorkActions) => {
    const storeOrdersInWorkActions = {
      paymentCheck: 'ordersInWorkPaymentCheck',
      assembly: 'ordersInWorkAssembly',
      dispatch: 'ordersInWorkDispatch',
      itemsRequired: 'ordersInWorkItemsRequired',
      shippedLastDay: 'ordersInWorkShippedLastDay',
    }

    this.activeLoader(action)
    try {
      let params: any = {}

      params = {
        group: action,
        ...Object.keys(this.filters).reduce((acc, filterKey) => {
          let data = this.filters[filterKey]
          if (data) {
            if (Array.isArray(data)) {
              data = this.getRequestCode(filterKey, data)
            }
            acc[filterKey === 'ordersQ' ? 'q' : filterKey] = data as string
          }
          return acc
        }, {} as { [key: string]: string }),
      }

      const { data } = await OrdersService.getOrdersInWork({
        params,
      })

      runInAction(() => {
        this[storeOrdersInWorkActions[action]] = data
      })
      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      this.closeLoader(action)
    }
  }

  getAvailableProductOptions = async (id: string) => {
    try {
      const { data } = await OrdersService.getAvailableProductOptions(id)
      this.availabilitesProductOptions = data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getAttachments = async (id: string) => {
    try {
      const { data } = await OrdersService.getAttachments(id)
      this.attachments = data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getOrderById = async (id: string) => {
    this.activeLoader('orders')

    try {
      const { data } = await OrdersService.getOrderById(id)
      const { products, ...orderData } = data

      runInAction(() => {
        this.currentOrder = orderData
        this.items = orderData.items
        this.digitalProducts = orderData.digitalProducts
        this.currentOrderProducts = products as ProductsArray
      })
      return data
    } catch (error) {
      this.ordersError = true
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      this.closeLoader('orders')
    }
  }

  getRequestCode = (storeKey: string, arr: string[]) => {
    const storeData = this[storeKey]
    return storeData
      ? storeData
          .filter((elem: any) => arr.includes(elem.name))
          .map((e: any) => e.code || e.id || e.name)
      : arr
  }

  loadOrders = async (action: string) => {
    if (
      action === 'reset'
        ? false
        : this.requestSettings.isAllLoaded ||
          this.ordersError ||
          this.changedTab === 'atWork'
    )
      return
    const loader = action === 'initRender' ? 'resetOrders' : 'loadMoreOrders'
    this.activeLoader(loader)
    try {
      const params = Object.values(this.filters).some((e) => e?.length)
        ? Object.keys(this.filters).reduce((acc, filterKey) => {
            let data = this.filters[filterKey]
            if (data) {
              if (Array.isArray(data)) {
                data = this.getRequestCode(filterKey, data)
              }
              acc[filterKey === 'ordersQ' ? 'q' : filterKey] = data as string
            }
            return acc
          }, {} as { [key: string]: string })
        : {}
      const { data } = await OrdersService.getOrders({
        params: {
          limit: this.requestSettings.limit,
          offset: Object.values(params).some(Boolean)
            ? 0
            : this.requestSettings.offset,
          ...params,
        },
      })

      if (action === 'isVisible') {
        runInAction(() => {
          this.orders = [...this.orders, ...data]
        })
      } else {
        runInAction(() => {
          this.orders = data
        })
      }

      this.requestSettings = {
        ...this.requestSettings,
        isAllLoaded: data.length < this.requestSettings.limit,
        offset: this.requestSettings.offset + this.requestSettings.limit,
      }

      this.closeLoader(loader)
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }
}
