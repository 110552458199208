import { cva } from 'class-variance-authority'

export const input = cva(['outline-none', 'rounded-lg', 'transition-all'], {
  variants: {
    intent: {
      primary:
        'bg-white text-gray-950 border-[1px]  border-b-opacity-[0.1] focus:border-b-[1px] focus:border-b-blue-600',
      secondary:
        'bg-gray-100 text-gray-950 border-[1px]  [&~label]:text-[rgba(112, 112, 112, 1)]  border-gray-300 border-b-gray-700 border-b-opacity-[0.1] focus:border-b-[1px] focus:border-b-gray-900 ',
      disabled: 'bg-gray-200 text-gray-950  cursor-not-allowed',
    },
    size: {
      small: ['text-sm', 'px-3', 'py-[10px]', 'h-[40px]'],
      average: ['text-base', 'pb-[5px] px-[3px]'],
      medium: ['text-base', 'pb-[8px]', 'px-3'],
      big: ['text-lg', 'py-5', 'px-9'],
    },
    status: {
      success: 'text-green-600 border-green-500 placeholder:text-green-00 ',
      error:
        'placeholder:text-red-600 border border-red-500 placeholder-red-700',
    },
  },
  compoundVariants: [
    {
      intent: 'primary',
      size: 'medium',
    },
  ],
  defaultVariants: {
    intent: 'primary',
    size: 'medium',
  },
})
