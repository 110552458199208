import { FC, useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import css from 'classnames'
import { motion } from 'framer-motion'
import { useMediaQuery } from 'usehooks-ts'
import { useTranslation } from 'react-i18next'
import { NavSub } from '../nav-sub'
import { MenuModel } from '~/shared/lib'

interface Props {
  item: MenuModel
  index: number
}

export const NavMenuItem: FC<Props> = ({ item, index }) => {
  const { t } = useTranslation()
  const [heading, setHeading] = useState<number | null>(0)

  const { pathname } = useLocation()

  const isSubMenu = Boolean(item?.subLinks && item?.subLinks?.length > 0)

  const matches = useMediaQuery('(max-width: 768px)')

  useEffect(() => {
    if (matches) {
      setHeading(0)
    }
  }, [pathname, matches])

  const activeLink = item.subLinks?.find((link) => link.link === pathname)

  return (
    <div className="p-0">
      <li
        className={css('inline-block group w-full cursor-pointer', {
          '[&>a>div>span]:opacity-100 [&>a>div>span]:w-full':
            heading === index || activeLink,
        })}
      >
        {isSubMenu ? (
          <Link
            to={item.link}
            onClick={() =>
              heading !== index && matches
                ? setHeading(index)
                : setHeading(null)
            }
            className="md:hover:bg-slate-100  group text-black-700 py-0 md:py-4 px-5 flex gap-2 justify-between w-full"
          >
            <div className="flex items-center relative gap-2 py-3 md:p-0 m-0 ">
              <span className="icon hidden md:block absolute bottom-[-10px]  opacity-0 group-hover:opacity-100 transition left-0 right-0 w-full bg-blue-600 h-[3px] rounded" />
              {t(item.localeTextName)}
            </div>
          </Link>
        ) : (
          <NavLink
            className={({ isActive }) => {
              return css(
                'md:hover:bg-slate-100 relative group text-black-700 flex md:py-4 py-3 px-5 gap-2 cursor-pointer',
                {
                  '[&>div>span]:opacity-100 [&>div>span]:w-full':
                    isActive || activeLink,
                },
              )
            }}
            to={item.link}
          >
            {' '}
            <div className={css('relative flex items-center gap-2')}>
              <span className="icon hidden md:block absolute bottom-[-10px] opacity-0 group-hover:opacity-100 group-hover:w-full transition-all left-0 right-0 w-0 bg-blue-600 h-[3px] rounded" />
              {t(item.localeTextName)}
            </div>
          </NavLink>
        )}

        {isSubMenu ? <NavSub item={item} /> : null}
      </li>

      {/* Mobile items */}
      {matches ? (
        <motion.div
          className={css('bg-slate-50 overflow-hidden ', {
            'md:hidden': heading === index,
            hidden: heading !== index,
          })}
          animate={
            heading === index ? { height: 'fit-content' } : { height: 0 }
          }
        >
          {item.subLinks?.map((link) => (
            <div key={link.localeTextName}>
              <div className="px-3 text-left md:cursor-pointer group">
                <ul>
                  <li className="py-3 pl-5 ">
                    <Link to={link.link}>{link.name}</Link>
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </motion.div>
      ) : null}
    </div>
  )
}
