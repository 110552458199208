import css from 'classnames'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'usehooks-ts'
import { motion } from 'framer-motion'
import { NavUser, Navigation } from './components'
import { headerAnimation } from './model'
import { SVGCloseButton, SVGMenu } from '~/shared/ui'
import { SVGLogo } from '~/shared/ui/SVG/SVGLogo'

export const Header = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { pathname } = useLocation()
  const matches = useMediaQuery('(max-width: 768px)')

  const toggleMenu = () => setIsOpen((prev) => !prev)

  useEffect(() => {
    if (matches) {
      setIsOpen(false)
    }
  }, [pathname, matches])

  return (
    <header className="bg-white px-4">
      <motion.div
        initial="closed"
        animate={['closed', 'open']}
        variants={headerAnimation}
        className="flex items-center font-medium justify-center"
      >
        <div className="text-lg font-bold z-[999] md:w-auto w-full flex justify-between items-center">
          <Link to="/" className="p-0">
            <SVGLogo width="42" height="42" />
          </Link>

          <div
            className="md:hidden flex items-center justify-center"
            onClick={toggleMenu}
          >
            {isOpen ? <SVGCloseButton size="34" /> : <SVGMenu size="34" />}
          </div>
        </div>

        <nav className="mr-auto ml-5 md:flex hidden">
          <Navigation />
        </nav>

        <div className="ml-5 md:block hidden">
          <NavUser />
        </div>

        <nav
          className={css(
            'md:hidden bg-white  absolute w-full h-full bottom-0 pt-20 pb-24 p-2 duration-500 z-50',
            { 'left-0': isOpen },
            { 'left-[-100%]': !isOpen },
          )}
        >
          <Navigation className="flex-col [&>*]:px-0 [&>*]:py-0 mb-4" />

          <NavUser />
        </nav>
      </motion.div>
    </header>
  )
}
