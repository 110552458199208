export const sidebarAnimation = {
  open: {
    width: '18rem',
    transition: {
      damping: 40,
    },
  },
  closed: {
    width: '4.5rem',
    transition: {
      damping: 40,
    },
  },
}

export const mobileSidebarAnimation = {
  open: {
    x: 0,
    width: '16rem',
    transition: {
      damping: 40,
    },
  },
  closed: {
    x: -250,
    width: 0,
    transition: {
      damping: 40,
      delay: 0.05,
    },
  },
}
