import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosError } from 'axios'
import {
  Brand,
  Category,
  CreateFeatureModel,
  CreateMaterialModel,
  EditableProductModel,
  Feature,
  Item,
  Manufacturer,
  Material,
  Option,
  Options,
  Product,
  ProductsService,
  ShippingModel,
} from '~/shared/api'
import { mapDataForSelect, parseQuery } from '~/shared/lib'
import { PRODUCTS_STATUSES } from '../lib'
import { FilterName, ProductType, Status } from './types'
import { globalStore } from '~/shared/store/global-store'

export class ProductsModel {
  manufacturer: Manufacturer[] = []

  options: Option[] = []

  stores = globalStore.stores

  getStores = globalStore.getStores

  categories: Category[] = []

  statuses: Status[] = PRODUCTS_STATUSES

  shipping: ShippingModel[] = []

  materials: Material[] = []

  features: Feature[] = []

  brands: Brand[] = []

  requiredLocalizations: string[] | null = null

  items: Item[] = []

  optionsItem: Options | null = null

  // Settings for infinite scroll
  requestSettings = {
    limit: 20,
    offset: 20,
    isAllLoaded: false,
  }

  products: Product[] = []

  currentProduct: EditableProductModel | null = null

  error: null | string = null

  loadedAllFilters = false

  categoriesLoading = false

  manufacturerLoading = false

  storesLoading = false

  optionsLoading = false

  productsLoading = false

  productsError = false

  loadingMoreProducts = false

  isCreatingProduct = false

  isAddingMaterial = false

  isAddingFeature = false

  isGettingProduct = false

  isUpdatingProduct = false

  productsSubContentOpens: string[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setIdsSubContentOpens = (id: string) => {
    this.productsSubContentOpens = this.productsSubContentOpens.includes(id)
      ? this.productsSubContentOpens.filter((e) => e !== id)
      : [...this.productsSubContentOpens, id]
  }

  getFilters = async () => {
    return Promise.all([
      this.getManufacturers(),
      this.getCategories(),
      this.getOptions(),
    ])
      .then((res) => {
        runInAction(() => {
          const [manufacturer, categories, options] = res
          this.manufacturer = manufacturer
          this.categories = categories
          this.options = options
        })
      })
      .finally(() => {
        runInAction(() => {
          this.loadedAllFilters = true
        })
      })
  }

  getCategories = async () => {
    this.categoriesLoading = true

    try {
      const { data } = await ProductsService.getCategories()

      runInAction(() => {
        this.categories = data
      })

      return data
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.categoriesLoading = false
      })
    }

    return []
  }

  getShipping = async () => {
    try {
      const { data } = await ProductsService.getShipping()

      runInAction(() => {
        this.shipping = data
      })

      return data
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }

    return []
  }

  getManufacturers = async () => {
    this.manufacturerLoading = true

    try {
      const { data } = await ProductsService.getManufacturers()

      runInAction(() => {
        this.manufacturer = data
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.manufacturerLoading = false
      })
    }

    return []
  }

  getOptions = async (productAvailabilityId?: string) => {
    this.optionsLoading = true

    try {
      const { data } = await ProductsService.getOptions({
        params: {
          productAvailabilityId,
        },
      })

      runInAction(() => {
        this.options = data.map((item) => ({
          ...item,
          active: false,
          enabled: false,
        }))
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.optionsLoading = false
      })
    }

    return []
  }

  getProducts = async (paramsFilter?: string) => {
    this.productsLoading = true
    this.productsError = false

    this.currentProduct = null

    const params = ProductsModel.getParamsFromQueryString(paramsFilter || '')

    try {
      const { data } = await ProductsService.getProducts({
        params,
      })
      runInAction(() => {
        this.products = data
        this.requestSettings = {
          ...this.requestSettings,
          isAllLoaded: data.length < this.requestSettings.limit,
        }
      })

      return data
    } catch (error) {
      this.productsError = true
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.productsLoading = false
      })
    }
  }

  getItems = async (id: string) => {
    try {
      const { data } = await ProductsService.getItems(id)
      this.items = data.items
      this.optionsItem = data.options
      return data
    } catch (error) {
      this.productsError = true
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.productsLoading = false
      })
    }
  }

  loadProducts = async (paramsFilter?: string) => {
    if (this.requestSettings.isAllLoaded || this.productsError) return

    this.loadingMoreProducts = true

    const params = ProductsModel.getParamsFromQueryString(paramsFilter || '')

    try {
      const { data } = await ProductsService.getProducts({
        params: {
          ...params,
          limit: this.requestSettings.limit,
          offset: this.requestSettings.offset,
        },
      })

      runInAction(() => {
        this.products = [...this.products, ...data]

        this.requestSettings = {
          ...this.requestSettings,
          isAllLoaded: data.length < this.requestSettings.limit,
          offset: this.requestSettings.offset + this.requestSettings.limit,
        }
        this.loadingMoreProducts = false
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.loadingMoreProducts = false
      })
    }
  }

  getMaterials = async () => {
    try {
      const { data } = await ProductsService.getMaterials()

      runInAction(() => {
        this.materials = data
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  createProduct = async (product: unknown) => {
    this.isCreatingProduct = true

    try {
      const { data } = await ProductsService.createProduct(product)

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw error.response?.data
      }
    } finally {
      this.currentProduct = null
      this.isCreatingProduct = false
    }
  }

  addMaterial = async (res: CreateMaterialModel) => {
    runInAction(() => {
      this.isAddingMaterial = true
    })

    try {
      const { data } = await ProductsService.addMaterial(res)
      runInAction(() => {
        this.materials = [data, ...this.materials]
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.isAddingMaterial = false
      })
    }
  }

  addFeature = async (res: CreateFeatureModel) => {
    runInAction(() => {
      this.isAddingFeature = true
    })

    try {
      const { data } = await ProductsService.addFeature(res)

      runInAction(() => {
        this.features = [data, ...this.features]
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.isAddingFeature = false
      })
    }
  }

  getFeatures = async () => {
    try {
      const { data } = await ProductsService.getFeatures()

      runInAction(() => {
        this.features = data
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getRequiredLocalization = async () => {
    try {
      const { localizations } = await ProductsService.getRequiredLocalization()
      runInAction(() => {
        this.requiredLocalizations = localizations
      })
      return localizations
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getBrands = async () => {
    try {
      const { data } = await ProductsService.getBrands()

      runInAction(() => {
        this.brands = data
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  updateProduct = async (id: string, res: unknown) => {
    runInAction(() => {
      this.isUpdatingProduct = true
    })

    try {
      await ProductsService.updateProduct(id, res)
    } catch (error) {
      if (error instanceof AxiosError) {
        throw error.response?.data
      }
    } finally {
      runInAction(() => {
        this.isUpdatingProduct = false
      })
    }
  }

  getProductById = async (
    id: string,
    cb: (data: EditableProductModel) => void,
  ) => {
    runInAction(() => {
      this.isGettingProduct = true
    })

    try {
      const { data } = await ProductsService.getProductById(id)

      runInAction(() => {
        cb(data)
        this.currentProduct = data
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.isGettingProduct = false
      })
    }
  }

  getFilterByName = async (name: FilterName, value: string) => {
    try {
      const { data } = await ProductsService.getFilterByName(name, {
        params: {
          ...(value.length > 0 && { q: value, limit: 20, offset: 0 }),
        },
      })

      runInAction(() => {
        this.setFilter(name, data)
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }

    return []
  }

  private setFilter(name: FilterName, data: unknown) {
    runInAction(() => {
      if (name === 'manufacturer') {
        this.manufacturer = data as Manufacturer[]
      }

      if (name === 'options') {
        this.options = data as Option[]
      }

      if (name === 'categories') {
        this.categories = data as Category[]
      }
    })
  }

  // Mappers
  get getMappedSelectFilters() {
    return {
      manufacturers: mapDataForSelect(this.manufacturer, 'id', 'name'),
      options: mapDataForSelect(this.options, 'id', 'name'),
      categories: mapDataForSelect(this.categories, 'id', 'name'),
      stores: mapDataForSelect(this.stores, 'id', 'name'),
      materials: mapDataForSelect(this.materials, 'id', 'description'),
      materialsRu: mapDataForSelect(
        this.materials.map((item) => item.localizations[0]),
        'product_material_id',
        'description',
      ),
      featuresRu: mapDataForSelect(
        this.features.map((item) => item.localizations[0]),
        'product_feature_id',
        'title',
      ),
      features: mapDataForSelect(this.features, 'id', 'title'),
      brands: mapDataForSelect(this.brands, 'id', 'name'),
      shipping: mapDataForSelect(this.shipping, 'code', 'name'),
    }
  }

  static getParamsFromQueryString(value?: string) {
    if (value?.length === 0) return {}

    const { q, sku, vendorCode, ...res } = parseQuery(value || '')

    const productName = q && q.join('').length > 0 ? { q: q.join('') } : {}

    const productSku =
      sku && sku.join('').length > 0 ? { sku: sku.join('') } : {}

    const productVendorCode =
      vendorCode && vendorCode.join('').length > 0
        ? { vendorCode: vendorCode.join('') }
        : {}

    return {
      ...res,
      ...productName,
      ...productVendorCode,
      ...productSku,
    }
  }
}

export const productTypes: ProductType[] = [
  {
    id: 1,
    value: 'physical',
    title: 'физический',
    icon: 'inventory_2',
  },
  {
    id: 2,
    value: 'digital',
    title: 'Цифровой',
    icon: 'upload_file',
  },
]

export const productCreateImageType = [
  { id: 1, type: 'image_cover', label: 'Обложка' },
  { id: 2, type: 'image_hover', label: 'При наведении' },
]
