/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectOption } from '~/shared/ui'

export function parseQuery(queryString: string) {
  if (queryString.length === 0) return {}

  const query: Record<string, string[]> = {}
  const pairs = (
    queryString[0] === '?' ? queryString.substring(1) : queryString
  ).split('&')

  for (let i = 0; i < pairs.length; i += 1) {
    const pair = pairs[i].split('=')

    const key = decodeURIComponent(pair[0])

    const data = decodeURIComponent(pair[1]).split(',')

    if (data.join('').length > 0) {
      if (query[key]) {
        query[key] = [...query[key], ...data]
      } else {
        query[key] = data
      }
    }
  }

  return query
}

export function parseFormData<T extends Record<string, any>>(formData: {
  [key in keyof T]: string | SelectOption | null | Date
}): T {
  const convertedObj = {} as T

  // Приводим key к типу keyof T
  Object.keys(formData).forEach((key) => {
    const typedKey = key as keyof T

    if (formData[typedKey] && typeof formData[typedKey] === 'object') {
      const tempObj = formData[typedKey] as SelectOption
      convertedObj[typedKey] = tempObj.value as T[keyof T]
    } else {
      convertedObj[typedKey] = formData[typedKey] as T[keyof T]
    }
  })

  return convertedObj
}
