import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { apiService } from '../..'
import { ImageModel, Store } from './types'

export class GlobalService {
  static getStores(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Store[]>> {
    return apiService.get<AxiosResponse<Store[]>>(`/stores`, config)
  }

  static uploadImage(data: FormData): Promise<ImageModel> {
    return apiService.post<ImageModel>('/upload', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}
