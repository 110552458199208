import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useStore } from '~/app/store'
import { useForm, useToast } from '~/shared/lib'
import { Button, Card } from '~/shared/ui'
import { Input } from '~/shared/ui/input'
import { SVGLogo } from '~/shared/ui/SVG/SVGLogo'

export const Login = observer(() => {
  const { t } = useTranslation()
  const { authStore, globalStore } = useStore()
  const navigate = useNavigate()
  const toast = useToast()

  const [isShownPassword, setIsShowPassword] = useState<boolean>(false)

  const { handleSubmit, handleChange, errors } = useForm({
    validations: {
      email: {
        pattern: {
          value: /^\S+@\S+\.\S+$/,
          message: 'Invalid email',
        },
      },
      password: {
        custom: {
          isValid: (value) => value.length >= 6,
          message: 'Пароль должен содержать не менее 6 символов',
        },
      },
    },
    onSubmit: (data) => {
      authStore
        .login(data.email, data.password)
        .then(async () => {
          await globalStore.getStores()
          navigate('/')
        })
        .catch((err: string[]) => {
          toast.open(
            err.length ? err.join(', ') : 'Invalid user data!',
            'danger',
          )
        })
    },
  })

  const togglePassword = () => setIsShowPassword((prev) => !prev)

  return (
    <div className="w-full">
      <Card className="p-8">
        <div className="w-full flex justify-center mb-8">
          <div className="cursor-pointer">
            <SVGLogo width="128" height="128" />
          </div>
        </div>

        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <Input
            name="email"
            id="email"
            type="email"
            label={t('email')}
            labelType="floating"
            className="text-red"
            onChange={handleChange('email')}
            helperText={errors.email && errors.email}
            status={errors.email ? 'error' : undefined}
          />

          <Input
            name="password"
            id="password"
            type={isShownPassword ? 'text' : 'password'}
            label={t('password')}
            labelType="floating"
            showPassword={togglePassword}
            onChange={handleChange('password')}
            helperText={errors.password && errors.password}
            status={errors.password ? 'error' : undefined}
          />

          <Button
            isLoading={authStore.isLoading}
            type="submit"
            className="flex items-center justify-center gap-2"
            lIcon="login"
          >
            {t('login')}
          </Button>
        </form>
      </Card>
    </div>
  )
})
