export const headerAnimation = {
  closed: {
    height: '0',
    transition: {
      damping: 40,
    },
  },
  open: {
    height: '56px',
    transition: {
      damping: 40,
    },
  },
}
