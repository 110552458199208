/* eslint-disable react/require-default-props */
import { FC } from 'react'
import { CardProps as BaseCardProps } from './types'
import { card, customCard } from './config'

type CardProps = BaseCardProps & {
  isCustom?: boolean
}

export const Card: FC<CardProps> = ({
  children,
  className,
  onClick,
  isCustom = false,
}) => {
  const cardClass = isCustom ? customCard : card
  return (
    <div onClick={onClick} className={cardClass({ className })}>
      {children}
    </div>
  )
}
