import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { Item } from '~/shared/api'
import { ArrowToDown } from '~/shared/ui'
import { SVGDone } from '~/shared/ui/SVG/SVGDone'
import { SVGNOtFoto } from '~/shared/ui/SVG/SVGNotFoto'

export const ArrowToDownProduct = observer((props: { originalData: Item }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  return (
    <>
      <div
        className="flex items-center justify-between w-[100%]"
        onClick={() => {
          setOpen(!open)
        }}
      >
        <div className="pl-2 w-[70%] whitespace-normal">
          {props?.originalData?.name}
        </div>
        <motion.div
          className="flex justify-center"
          animate={{ rotate: open ? 180 : 0 }}
          initial={{ rotate: 0 }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
        >
          <ArrowToDown color="gray" width={26} height={26} />
        </motion.div>
      </div>
      {open && (
        <div className=" border border-gray-200 rounded-lg m-6 p-3 ">
          <div className="flex p-3 justify-between w-[50%]">
            {props.originalData?.details?.image?.original ? (
              <img
                src={props.originalData?.details?.image?.original}
                alt="Foto"
                className="w-[100px] h-[100px] object-cover sm: pr-4"
              />
            ) : (
              <SVGNOtFoto size="100" />
            )}

            <div className="bg-[#E8F5E9] rounded-lg w-[165px] h-[45px] flex justify-between p-2 items-center">
              <SVGDone size="20px" color="#4CAF50" strokeWidth="3" />
              <strong className="text-[#4CAF50] text-[14px] sm:p-3">
                {t('inStock')}
              </strong>
              <p className="text-white bg-[#4CAF50] w-[40px] h-[25px] pt-0.5 text-center rounded-xl">
                {props.originalData?.count}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className=" border border-gray-200 w-[70%] rounded-lg">
              <div className="bg-[#EBEBEB] rounded-lg flex  h-[45px] justify-between items-center p-3 opacity-70 ">
                <SVGDone size="20px" color="#242424" strokeWidth="3" />

                <strong> {t('InTheOrder')} </strong>
                <strong className="text-black bg-gray-400 w-[40px] h-[25px] rounded-xl text-center pt-0.5">
                  {props.originalData?.countInOrders}
                </strong>
              </div>
              <div className="flex justify-around  p-3 border-b-2">
                <p className="text-gray-600 pl-2 ">{t('order number')}</p>
                <p className="text-gray-600"> {t('quantity')}</p>
              </div>
              {Object.keys(props.originalData.orders).map((orderId, i, arr) => (
                <div
                  className={`flex justify-around  p-3  ${
                    arr.length - 1 === i ? '' : 'border-b-2'
                  }`}
                  key={orderId}
                >
                  <p className="text-gray-600 ">
                    {props.originalData.orders[orderId].number}
                  </p>
                  <p className="text-gray-600">
                    {props.originalData.orders[orderId].quantity}
                  </p>
                </div>
              ))}
            </div>
            <div className=" border border-gray-200 w-[70%] rounded-lg">
              <div className="bg-[#FFEBEE] rounded-lg flex  h-[45px] justify-between items-center p-3">
                <SVGDone size="20px" color="#FF5252" strokeWidth="3" />

                <strong className="text-[#FF5252]">{t('IsRequired')} </strong>
                <strong className="text-white bg-[#FF5252] w-[40px] h-[25px] rounded-xl text-center pt-0.5">
                  {props.originalData?.countInDemands}
                </strong>
              </div>
              <div className="flex justify-between  p-3">
                <p className="text-gray-600">{t('order number')}</p>
                <p className="text-gray-600">{t('quantity')}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
})
