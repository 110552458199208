import { AxiosResponse } from 'axios'
import { apiService } from '../..'
import { UpdateupdateRegistrationData } from './types'

export class RegistarationService {
  static updateRegistration(
    data: UpdateupdateRegistrationData,
  ): Promise<AxiosResponse> {
    return apiService.post<AxiosResponse>('/legalEntityAndStore', data)
  }

  static validate(data: UpdateupdateRegistrationData): Promise<AxiosResponse> {
    return apiService.post<AxiosResponse>('/legalEntityAndStore/validate', data)
  }
}
