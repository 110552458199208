import { observer } from 'mobx-react-lite'
import { useStore } from '~/app/store'
import { ProductsCategoriesFilters } from '~/features/products/categories'
import Spinner from '~/shared/ui/spinner'

export const StoresFilterWidget = observer(() => {
  const { categoriesStore, globalStore } = useStore()
  const { storesLoading } = categoriesStore
  const { stores } = globalStore

  return storesLoading ? (
    <div className="my-10 flex items-center justify-center">
      <Spinner />
    </div>
  ) : (
    <ProductsCategoriesFilters data={stores} />
  )
})
