import { observer } from 'mobx-react-lite'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useStore } from '~/app/store'
import { PageTitle, ToolTip, SVGStore } from '~/shared/ui'
import Spinner from '~/shared/ui/spinner'
import { SVGNOtFoto } from '~/shared/ui/SVG/SVGNotFoto'

export const CategoryEditTitleWidget = observer(() => {
  const { t } = useTranslation()

  const { categoriesStore } = useStore()
  const { createCategoryData, isGettingCategory, currentEditableCategory } =
    categoriesStore
  const { pathname } = useLocation()

  const paths = pathname.split('/')
  const lastPath = paths[paths.length - 1]
  const isEditable = lastPath === 'edit'

  if (isGettingCategory) {
    return <Spinner />
  }

  return isEditable ? (
    <div className="flex items-center gap-3">
      <SVGStore />

      <div className="flex items-center gap-3">
        <PageTitle
          title={
            currentEditableCategory?.localizations[0].name ||
            createCategoryData.category.slug ||
            ''
          }
        />
        <ToolTip
          clicked
          iconColor="text-[#242424]"
          iconSize={24}
          tooltipTitle={t('defaultToolTipTitle')}
          tooltipText={t('defaultToolTipText')}
        />
      </div>

      <div className="flex items-center gap-2">
        {typeof createCategoryData?.category?.seo?.storeLogo === 'string' ? (
          <img
            className="w-8 h-8"
            src={createCategoryData?.category?.seo?.storeLogo || ''}
            alt="store_logo"
          />
        ) : (
          <SVGNOtFoto size="32" />
        )}
        <div className="flex flex-col">
          <p className="text-sm">{createCategoryData.category.store?.name}</p>
          <Link
            to={`https://${createCategoryData.category.store?.domain}/category/${createCategoryData.category.slug}`}
            className="text-sm font-light text-slate-400 cursor-pointer"
            target="_blank"
          >
            {createCategoryData.category.store?.domain}/category/
            {createCategoryData.category.slug}
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex items-center gap-3">
      <PageTitle title={t('createNewCategory')} />
      <ToolTip
        iconSize={22}
        tooltipTitle={t('defaultToolTipTitle')}
        tooltipText={t('defaultToolTipText')}
      />
    </div>
  )
})
