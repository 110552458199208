import { FC } from 'react'
import { Props } from './types'

export const PageTitle: FC<Props> = ({
  title,
  color = 'text-title-color',
  size = 'text-[30px]',
  fontWeight = 'font-medium',
}) => {
  return (
    <div className="flex items-center gap-3">
      <h1 className={`${fontWeight} ${color} ${size}`}>{title}</h1>
    </div>
  )
}
