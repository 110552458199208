import { ChangeEvent, FC, KeyboardEvent, useState } from 'react'
import { useUpdateEffect } from 'usehooks-ts'
import { Props } from './types'
import { generateUEID } from '~/shared/lib/helper-functions/ueid'

export const TagInput: FC<Props> = ({
  placeholder,
  onChange,
  name,
  defaultData = [],
  renderIconEnd,
}) => {
  const [input, setInput] = useState('')
  const [tags, setTags] = useState<string[]>(defaultData)
  const [isKeyReleased, setIsKeyReleased] = useState(false)

  useUpdateEffect(() => {
    onChange?.(tags, name)
  }, [tags])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setInput(value)
  }

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const { key } = e
    const trimmedInput = input.trim()

    if (
      key === 'Enter' &&
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      e.preventDefault()
      setTags((prevState) => [...prevState, trimmedInput])
      setInput('')
    }

    if (key === 'Backspace' && !input.length && tags.length && isKeyReleased) {
      e.preventDefault()
      const tagsCopy = [...tags]
      const poppedTag = tagsCopy.pop() || ''

      setTags(tagsCopy)
      setInput(poppedTag)
    }

    setIsKeyReleased(false)
  }

  const onKeyUp = () => {
    setIsKeyReleased(true)
  }

  const handleDelete = (item: string) => {
    setTags((prevState) => prevState.filter((tag) => tag !== item))
  }

  return (
    <div className="w-auto relative flex overflow-x-auto items-center gap-2  bg-white p-2 rounded border border-gray-300 focus-within:border-b-blue-600 border-b-gray-400  flex-wrap">
      {tags.map((tag) => (
        <div
          key={tag + generateUEID()}
          className="bg-gray-200  flex items-center gap-1 py-0.5 px-2 rounded"
        >
          {tag}

          <button
            onClick={() => handleDelete(tag)}
            className="outline-none bg-transparent hover:text-red-500 transition-all"
          >
            &times;
          </button>
        </div>
      ))}
      <input
        value={input}
        name={name}
        className="outline-none p-[6px] w-full min-w-[50%] bg-t bg-transparent focus:placeholder:text-transparent"
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        onChange={handleChange}
        onKeyUp={onKeyUp}
      />

      {renderIconEnd ? (
        <div className="absolute right-0">{renderIconEnd}</div>
      ) : null}
    </div>
  )
}
