import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { MultiValue } from 'react-select'
import { useLocalStorage } from 'usehooks-ts'
import { SelectOption } from '~/shared/ui'
import { parseJSON, removeEmptyKeys } from '../../helper-functions'

interface Props {
  selectData: {
    [key: string]: SelectOption[]
  }
  savedFiltersKey: string
}

export const useFilters = (props: Props) => {
  const [params, setParams] = useSearchParams()
  const [savedFilters, setSavedFilters] = useLocalStorage(
    props.savedFiltersKey,
    parseJSON<MultiValue<SelectOption>>(
      localStorage.getItem(props?.savedFiltersKey) || '',
    ) || {},
  )
  const [activeFilters, setActiveFilters] = useState<
    Record<string, MultiValue<SelectOption>>
  >(savedFilters || {})

  const { pathname } = useLocation()

  useEffect(() => {
    return () => {
      setSavedFilters({})
      setActiveFilters({})
    }
  }, [pathname, setSavedFilters])

  const selectsDataKeys = useMemo(
    () => ({ ...props.selectData }),
    [props.selectData],
  )

  const getSelectActiveData = useCallback(
    (key: string) => {
      const activeParams = params.get(key)?.split(',').filter(Boolean) || []

      return activeParams.map((value) => {
        const selectedLabel =
          selectsDataKeys[key].find((item) => item.value === value)?.label || ''

        return {
          label: selectedLabel,
          value,
        }
      })
    },
    [params, selectsDataKeys],
  )

  const getActiveFiltersData: (key: string) => MultiValue<SelectOption> =
    useCallback(
      (key: string) => {
        return activeFilters[key]?.length > 0
          ? activeFilters[key]
          : getSelectActiveData(key)
      },
      [activeFilters, getSelectActiveData],
    )

  const clearAllFilters = useCallback(() => {
    setParams({})
    setActiveFilters({})
    setSavedFilters({})
  }, [setParams, setSavedFilters])

  const handleFilter = useCallback(
    (key: string, value: MultiValue<SelectOption | string>) => {
      const values = value.map((item) => {
        return typeof item === 'string' ? item : item.value
      })

      const prevQuery = [...params.entries()].reduce((acc, item) => {
        const [name, id] = item

        acc[name] = id

        return acc
      }, {} as Record<string, string>)

      const payload = removeEmptyKeys({
        ...prevQuery,
        [key]: values.join(','),
      })

      setActiveFilters((prev) => ({ ...prev, [key]: value as SelectOption[] }))

      setSavedFilters({
        ...savedFilters,
        [key]: value,
      })

      setParams(payload)
    },
    [setParams, params, savedFilters, setSavedFilters],
  )

  const isEmptyFilters = useMemo(() => {
    const filters = [...params.entries()].reduce((acc, item) => {
      acc.push(item[1])

      return acc
    }, [] as string[])

    return filters.join('').length === 0
  }, [params])

  return {
    handleFilter,
    getSelectActiveData,
    clearAllFilters,
    isEmptyFilters,
    getActiveFiltersData,
  }
}
