import { VariantType } from './type'
// переменная для модалки скидок и купонов
export const VARIANT_TYPES: VariantType[] = [
  {
    id: 1,
    path: 'product',
    value: 'All',
    title: 'Tовары',
    titleCoupon: 'Все товары',
    icon: 'product',
  },
  {
    id: 2,
    path: 'category',
    value: 'Category',
    title: 'Категория',
    icon: 'category',
    titleCoupon: 'Категория',
  },
]
// переменная для модалки купонов
export const TYPES_COUPON: VariantType[] = [
  {
    id: 1,
    value: 'Percent',
    title: 'Процент',
    icon: 'percent',
    titleCoupon: 'Процент',
  },
  {
    id: 2,
    value: 'Fixed',
    title: 'На сумму',
    icon: 'forTheAmountOf',
    titleCoupon: 'На сумму',
  },
]
