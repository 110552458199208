import { observer } from 'mobx-react-lite'
import { Navigate } from 'react-router-dom'
import { useStore } from '~/app/store'

const RequireAuth = observer(({ children }: { children: JSX.Element }) => {
  const { authStore } = useStore()

  if (!authStore.isAuth) {
    return <Navigate to="/login" replace />
  }

  return children
})

export default RequireAuth
