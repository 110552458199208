import { MenuModel } from '../../types'

// localeTextName - это ключ, который мы передаем в функцию "t" из библиотека react-i18next, чтобы отобржать язык в завимисти, что выбрал польльзователь

export const menuLinks: MenuModel[] = [
  {
    name: 'Orders ',
    link: '/orders',
    icon: 'orders',
    localeTextName: 'Orders',
  },
  {
    name: 'Products',
    link: '/products',
    icon: 'list_alt',
    localeTextName: 'products',
    subLinks: [
      {
        name: 'List',
        link: '/products',
        localeTextName: 'list',
      },
      {
        name: 'Categories',
        link: '/categories',
        localeTextName: 'categories',
      },
    ],
  },
  {
    name: 'Users',
    link: '/users',
    icon: 'person',
    localeTextName: 'users',
  },
  {
    name: 'Store',
    link: '/stores',
    icon: 'stores',
    localeTextName: 'Store',
  },
  {
    name: 'warehouse',
    link: '/incoming',
    icon: 'warehouse',
    localeTextName: 'warehouse',
  },
  {
    name: 'marketing',
    link: '/discounts',
    icon: 'marketing',
    localeTextName: 'marketing',
    subLinks: [
      {
        name: 'discounts',
        link: '/discounts',
        localeTextName: 'discounts',
      },
      {
        name: 'coupons',
        link: '/coupons',
        localeTextName: 'coupons',
      },
      {
        name: 'slider',
        link: '/slider',
        localeTextName: 'slider',
      },
    ],
  },
]

export const iconMenuLinks = [
  { link: '/settings', icon: 'settings' },
  { link: '/orders', icon: 'orders' },
  { link: '/incoming', icon: 'warehouse' },
]
