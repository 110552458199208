/* eslint-disable no-param-reassign */
import { useRef } from 'react'
import css from 'classnames'
import { XYCoord, useDrag, useDrop } from 'react-dnd'
import { Link } from 'react-router-dom'
import { NestedCategories } from '~/shared/api'
import { moveElement, replaceCategoriesById } from '../../lib/helpers'
import { SVGDragIndicator, SVGEye, SVGPencil } from '~/shared/ui'

export interface Props {
  data: NestedCategories
  subList: NestedCategories[]
  list: NestedCategories[]
  index: number
  parentItem: NestedCategories
  setList: (data: NestedCategories[]) => void
  updated: () => void
}

export const SubItem3 = ({
  data,
  index,
  parentItem,
  list,
  subList,
  setList,
  updated,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const dragPreviewRef = useRef<HTMLDivElement | null>(null)

  const [, drop] = useDrop({
    accept: 'sub-category-3',

    hover: (item: NestedCategories & { index: number }, monitor) => {
      if (!dragPreviewRef.current) return

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) return

      const hoveredRect = dragPreviewRef.current.getBoundingClientRect()
      const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2
      const mousePosition = monitor.getClientOffset() as XYCoord
      const hoverClientY = mousePosition.y - hoveredRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

      const newData = moveElement(subList, dragIndex, hoverIndex)
      const newList = replaceCategoriesById(list, parentItem.id, newData)

      setList(newList)
      updated()

      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'sub-category-3',
    item: { ...data, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(ref)
  drop(preview(dragPreviewRef))

  return (
    <div
      ref={dragPreviewRef}
      className={css(
        'category active:overflow-hidden bg-gray-100  w-full overflow-hidden rounded-lg border border-[#E0E0E0]',
        {
          'opacity-50': isDragging,
        },
      )}
      data-id={data.id}
      data-index={index}
    >
      <div className="flex items-center justify-between gap-3 h-[53px]">
        <div
          ref={ref}
          className="flex item-center justify-center p-3 cursor-grab active:cursor-grabbing"
        >
          <SVGDragIndicator />
        </div>

        <div className="flex items-center">
          <h2
            className={css('text-[18px] truncate mx-auto', {
              'opacity-50': !data.showInMenu,
            })}
          >
            {data.localizations[0].name || data.name}
          </h2>

          <Link
            to={`/categories/${data.id}/edit`}
            className="mx-2 flex items-center justify-center group p-3 pl-0"
          >
            <SVGPencil />
          </Link>

          {data.showInMenu ? null : (
            <div className={css('flex items-center gap-2 text-gray-400')}>
              <SVGEye icon="visibilityOff" />
              lllll
              <p className={css({ 'opacity-60': !data.showInMenu })}>Скрыт</p>
            </div>
          )}
        </div>

        <div className="pr-3">
          {data?.categories?.length ? (
            <span className="bg-red-500 rounded-full px-1.5 h-5 text-[12px] text-white flex items-center justify-center">
              {data.categories.length}
            </span>
          ) : (
            <span />
          )}
        </div>
      </div>
    </div>
  )
}
