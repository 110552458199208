import { motion } from 'framer-motion'
import { useCallback, useEffect, useMemo, useState } from 'react'
import css from 'classnames'
import { useLocation } from 'react-router-dom'
import { NavMenu } from './components'
import { mobileSidebarAnimation, sidebarAnimation } from './model'
import { SidebarHeader } from './components/header'
import { menuLinks, useWindowSize } from '~/shared/lib'
import { SVGMenu } from '~/shared/ui'

export const SideBar = () => {
  const { width } = useWindowSize()
  const { pathname } = useLocation()

  const isTab = useMemo(() => !!(Number(width) < 768), [width])

  const [isOpen, setIsOpen] = useState(!isTab)
  const toggleMenu = useCallback(() => setIsOpen((prev) => !prev), [])
  const handleOpenMenu = useCallback(() => setIsOpen(true), [])

  useEffect(() => {
    if (isTab) {
      // mobile
      setIsOpen(false)
    } else {
      // laptop
      setIsOpen(true)
    }
  }, [isTab])

  // close when path changes only mobile
  useEffect(() => {
    if (isTab) {
      setIsOpen(false)
    }
  }, [pathname, isTab])

  return (
    <aside className="md:h-screen sticky left-0 top-0">
      <div
        onClick={() => setIsOpen(false)}
        className={css(
          'md:hidden fixed inset-0 max-h-screen z-[999] bg-black/50',
          {
            block: isOpen,
            hidden: !isOpen,
          },
        )}
      />
      <motion.div
        variants={isTab ? mobileSidebarAnimation : sidebarAnimation}
        animate={isOpen ? 'open' : 'closed'}
        initial={{ x: isTab ? -250 : 0 }}
        className="bg-white text-gray shadow-xl z-[999] w-[16rem] max-width-[16rem] h-screen pt-3 overflow-y-hidden md:relative fixed flex flex-col flex-1"
      >
        <SidebarHeader toggleMenu={toggleMenu} isOpen={isOpen} />

        <NavMenu isOpen={isOpen} items={menuLinks} openMenu={handleOpenMenu} />
      </motion.div>

      <div
        className="p-3 md:hidden w-full bg-white flex shadow"
        onClick={() => setIsOpen(true)}
      >
        <SVGMenu />
      </div>
    </aside>
  )
}
