export const determineStatus = (
  startDate: Date | null,
  endDate: Date | null,
): 'Active' | 'Inactive' | 'Finished' => {
  const currentDate = new Date()

  if (startDate && endDate) {
    const start = new Date(startDate)
    const end = new Date(endDate)

    if (currentDate < start) {
      return 'Inactive'
    }
    if (currentDate >= start && currentDate <= end) {
      return 'Active'
    }
    if (currentDate > end) {
      return 'Finished'
    }
  }

  return 'Finished'
}
