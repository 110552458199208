import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ru from './languages/ru.json'
import en from './languages/en.json'

i18n

  .use(LanguageDetector)

  .use(initReactI18next)

  .init({
    debug: false,
    fallbackLng: 'ru',
    lng: localStorage.getItem('i18nextLng') || 'ru',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      ru,
      en,
    },
  })

export default i18n
