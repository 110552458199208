/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosError } from 'axios'
import {
  AvailabilityProduct,
  FiltersType,
  FormData,
  Sale,
  UpdateSale,
} from '~/shared/api/services/marketing/types'
import { SelectOption } from '~/shared/ui'
import { Product } from '~/shared/api'
import {
  VARIANT_TYPES,
  determineStatus,
  handleAxiosError,
  parseFormData,
} from '~/shared/lib'
import { MarketingService } from '~/shared/api/services/marketing'
import { store } from '~/app/store'

export class DiscountStore {
  requestSettings = {
    limit: 15,
    offset: 0,
  }

  filters: FiltersType = {
    productId: null,
    status: { value: 'All', label: 'Все' },
    categoryId: null,
    date: [],
  }

  variantTypeId = 1

  sales: Sale[] = []

  productsForFilter: Product[] = []

  q = ''

  formQ = ''

  states = [
    { value: 'All', label: 'Все' },
    { value: 'Active', label: 'Активно' },
    { value: 'Inactive', label: 'Неактивно' },
    { value: 'Finished', label: 'Завершено' },
  ]

  formData: FormData = {
    dateEnd: null,
    dateStart: null,
    sale: '',
    timeStart: '',
    timeEnd: '',
    maxCountUse: '',
    productAvailabilityId: null,
    storeId: null,
    categoryId: null,
  }

  availabilityProductsForForm: AvailabilityProduct[] = []

  subContentOpens: string[] = []

  updateSales: UpdateSale[] = []

  totalPagesSales = 0

  constructor() {
    makeAutoObservable(this)
  }

  setIdsSubContentOpens = (id: string) => {
    runInAction(() => {
      this.subContentOpens = this.subContentOpens.includes(id)
        ? this.subContentOpens.filter((e) => e !== id)
        : [...this.subContentOpens, id]
    })
  }

  updateSale = async (id: string, path: 'product' | 'category') => {
    try {
      const sale = this.updateSales.find((e) => e.id === id)

      if (sale) {
        const status = determineStatus(sale.dateStart, sale.dateEnd)
        sale.state = status

        await MarketingService.updateSale(sale, path)
        this.sales = this.sales.map((e) =>
          e.id === id ? { ...e, ...sale } : e,
        )
      }
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    }
  }

  getProductsForFilter = async () => {
    try {
      store.marketingStore.activeLoader('products')
      const search = this.q
        ? {
            params: {
              q: this.q,
            },
          }
        : {}
      const { data } = await MarketingService.getProductsForFilter(search)

      runInAction(() => {
        this.productsForFilter = data
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw error.response?.data.error
      }
    } finally {
      runInAction(() => {
        store.marketingStore.closeLoader('products')
      })
    }
  }

  createSale = async () => {
    try {
      store.marketingStore.activeLoader('createSale')

      const variant = VARIANT_TYPES.find((e) => e.id === this.variantTypeId)
      if (variant?.path) {
        await MarketingService.createSale(
          parseFormData<FormData>(this.formData),
          variant.path as 'product' | 'category',
        )
        this.requestSettings = { offset: 0, limit: 15 }
        await this.getSales()
      }
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      store.marketingStore.closeLoader('createSale')
    }
  }

  deleteSale = async (id: string, path: 'product' | 'category') => {
    try {
      store.marketingStore.activeLoader('deleteSale')
      await MarketingService.deleteSale(id, path)
      runInAction(() => {
        this.sales = this.sales.filter((sale) => sale.id !== id)
        this.updateSales = this.updateSales.filter((sale) => sale.id !== id)
      })
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      store.marketingStore.closeLoader('deleteSale')
    }
  }

  setUpdateSales = ({
    id,
    name,
    value,
  }: {
    id: string
    name: string
    value: string | Date | null
  }) => {
    runInAction(() => {
      this.updateSales = this.updateSales.map((sale) =>
        sale.id === id ? { ...sale, [name]: value } : sale,
      )
    })
  }

  getProductsForForm = async () => {
    try {
      store.marketingStore.activeLoader('products')
      const search = this.formQ
        ? {
            params: {
              q: this.formQ,
              storeId: this.formData.storeId?.value,
            },
          }
        : { params: { storeId: this.formData.storeId?.value } }
      const { data } = await MarketingService.getProductsForForm(search)

      runInAction(() => {
        this.availabilityProductsForForm = data
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw error.response?.data.error
      }
    } finally {
      runInAction(() => {
        store.marketingStore.closeLoader('products')
      })
    }
  }

  getSales = async (action?: 'loadMoreSales') => {
    try {
      const params: { offset: number; limit: number; [key: string]: any } = {
        offset: action ? this.requestSettings.offset : 0,
        limit: this.requestSettings.limit,
      }
      store.marketingStore.activeLoader(action ? 'loadMoreSales' : 'sales')
      const filterKeys = Object.keys(this.filters) as (keyof FiltersType)[]
      filterKeys.forEach((filterKey) => {
        if (filterKey === 'status') {
          if (
            this.filters.status?.value &&
            this.filters.status.value !== 'All'
          ) {
            params[filterKey] = this.filters.status.value
          }
        } else if (filterKey !== 'date' && this.filters[filterKey]?.value) {
          params[filterKey] = this.filters[filterKey]?.value
        } else if (filterKey === 'date' && this.filters.date?.length) {
          const [dateStart] = this.filters.date
          params.dateStart = dateStart
          params.dateEnd = this.filters.date[this.filters.date.length - 1]
        }
      })

      const resp = await MarketingService.getSales({
        params,
      })
      const { data } = resp
      const { totalPages }: { totalPages: number } = resp as any

      runInAction(() => {
        this.sales = action ? [...this.sales, ...data.sales] : data.sales
        const emptyString: unknown = ''
        this.updateSales = this.sales.map((e) => ({
          dateEnd: e.dateEnd ? e.dateEnd : (emptyString as Date),
          dateStart: e.dateStart ? e.dateStart : (emptyString as Date),
          maxCountUse: e.maxCountUse ? String(e.maxCountUse) : '',
          timeEnd: e.timeEnd || '',
          timeStart: e.timeStart || '',
          id: e.id,
          state: e.state,
          storeId: e.storeId,
        }))

        this.requestSettings = {
          ...this.requestSettings,
          offset: this.requestSettings.offset + this.requestSettings.limit,
        }
        this.totalPagesSales = totalPages
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw error.response?.data.error
      }
    }
    store.marketingStore.closeLoader(action ? 'loadMoreSales' : 'sales')
  }

  setFilters = (name: string, value: Date[] | SelectOption) => {
    this.filters = { ...this.filters, [name]: value }
    this.requestSettings.offset = 0
  }

  setFormData = (
    name: keyof FormData,
    value: string | SelectOption | number | Date | null,
  ) => {
    this.formData = { ...this.formData, [name]: value }
  }

  setVariantType = (value: number) => {
    runInAction(() => {
      this.variantTypeId = value
      this.formData = {
        ...this.formData,
        categoryId: null,
        productAvailabilityId: null,
      }
    })
  }

  clearFormData = () => {
    this.formData = {
      dateEnd: null,
      dateStart: null,
      sale: '',
      timeStart: '',
      timeEnd: '',
      maxCountUse: '',
      productAvailabilityId: null,
      storeId: null,
      categoryId: null,
    }
  }

  clearFilters = () => {
    this.filters = {
      productId: null,
      status: null,
      categoryId: null,
      date: [],
    }
  }

  clearAvailabilityProductsForForm() {
    this.availabilityProductsForForm = []
  }
}

export const discountStore = new DiscountStore()
