import { CSSProperties, FC, useEffect, useRef, useState } from 'react'
import { Props, TooltipPosition } from './types'
import { HelpSVG } from '../SVG/HelpSVG'
import { SVGComment } from '../SVG/SVGComment'

export const ToolTip: FC<Props> = ({
  tooltipText,
  tooltipTitle,
  clicked,
  comment = false,
}): JSX.Element => {
  const [tooltipStatus, setTooltipStatus] = useState(false)
  const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition>({
    top: 0,
    left: 0,
  })
  const tooltipRef = useRef<HTMLDivElement | null>(null)
  const tooltipChildRef = useRef<HTMLDivElement | null>(null)

  const handleMouseEnter = () => {
    if (!clicked) {
      setTooltipStatus(true)
    }
  }

  const handleMouseLeave = () => {
    if (!clicked) {
      setTooltipStatus(false)
    }
  }

  const handleClick = () => {
    if (clicked) {
      setTooltipStatus(!tooltipStatus)
    }
  }

  useEffect(() => {
    if (tooltipRef.current && tooltipChildRef.current) {
      const iconRect = tooltipRef.current.getBoundingClientRect()
      const { innerWidth, innerHeight } = window

      let top = iconRect.bottom
      let { left } = iconRect

      if (
        left +
          tooltipRef.current.offsetWidth +
          tooltipChildRef.current.offsetWidth >
        innerWidth
      ) {
        left =
          innerWidth -
          tooltipRef.current.offsetWidth -
          tooltipChildRef.current.offsetWidth
      }

      if (
        top +
          tooltipRef.current.offsetHeight +
          tooltipChildRef.current.offsetHeight >
        innerHeight
      ) {
        top =
          innerHeight -
          tooltipRef.current.offsetHeight -
          tooltipChildRef.current.offsetHeight
      }

      setTooltipPosition({ top, left })
    }
  }, [tooltipStatus])

  const tooltipStyle: CSSProperties = {
    position: 'fixed',
    top: `${tooltipPosition.top}px`,
    left: `${tooltipPosition.left}px`,
    zIndex: 99999,
    maxWidth: '300px',
    minWidth: '200px',
    backgroundColor: 'white',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    padding: '8px',
  }

  return (
    <div className="flex items-center justify-center" ref={tooltipRef}>
      <div
        className="relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {' '}
        {comment ? (
          <div className="mr-2 cursor-pointer flex items-center justify-center">
            <SVGComment
              size="24px"
              color="#242424"
              className="absolute top-[6.5px] right-[-9px] "
            />
          </div>
        ) : (
          <div className="mr-2 cursor-pointer flex items-center justify-center">
            <HelpSVG />
          </div>
        )}
        {tooltipStatus && (
          <div style={tooltipStyle} ref={tooltipChildRef}>
            <h1 className="text-sm font-medium text-[#242424]">
              {tooltipTitle}
            </h1>

            <p className="text-xs leading-4 font-normal mt-1 w-full text-[#707070]">
              {tooltipText}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
