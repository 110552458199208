import { FC, HTMLAttributes } from 'react'

interface SVGLogoutProps extends HTMLAttributes<SVGSVGElement> {
  className: string
}

export const SVGLogout: FC<SVGLogoutProps> = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#242424"
      strokeWidth="1"
      className={className}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <path
        d="M15.6231 15.9769C15.4782 15.8115 15.4058 15.6282 15.4058 15.4269C15.4058 15.2256 15.4782 15.0525 15.6231 14.9077L17.5308 13H9.86537C9.65255 13 9.47435 12.9282 9.33077 12.7846C9.18718 12.641 9.11539 12.4628 9.11539 12.25C9.11539 12.0372 9.18718 11.859 9.33077 11.7154C9.47435 11.5718 9.65255 11.5 9.86537 11.5H17.5308L15.6135 9.5827C15.4647 9.43398 15.3904 9.25899 15.3904 9.05772C15.3904 8.85644 15.468 8.67823 15.6231 8.5231C15.768 8.36798 15.941 8.29043 16.1423 8.29043C16.3436 8.29043 16.5166 8.36286 16.6615 8.50772L19.7711 11.6173C19.8647 11.7109 19.9307 11.8096 19.9692 11.9135C20.0076 12.0173 20.0269 12.1295 20.0269 12.25C20.0269 12.3705 20.0076 12.4827 19.9692 12.5865C19.9307 12.6904 19.8647 12.7891 19.7711 12.8827L16.6519 16.0019C16.4929 16.1608 16.318 16.2326 16.1269 16.2172C15.9359 16.2019 15.768 16.1217 15.6231 15.9769ZM5.40387 21C4.89875 21 4.47119 20.825 4.12119 20.475C3.77119 20.125 3.59619 19.6974 3.59619 19.1923V5.3077C3.59619 4.80257 3.77119 4.375 4.12119 4.025C4.47119 3.675 4.89875 3.5 5.40387 3.5H11.3654C11.5782 3.5 11.7564 3.5718 11.9 3.7154C12.0436 3.85898 12.1154 4.03718 12.1154 4.25C12.1154 4.46282 12.0436 4.64102 11.9 4.7846C11.7564 4.92818 11.5782 4.99997 11.3654 4.99997H5.40387C5.32693 4.99997 5.25642 5.03202 5.19232 5.09613C5.1282 5.16024 5.09614 5.23077 5.09614 5.3077V19.1923C5.09614 19.2692 5.1282 19.3398 5.19232 19.4039C5.25642 19.468 5.32693 19.5 5.40387 19.5H11.3654C11.5782 19.5 11.7564 19.5718 11.9 19.7154C12.0436 19.859 12.1154 20.0372 12.1154 20.25C12.1154 20.4628 12.0436 20.641 11.9 20.7846C11.7564 20.9282 11.5782 21 11.3654 21H5.40387Z"
        fill="#1C1B1F"
      />
    </svg>
  )
}
