export function isEqual(a: unknown, b: unknown): boolean {
  if (
    typeof a !== 'object' ||
    a === null ||
    typeof b !== 'object' ||
    b === null
  ) {
    return a === b
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      return false
    }
    for (let i = 0; i < a.length; i += 1) {
      if (!isEqual(a[i], b[i])) {
        return false
      }
    }
    return true
  }

  if (
    typeof a === 'object' &&
    typeof b === 'object' &&
    a !== null &&
    b !== null
  ) {
    const keysA = Object.keys(a)
    const keysB = Object.keys(b)

    if (keysA.length !== keysB.length) {
      return false
    }

    for (let i = 0; i < keysA.length; i += 1) {
      const key = keysA[i]
      if (
        !keysB.includes(key) ||
        !isEqual(a[key as keyof typeof a], b[key as keyof typeof a])
      ) {
        return false
      }
    }
    return true
  }

  return false
}
