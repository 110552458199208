import { AxiosError } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import { MainService } from '~/shared/api/services/main'
import { parseJSON } from '~/shared/lib'
import { STORES_STORAGE_KEY } from './config'
import { Store } from '../api/services/global/types'

export class GlobalModel {
  loading = false

  stores: Store[] | [] =
    parseJSON(localStorage.getItem(STORES_STORAGE_KEY)) || []

  constructor() {
    makeAutoObservable(this)
  }

  getStores = async () => {
    try {
      this.loading = true
      const { data } = await MainService.getStores()

      runInAction(() => {
        this.stores = data
      })
      localStorage.setItem(STORES_STORAGE_KEY, JSON.stringify(data))

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }
}
export const globalStore = new GlobalModel()
