/* eslint-disable react/jsx-props-no-spreading */
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import css from 'classnames'
import { useTranslation } from 'react-i18next'
import { Props } from './types'
import { SVGDownLoad } from '../SVG/SVGDownLoad'

export const DropFileZone: FC<Props> = ({
  onChange,
  className,
  children,
  dropFileZoneRefIcon,
  dropZoneRefBanner,
  dropFileZoneRefLogo,
  warning,
}) => {
  const { t } = useTranslation()
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onChange?.(acceptedFiles)
    },
    [onChange],
  )
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
  const containerRef = useRef(null)

  const { isDragActive, getRootProps, getInputProps } = useDropzone({ onDrop })
  useEffect(() => {
    // Обновление размера контейнера при изменении размера окна или других факторов
    const updateContainerSize = () => {
      if (containerRef.current) {
        const { offsetWidth, offsetHeight } = containerRef.current
        setContainerSize({ width: offsetWidth, height: offsetHeight })
      }
    }

    window.addEventListener('resize', updateContainerSize)
    updateContainerSize() // Инициализация размера при монтировании

    return () => {
      window.removeEventListener('resize', updateContainerSize)
    }
  }, [])
  const iconSize = containerSize.width <= 200 ? 32 : 40
  const textSize = containerSize.width <= 200 ? 'text-xs' : 'text-sm'
  const textStyles = `font-light ${textSize} text-center`

  return (
    <div
      ref={containerRef}
      {...getRootProps()}
      className={css(
        className,
        'w-full dashed p-4 rounded flex flex-col gap-3 items-center justify-center transition-all',
        { 'bg-gray-100 bg-none': isDragActive },

        { dashedWarning: warning }, // from index.css
      )}
      style={{ whiteSpace: 'normal' }}
    >
      {children}
      <input {...getInputProps()} ref={dropFileZoneRefLogo} />
      <input {...getInputProps()} ref={dropFileZoneRefIcon} />
      <input {...getInputProps()} ref={dropZoneRefBanner} />

      <SVGDownLoad size={String(iconSize)} />

      <p className={` ${textStyles}`}>{t('dndPic')}</p>
    </div>
  )
}
