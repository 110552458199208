// A wrapper for "JSON.parse()" to support "undefined" value
export function parseJSON<T>(value: string | null): T | undefined {
  if (value === null || value === undefined) {
    return undefined
  }

  try {
    return JSON.parse(value) as T
  } catch {
    // eslint-disable-next-line no-console
    console.log('parsing error on', { value })
    return undefined
  }
}
