import css from 'classnames'
import { motion } from 'framer-motion'
import { Props } from './types'
import { menuAnimate } from './config'

export const ContextMenu = <T,>({
  isShown,
  top,
  left,
  items,
  row,
}: Props<T>) => {
  return (
    <motion.div
      className={css('bg-white flex flex-col absolute shadow rounded ', {
        hidden: !isShown,
      })}
      initial="closed"
      animate={isShown ? 'open' : 'closed'}
      variants={menuAnimate}
      style={{ top, left }}
    >
      {items?.map((item) => (
        <div
          key={item.text}
          onClick={() => {
            if (row) {
              item.handler(row)
            }
          }}
          className="p-3 hover:bg-slate-200 cursor-pointer transition"
        >
          {item.text}
        </div>
      ))}
    </motion.div>
  )
}
