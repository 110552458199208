import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useIntersectionObserver } from 'usehooks-ts'
import css from 'classnames'
import { useStore } from '~/app/store'
import { PRODUCTS_TABLE_CONFIG } from '~/entities/products'
import { TableContainer } from '~/shared/ui'
import GoToTop from '~/features/scroll-to-top'
import { SubRowComponent } from '~/entities/products/ui/sub-content-product-1'

export const ProductsTableWidget = observer(() => {
  const { productsStore } = useStore()
  const { search } = useLocation()

  const searchWithoutPluses = useMemo(
    () => search.replace(/\+/g, ' '),
    [search],
  )

  const {
    productsLoading,
    products,
    loadingMoreProducts,
    getProducts,
    loadProducts,
  } = productsStore

  const tableBottom = useRef<HTMLDivElement>(null)

  const entry = useIntersectionObserver(tableBottom, {})
  const isVisible = useMemo(
    () => !!entry?.isIntersecting,
    [entry?.isIntersecting],
  )

  useEffect(() => {
    if (isVisible) {
      loadProducts(searchWithoutPluses)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, searchWithoutPluses])

  useEffect(() => {
    getProducts(searchWithoutPluses)
  }, [searchWithoutPluses, getProducts])

  const anchor = useMemo(
    () => (
      <div
        ref={tableBottom}
        className={css({ hidden: productsLoading || loadingMoreProducts })}
      />
    ),
    [productsLoading, loadingMoreProducts],
  )

  return (
    <>
      <div className="mt-4">
        <TableContainer
          fixedWidth
          loadMore={loadingMoreProducts}
          isLoading={productsLoading}
          columns={PRODUCTS_TABLE_CONFIG}
          data={products}
          hasSubRowContent
          subRowComponent={SubRowComponent}
        />
        {anchor}
      </div>
      <div className="fixed bottom-8 left-7">
        <GoToTop />
      </div>
    </>
  )
})
