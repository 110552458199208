import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { apiService } from '../../api-base'
import {
  AvailabilitesProduct,
  Countries,
  Incoming,
  IncomingData,
  IncomingNumber,
  Order,
  ProductOption,
  Suppliers,
  Warehouses,
} from './types'

export class WarehousesService {
  static getWarehouses(): Promise<AxiosResponse<Warehouses[]>> {
    return apiService.get<AxiosResponse<Warehouses[]>>(`/warehouses`)
  }

  static getAvailabilitiesProduct(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<AvailabilitesProduct[]>> {
    return apiService.get<AxiosResponse<AvailabilitesProduct[]>>(
      `/products/availabilities`,
      { params: { ...config, withoutInventories: 1 } },
    )
  }

  static getProductOption(
    id: string,
  ): Promise<AxiosResponse<{ id: string; options: ProductOption[] }>> {
    return apiService.get<
      AxiosResponse<{ id: string; options: ProductOption[] }>
    >(`/products/available/${id}`)
  }

  static getProductByBarcode(barcode: string): Promise<AxiosResponse> {
    return apiService.get<AxiosResponse>(
      `/products/variation/barcode/${barcode}`,
    )
  }

  static getWarehouseID(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Incoming[]>> {
    return apiService.get<AxiosResponse<Incoming[]>>(`/incoming`, config)
  }

  static getVariation(
    id: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    return apiService.get<AxiosResponse>(
      `/products/available/${id}/variation`,

      { ...config },
    )
  }

  static getSuppliers(): Promise<AxiosResponse<Suppliers[]>> {
    return apiService.get<AxiosResponse<Suppliers[]>>(`/suppliers`)
  }

  static getCountries(): Promise<AxiosResponse<Countries[]>> {
    return apiService.get<AxiosResponse<Countries[]>>(`/countries`)
  }

  static createNewEntrance(data: Order): Promise<AxiosResponse> {
    return apiService.post<AxiosResponse>(`/incoming/create`, data)
  }

  static deleteEntrance(
    listNumber: string,
    data: { incomingListNumber: string | undefined },
  ): Promise<AxiosResponse> {
    return apiService.deleteWithBody<AxiosResponse>(
      `/incoming/${listNumber}/remove`,
      data,
    )
  }

  static generateCSV(
    listNumber: string,
    data: {
      incomingListNumber: string | undefined
    },
  ): Promise<AxiosResponse> {
    return apiService.post<AxiosResponse>(
      `/incoming/${listNumber}/generate-csv`,
      data,
    )
  }

  static createSuppliers(data: {
    name: string
    inn: string
    kpp: string
    countryCode: number
  }): Promise<AxiosResponse> {
    return apiService.post<AxiosResponse>(`/suppliers`, data)
  }

  static accept(data: IncomingData): Promise<AxiosResponse> {
    return apiService.post<AxiosResponse>(
      `/incoming/${data.incomingListNumber}/view/accept`,
      data,
    )
  }

  static getIncomingNumber(
    number: string,
  ): Promise<AxiosResponse<IncomingNumber>> {
    return apiService.get<AxiosResponse<IncomingNumber>>(
      `incoming/${number}/view`,
    )
  }
}
