import { FC } from 'react'

interface PropsDownLoad {
  // eslint-disable-next-line react/require-default-props
  size?: string
  // eslint-disable-next-line react/require-default-props
  color?: string
}

export const SVGDownLoad: FC<PropsDownLoad> = ({ size, color }) => {
  const fill = color || 'gray'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || '33'}
      height={size || '32'}
      viewBox="0 0 33 32"
      fill="none"
      stroke={fill}
      strokeWidth="1"
    >
      <path
        d="M16.5 20.3179C16.3393 20.3179 16.1897 20.2923 16.0513 20.241C15.9128 20.1897 15.7812 20.1017 15.6564 19.9769L11.5103 15.8308C11.3257 15.6462 11.2312 15.4141 11.227 15.1347C11.2227 14.8552 11.3171 14.6189 11.5103 14.4257C11.7034 14.2326 11.941 14.1326 12.2231 14.1257C12.5051 14.1189 12.7427 14.212 12.9359 14.4052L15.5 16.9693V6.76925C15.5 6.4855 15.5957 6.2479 15.7872 6.05645C15.9786 5.86501 16.2162 5.76929 16.5 5.76929C16.7837 5.76929 17.0213 5.86501 17.2128 6.05645C17.4042 6.2479 17.4999 6.4855 17.4999 6.76925V16.9693L20.064 14.4052C20.2487 14.2206 20.4841 14.1296 20.7704 14.1321C21.0568 14.1347 21.2965 14.2326 21.4897 14.4257C21.6828 14.6189 21.7794 14.8531 21.7794 15.1283C21.7794 15.4035 21.6828 15.6376 21.4897 15.8308L17.3435 19.9769C17.2188 20.1017 17.0871 20.1897 16.9487 20.241C16.8102 20.2923 16.6607 20.3179 16.5 20.3179ZM8.91027 26C8.23676 26 7.66667 25.7667 7.2 25.3C6.73333 24.8333 6.5 24.2632 6.5 23.5897V21C6.5 20.7162 6.59573 20.4786 6.7872 20.2872C6.97864 20.0957 7.21624 20 7.5 20C7.78376 20 8.02136 20.0957 8.2128 20.2872C8.40424 20.4786 8.49997 20.7162 8.49997 21V23.5897C8.49997 23.6923 8.5427 23.7863 8.62817 23.8718C8.71366 23.9573 8.80769 24 8.91027 24H24.0897C24.1922 24 24.2863 23.9573 24.3718 23.8718C24.4572 23.7863 24.5 23.6923 24.5 23.5897V21C24.5 20.7162 24.5957 20.4786 24.7871 20.2872C24.9786 20.0957 25.2162 20 25.4999 20C25.7837 20 26.0213 20.0957 26.2127 20.2872C26.4042 20.4786 26.4999 20.7162 26.4999 21V23.5897C26.4999 24.2632 26.2666 24.8333 25.7999 25.3C25.3333 25.7667 24.7632 26 24.0897 26H8.91027Z"
        fill={fill}
      />
    </svg>
  )
}
