import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React from 'react'

interface Props<T, K extends keyof T> {
  data: T[]
  columns: ColumnDef<T, K>[]
  pagination?: boolean
}

export const useTable = <T, K extends keyof T>({
  data,
  columns,
  pagination,
}: Props<T, K>) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    ...(pagination && { getPaginationRowModel: getPaginationRowModel() }),
  })

  const [state, setState] = React.useState(table.initialState)

  const [rowSelection, setRowSelection] = React.useState({})

  table.setOptions((prev) => ({
    ...prev,
    state: {
      ...state,
      rowSelection,
    },
    onStateChange: setState,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    ...(pagination && { getPaginationRowModel: getPaginationRowModel() }),
    onRowSelectionChange: setRowSelection,
  }))

  return { state, table, row: table.getSelectedRowModel }
}
