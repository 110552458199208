export const SVGStore = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#242424"
      strokeWidth="0.5"
    >
      <path
        d="M4.96148 5.74995C4.749 5.74995 4.57088 5.67805 4.42713 5.53425C4.28338 5.39043 4.21151 5.21223 4.21151 4.99965C4.21151 4.78705 4.28338 4.60896 4.42713 4.46538C4.57088 4.32179 4.749 4.25 4.96148 4.25H19.0384C19.2509 4.25 19.429 4.3219 19.5728 4.4657C19.7165 4.60952 19.7884 4.78772 19.7884 5.0003C19.7884 5.2129 19.7165 5.39099 19.5728 5.53457C19.429 5.67816 19.2509 5.74995 19.0384 5.74995H4.96148ZM5.15381 19.75C4.89773 19.75 4.68307 19.6633 4.50983 19.4901C4.3366 19.3169 4.24998 19.1022 4.24998 18.8461V13.75H3.94036C3.65574 13.75 3.42145 13.6387 3.23748 13.4163C3.0535 13.1939 2.99484 12.9403 3.06151 12.6557L4.06151 7.96345C4.10708 7.75535 4.21338 7.58442 4.38043 7.45065C4.5475 7.31688 4.73734 7.25 4.94996 7.25H19.05C19.2626 7.25 19.4524 7.31688 19.6195 7.45065C19.7865 7.58442 19.8928 7.75535 19.9384 7.96345L20.9384 12.6557C21.0051 12.9403 20.9464 13.1939 20.7624 13.4163C20.5785 13.6387 20.3442 13.75 20.0596 13.75H19.7499V19C19.7499 19.2125 19.678 19.3906 19.5342 19.5343C19.3904 19.6781 19.2122 19.75 18.9996 19.75C18.787 19.75 18.6089 19.6781 18.4654 19.5343C18.3218 19.3906 18.25 19.2125 18.25 19V13.75H13.7499V18.8461C13.7499 19.1022 13.6633 19.3169 13.4901 19.4901C13.3169 19.6633 13.1022 19.75 12.8461 19.75H5.15381ZM5.74993 18.25H12.25V13.75H5.74993V18.25ZM4.66531 12.25H19.3346L18.5711 8.74995H5.42878L4.66531 12.25Z"
        fill="#242424"
      />
    </svg>
  )
}
