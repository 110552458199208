import { AxiosError } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import FingerPrint from '@fingerprintjs/fingerprintjs'
import { AuthService, ListUser, User } from '~/shared/api'
import {
  REFRESH_TOKEN_STORAGE_KEY,
  TOKEN_STORAGE_KEY,
  USER_STORAGE_KEY,
} from './config'
import { parseJSON } from '~/shared/lib'

export class AuthModel {
  sessionToken = ''

  isLoading = false

  usersList: ListUser[] = []

  isListLoading = false

  isMoreLoading = false

  offset = 0

  limit = 20

  isEndUserList = false

  user: User | null = parseJSON(localStorage.getItem(USER_STORAGE_KEY)) || null

  constructor() {
    makeAutoObservable(this)

    this.getSessionTokenFromStorage()
  }

  get isAuth() {
    return !!this.sessionToken
  }

  private saveSessionTokenToStorage = (value: string) => {
    localStorage.setItem(TOKEN_STORAGE_KEY, value)
    this.sessionToken = value
  }

  private saveUserToStorage = (value: User) => {
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(value))
    this.user = value
  }

  private getSessionTokenFromStorage = async () => {
    const value = localStorage.getItem(TOKEN_STORAGE_KEY)

    if (value !== null) {
      this.sessionToken = value
    }
  }

  private setSessionToken = (token: string) => {
    this.saveSessionTokenToStorage(token)
    this.sessionToken = token
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  logout = async () => {
    try {
      const { status } = await AuthService.logout()

      if (status) {
        this.setSessionToken('')
        localStorage.clear()
      }
    } catch (error) {
      throw new Error('Unsuccessfully logout!')
    }
  }

  login = async (email: string, password: string) => {
    this.setIsLoading(true)

    try {
      const fpPromise = FingerPrint.load()
      const fp = await fpPromise
      const res = await fp.get()

      const payload = {
        email,
        password,
        platform: 'web',
        deviceId: res.visitorId,
      }
      const { data } = await AuthService.login(payload)

      this.setSessionToken(data.token)
      this.saveUserToStorage(data.user)
      localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, data.refreshToken)
    } catch (error: unknown) {
      throw new Error('Invalid user data! Try again!')
    } finally {
      this.setIsLoading(false)
    }
  }

  getUsers = async () => {
    runInAction(() => {
      this.isListLoading = true
      this.isEndUserList = false
    })

    try {
      const { data } = await AuthService.getUsers()

      runInAction(() => {
        this.usersList = data
        this.offset = 20
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.isListLoading = false
      })
    }
  }

  getMoreUsers = async () => {
    if (this.isEndUserList) return

    runInAction(() => {
      this.isMoreLoading = true
    })

    try {
      const { data } = await AuthService.loadMoreUsers({
        params: {
          limit: this.limit,
          offset: this.offset,
        },
      })

      runInAction(() => {
        this.isEndUserList = data.length < this.limit
        this.offset += this.offset

        this.usersList = [...this.usersList, ...data]
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.isMoreLoading = false
      })
    }
  }
}

export default AuthModel
