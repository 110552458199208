import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { usePageTitle } from '~/shared/lib'

export const NotFound = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  usePageTitle({
    title: t('pageNotFound'),
  })

  return (
    <div className="flex items-center justify-center w-full h-full bg-gradient-to-r">
      <div className="px-40 py-20 bg-white  rounded-md shadow-xl">
        <div className="flex flex-col items-center">
          <h1 className="font-bold text-blue-600 text-9xl">404</h1>

          <h6 className="mb-2 text-2xl font-bold text-center text-gray-800  md:text-3xl">
            <span className="text-red-500">{t('oops')}!</span>{' '}
            {t('pageNotFound')}
          </h6>

          <p className="mb-8 text-center text-gray-500  md:text-lg">
            {t('pageDoesNotExist')}.
          </p>

          <button
            onClick={() => navigate(-1)}
            className="px-6 py-2 text-sm font-semibold text-blue-800 bg-blue-100"
          >
            {t('goBack')}
          </button>
        </div>
      </div>
    </div>
  )
}
