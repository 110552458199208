import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { apiService } from '../../api-base'
import {
  CreateCategoryApiModel,
  NestedCategories,
  SingleCategoryResponse,
} from './types'

export class CategoriesService {
  static getCategories(
    config: AxiosRequestConfig,
  ): Promise<AxiosResponse<NestedCategories[]>> {
    return apiService.get<AxiosResponse<NestedCategories[]>>(
      '/categories',
      config,
    )
  }

  static updateCategories(data: {
    categories: NestedCategories[]
    storeId: string
  }): Promise<AxiosResponse<NestedCategories[]>> {
    return apiService.post<AxiosResponse<NestedCategories[]>>(
      '/categories/update',
      data,
    )
  }

  static createCategory(data: CreateCategoryApiModel) {
    return apiService.post<AxiosResponse<CreateCategoryApiModel[]>>(
      '/categories',
      data,
    )
  }

  static getCategoryById(
    id: string,
  ): Promise<AxiosResponse<SingleCategoryResponse>> {
    return apiService.get<AxiosResponse<SingleCategoryResponse>>(
      `/categories/${id}`,
    )
  }

  static updateCategory(
    id: string,
    data: CreateCategoryApiModel,
  ): Promise<AxiosResponse<CreateCategoryApiModel>> {
    return apiService.put<AxiosResponse<CreateCategoryApiModel>>(
      `/categories/${id}`,
      data,
    )
  }

  static deleteCategory(
    id: string,
  ): Promise<AxiosResponse<{ success: boolean }>> {
    return apiService.delete<AxiosResponse<{ success: boolean }>>(
      `/categories/${id}`,
    )
  }
}
