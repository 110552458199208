import { FC } from 'react'
import { Props } from './types'

export const TextArea: FC<Props> = ({
  rows = 4,
  id,
  placeholder,
  label,
  onChange,
  value,
  name,
  warning,
}) => {
  return (
    <div
      className={`flex flex-col gap-1 p-2.5 group border rounded-lg active:border-b-blue-500 ${
        warning ? 'border-yellow-500' : ''
      }`}
    >
      {' '}
      {label ? (
        <label className="text-sm text-gray-400 w-full" htmlFor={id}>
          {label}
        </label>
      ) : null}
      <textarea
        id={id}
        rows={rows}
        value={value}
        name={name}
        onChange={onChange}
        className="outline-none bg-transparent resize-none"
        placeholder={placeholder}
      />
    </div>
  )
}
