import { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import { ErrorRecord, Props } from './types'

export const useForm = <
  T extends Record<keyof T, string> = Record<string, never>,
>(
  options?: Props<T>,
) => {
  const [data, setData] = useState<T>((options?.initialValues || {}) as T)

  const [errors, setErrors] = useState<ErrorRecord<T>>({})

  const validateEmailDomain = (email: string) => {
    const domainRegex = /@.+\./
    return domainRegex.test(email)
  }

  const validateField = useCallback(
    (key: keyof T, value: string) => {
      const validation = options?.validations?.[key]
      let error = ''

      if (validation) {
        if (validation.required?.value && !value) {
          error = validation.required.message
        } else if (
          typeof validation.pattern?.value === 'string' &&
          !RegExp(validation.pattern.value).test(value)
        ) {
          error = validation.pattern.message
        } else if (
          validation.custom?.isValid &&
          !validation.custom.isValid(value, data)
        ) {
          error = validation.custom.message
        } else if (key === 'email' && !validateEmailDomain(value)) {
          error = 'Некорректный email'
        }
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [key]: error,
      }))
    },
    [data, options?.validations],
  )

  const handleChange = useCallback(
    <S extends NonNullable<unknown>>(
        key: keyof T,
        sanitizeFn?: (value: string) => S,
      ) =>
      (e: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
        const value = sanitizeFn ? sanitizeFn?.(e.target.value) : e.target.value

        setData({
          ...data,
          [key]: value,
        })

        validateField(key, value as string)
      },
    [data, validateField],
  )

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault()

      const validations = options?.validations

      if (validations) {
        let valid = true
        const newErrors: ErrorRecord<T> = {}

        Object.keys(validations).forEach((key) => {
          const value = data[key as keyof T] as string

          const validation = validations[key as keyof T]
          if (validation?.required?.value && !value) {
            valid = false
            newErrors[key as keyof T] = validation?.required?.message
          }

          if (key === 'email' && !validateEmailDomain(value)) {
            valid = false
            newErrors[key as keyof T] = 'Некорректный домен email'
          }

          const pattern = validation?.pattern
          if (pattern?.value instanceof RegExp && !pattern.value.test(value)) {
            valid = false
            newErrors[key as keyof T] = pattern.message
          }

          const custom = validation?.custom
          if (custom?.isValid && !custom.isValid(value, data)) {
            valid = false
            newErrors[key as keyof T] = custom.message
          }
        })

        if (!valid) {
          setErrors(newErrors)
          return
        }
      }

      setErrors({})

      if (options?.onSubmit) {
        options.onSubmit(data)
      }
    },
    [data, options],
  )

  return {
    data,
    handleChange,
    handleSubmit,
    errors,
  }
}
