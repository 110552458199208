/* eslint-disable react/require-default-props */
interface DoneProps {
  size?: string
  color?: string
  stroke?: string
  strokeWidth?: string
}

export const SVGDone = (props: DoneProps) => {
  const { size, color, stroke, strokeWidth } = props

  return (
    <svg
      width={size || '40'}
      height={size || '40'}
      viewBox="0 0 24 24"
      fill="none"
      stroke={stroke || color || '#707070'}
      strokeWidth={strokeWidth || '1'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="done"
        d="M9.55 17.27C9.42 17.27 9.31 17.25 9.21 17.21C9.1 17.18 9.01 17.11 8.91 17.02L4.74 12.84C4.59 12.7 4.52 12.52 4.53 12.31C4.53 12.1 4.61 11.92 4.75 11.77C4.9 11.63 5.07 11.55 5.28 11.55C5.49 11.55 5.66 11.63 5.81 11.77L9.55 15.51L18.18 6.87C18.33 6.73 18.51 6.65 18.72 6.65C18.93 6.65 19.11 6.73 19.25 6.87C19.4 7.02 19.47 7.2 19.47 7.41C19.47 7.62 19.4 7.8 19.25 7.94L10.18 17.02C10.08 17.11 9.99 17.18 9.88 17.21C9.78 17.25 9.67 17.27 9.55 17.27Z"
        fill={color || '#707070'}
      />
    </svg>
  )
}
