import ReactDOM from 'react-dom/client'
// import * as Sentry from '@sentry/react'
import App from '~/app/index.tsx'

// Sentry.init({
//   dsn: import.meta.env.VITE_SENTRY_DSN,
//   tracesSampleRate: 1.0,
// })

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <App />,
)
