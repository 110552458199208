import { cva } from 'class-variance-authority'

export const navigation = cva(['flex p-0'], {
  variants: {
    intent: {},
    size: {
      small: ['text-md'],
      medium: ['text-base'],
    },
  },
  compoundVariants: [
    // WHAT IS THIS?
    // ANSWER - When your button will have size="medium" and intent="primary",
    // all styles provided to "className" will be applied.
    {
      size: 'medium',
      //   className: 'uppercase',
    },
  ],
  defaultVariants: {
    size: 'medium',
  },
})
