import { FC, useCallback, useEffect, useState } from 'react'
import { useUpdateEffect } from 'usehooks-ts'
import { observer } from 'mobx-react-lite'
import { CategoriesList } from '~/entities/category-list'
import { NestedCategories } from '~/shared/api'
import { Empty } from '~/shared/ui'
import { useStore } from '~/app/store'

interface Props {
  data: NestedCategories[]
}

export const CategoriesCards: FC<Props> = observer(({ data }) => {
  const [list, setList] = useState(data)

  const { categoriesStore } = useStore()
  const { setIsUpdatedList, setUpdatedCategories } = categoriesStore

  useEffect(() => {
    return () => {
      setIsUpdatedList(false)
    }
  }, [setIsUpdatedList])

  useUpdateEffect(() => {
    setUpdatedCategories(list)
  }, [list, setUpdatedCategories])

  useUpdateEffect(() => {
    setList(data)
  }, [data])

  const updated = useCallback(() => {
    setIsUpdatedList(true)
  }, [setIsUpdatedList])

  if (data.length === 0) {
    return <Empty />
  }

  return <CategoriesList data={list} setList={setList} updated={updated} />
})
