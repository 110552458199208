import { AxiosError } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import { RegistarationService } from '~/shared/api/services/registration'
import { UpdateupdateRegistrationData } from '~/shared/api/services/registration/types'

export class RegistrationModel {
  loading = false

  constructor() {
    makeAutoObservable(this)
  }

  updateRegistration = async (data: UpdateupdateRegistrationData) => {
    this.loading = true

    try {
      await RegistarationService.updateRegistration(data)
      runInAction(() => {
        this.loading = false
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  validate = async (data: UpdateupdateRegistrationData) => {
    this.loading = true

    try {
      const responce = await RegistarationService.validate(data)
      runInAction(() => {
        this.loading = false
      })
      return Array.isArray(responce.data) ? null : responce.data
    } catch (error) {
      if (error instanceof AxiosError) {
        const innError = error.response?.data.error['legalEntity.inn']
        const emailError = error.response?.data.error['user.email']

        let errorMessage = 'Произошла неизвестная ошибка.'
        if (innError) {
          errorMessage = innError
        } else if (emailError) {
          errorMessage = emailError
        }
        throw new Error(errorMessage)
      }
      return { defaultError: 'server is unavailable' }
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }
}

export default RegistrationModel
