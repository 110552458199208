import { FC, useState } from 'react'
import css from 'classnames'
import { Props } from './types'

export const Switcher: FC<Props> = ({
  id = Date.now().toString(),
  label,
  onChange,
  defaultValue = false,
  labelClassNames,
  disabled = false,
}) => {
  const [enabled, setEnabled] = useState(defaultValue)

  const handleClick = () => {
    if (!disabled) {
      setEnabled(!enabled)
      onChange?.(!enabled)
    }
  }
  return (
    <div className="flex gap-3">
      <span className={css(labelClassNames)}>{label}</span>
      <label
        htmlFor={id}
        className="inline-flex relative items-center mr-5 cursor-pointer"
      >
        <input
          id={id}
          type="checkbox"
          className="sr-only peer"
          checked={enabled}
          readOnly
        />
        <div
          onClick={handleClick}
          className={`w-12 h-6 border border-gray-600 rounded-full peer ${
            disabled ? 'pointer-events-none opacity-50' : ''
          } peer-checked:after:translate-x-full peer-checked:after:border-white-600 after:content-[''] after:absolute after:top-1 after:left-[5px] peer-checked:after:left-[10px] after:bg-gray-500 after:border-gray-500 peer-checked:after:border-white peer-checked:after:bg-white after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600 peer-checked:border-blue-600`}
        />
      </label>
    </div>
  )
}
