/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import { MarketingService } from '~/shared/api/services/marketing'
import {
  Slider,
  FormDataSlider,
  Slide,
  FormDataSlide,
} from '~/shared/api/services/marketing/types'
import { SelectOption } from '~/shared/ui'
import { handleAxiosError } from '~/shared/lib'
import { store } from '~/app/store'

export class SliderStore {
  requestSettings = {
    limit: 15,
    offset: 0,
  }

  sliders: Slider[] = []

  updateSliders: Slider[] = []

  slide: Slide[] = []

  formDataSlider: FormDataSlider = {
    name: '',
    storeId: null,
    enabled: true,
  }

  formDataSlide: FormDataSlide = {
    title: '',
    link: '',
    text: '',
    attachmentUrl: '',
    attachmentId: '',
    enabled: false,
  }

  constructor() {
    makeAutoObservable(this)
  }

  createSlider = async (slider: FormDataSlider) => {
    try {
      store.marketingStore.activeLoader('createSlider')

      const { data } = await MarketingService.createSlider(slider)
      this.getSliders()
      return data
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      store.marketingStore.closeLoader('createSlider')
    }
  }

  createSlide = async (slide: FormDataSlide, id: string) => {
    try {
      store.marketingStore.activeLoader('createSlide')

      const { data } = await MarketingService.createSlide(slide, id)
      this.getSliders()
      return data
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      store.marketingStore.closeLoader('createSlide')
    }
  }

  deleteSlider = async (id: string) => {
    try {
      store.marketingStore.activeLoader('deleteSlider')
      await MarketingService.deleteSlider(id)
      await this.getSliders()
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      store.marketingStore.closeLoader('deleteSlider')
    }
  }

  getSliders = async () => {
    store.marketingStore.activeLoader('sliders')
    try {
      const params = {
        offset: this.requestSettings.offset,
        limit: this.requestSettings.limit,
      }

      const { data } = await MarketingService.getSliders({
        params,
      })

      runInAction(() => {
        this.sliders = data
        this.updateSliders = data.map((el) => ({
          ...el,
          sliderOpen: false,
          slides: el.slides.map((sld) => ({ ...sld, slideOpen: false })),
        }))
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw error.response?.data.error
      }
    }
    store.marketingStore.closeLoader('sliders')
  }

  setOpenSlider = (sliderId: string) => {
    runInAction(() => {
      this.updateSliders = this.updateSliders.map((e) =>
        e.id === sliderId ? { ...e, sliderOpen: !e.sliderOpen } : e,
      )
    })
  }

  setSlider = (name: string, value: string | boolean, id: string) => {
    runInAction(() => {
      this.updateSliders = this.updateSliders.map((el) =>
        el.id === id ? { ...el, [name]: value } : el,
      )
    })
  }

  setSlide = (data: {
    sliderId: string
    slideId: string
    name: keyof Slide
    value: string | boolean
  }) => {
    const { sliderId, slideId, name, value } = data
    runInAction(() => {
      this.updateSliders = this.updateSliders.map((e) =>
        e.id === sliderId
          ? {
              ...e,
              slides: e.slides.map((sld) =>
                sld.id === slideId ? { ...sld, [name]: value } : sld,
              ),
            }
          : e,
      )
    })
  }

  saveUpdateSliders = async () => {
    try {
      await Promise.allSettled(
        this.updateSliders.map((el) => MarketingService.updateSlider(el)),
      )
      await this.getSliders()
    } catch (error) {
      if (error instanceof AxiosError) {
        throw error.response?.data.error
      }
    }
  }

  saveUpdateSlider = async (sliderId: string) => {
    try {
      store.marketingStore.activeLoader('updateSlider')
      const updateSlider = this.updateSliders.find((sl) => sl.id === sliderId)
      if (updateSlider) {
        await MarketingService.updateSlider(updateSlider)
      }
      await this.getSliders()
    } catch (error) {
      if (error instanceof AxiosError) {
        throw error.response?.data.error
      }
    } finally {
      store.marketingStore.closeLoader('updateSlider')
    }
  }

  saveUpdateSlide = async (sliderId: string, slideId: string) => {
    try {
      store.marketingStore.activeLoader('updateSlider')

      const updateSlider = this.updateSliders.find((sl) => sl.id === sliderId)

      if (updateSlider) {
        const updateSlide = updateSlider.slides.find((sl) => sl.id === slideId)

        if (updateSlide) {
          await MarketingService.updateSlide(updateSlide, slideId)
        }
      }

      await this.getSliders()
    } catch (error) {
      if (error instanceof AxiosError) {
        throw error.response?.data.error || 'Ошибка обновления слайда'
      }
    } finally {
      store.marketingStore.closeLoader('updateSlider')
    }
  }

  setFormDataSlider = (
    name: string,
    value: string[] | SelectOption | boolean | string,
  ) => {
    this.formDataSlider = { ...this.formDataSlider, [name]: value }
  }

  setFormDataSlide = (
    name: string,
    value: string[] | SelectOption | boolean | string,
  ) => {
    this.formDataSlide = { ...this.formDataSlide, [name]: value }
  }

  clearFormDataSlider = () => {
    this.formDataSlider = {
      name: '',
      storeId: null,
      enabled: true,
    }
  }

  clearFormDataSlide = () => {
    this.formDataSlide = {
      title: '',
      link: '',
      text: '',
      attachmentUrl: '',
      attachmentId: '',
      enabled: false,
    }
  }
}

export default SliderStore
