/* eslint-disable react/require-default-props */

interface DeleteSVGProps {
  color?: string
  onClick?: () => void
  size?: string
  disabled?: boolean
  className?: string
}

export const DeleteSVG = (props: DeleteSVGProps) => {
  const { color, size, disabled = false, onClick, className } = props

  const onClickHandler = () => {
    if (!disabled && onClick) {
      onClick()
    }
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClickHandler}
      className={`transition-opacity duration-300 ${
        disabled ? 'opacity-50' : 'opacity-100'
      } ${className}`}
    >
      <path
        d="M7.3077 20.5C6.80257 20.5 6.375 20.325 6.025 19.975C5.675 19.625 5.5 19.1975 5.5 18.6923V6.00005H5.25C5.03718 6.00005 4.85898 5.92826 4.7154 5.78468C4.5718 5.6411 4.5 5.4629 4.5 5.25008C4.5 5.03726 4.5718 4.85906 4.7154 4.71548C4.85898 4.57188 5.03718 4.50008 5.25 4.50008H8.99997C8.99997 4.24881 9.08779 4.03856 9.26342 3.86933C9.43907 3.70009 9.65254 3.61548 9.90382 3.61548H14.0961C14.3474 3.61548 14.5609 3.70009 14.7365 3.86933C14.9122 4.03856 15 4.24881 15 4.50008H18.75C18.9628 4.50008 19.141 4.57188 19.2845 4.71548C19.4281 4.85906 19.5 5.03726 19.5 5.25008C19.5 5.4629 19.4281 5.6411 19.2845 5.78468C19.141 5.92826 18.9628 6.00005 18.75 6.00005H18.5V18.6923C18.5 19.1975 18.325 19.625 17.975 19.975C17.625 20.325 17.1974 20.5 16.6922 20.5H7.3077ZM6.99997 6.00005V18.6923C6.99997 18.7821 7.02883 18.8558 7.08652 18.9135C7.14422 18.9712 7.21795 19.0001 7.3077 19.0001H16.6922C16.782 19.0001 16.8557 18.9712 16.9134 18.9135C16.9711 18.8558 17 18.7821 17 18.6923V6.00005H6.99997ZM9.40385 16.2501C9.40385 16.4629 9.47564 16.6411 9.61922 16.7847C9.76281 16.9283 9.94101 17.0001 10.1538 17.0001C10.3666 17.0001 10.5448 16.9283 10.6884 16.7847C10.832 16.6411 10.9038 16.4629 10.9038 16.2501V8.75003C10.9038 8.53721 10.832 8.35901 10.6884 8.21543C10.5448 8.07184 10.3666 8.00005 10.1538 8.00005C9.94101 8.00005 9.76281 8.07184 9.61922 8.21543C9.47564 8.35901 9.40385 8.53721 9.40385 8.75003V16.2501ZM13.0961 16.2501C13.0961 16.4629 13.1679 16.6411 13.3115 16.7847C13.4551 16.9283 13.6333 17.0001 13.8461 17.0001C14.0589 17.0001 14.2371 16.9283 14.3807 16.7847C14.5243 16.6411 14.5961 16.4629 14.5961 16.2501V8.75003C14.5961 8.53721 14.5243 8.35901 14.3807 8.21543C14.2371 8.07184 14.0589 8.00005 13.8461 8.00005C13.6333 8.00005 13.4551 8.07184 13.3115 8.21543C13.1679 8.35901 13.0961 8.53721 13.0961 8.75003V16.2501ZM6.99997 6.00005V18.6923C6.99997 18.7821 7.02883 18.8558 7.08652 18.9135C7.14422 18.9712 7.21795 19.0001 7.3077 19.0001H6.99997V6.00005Z"
        fill={color || '#242424'}
      />
    </svg>
  )
}
