import { Route, Routes } from 'react-router-dom'
import { Suspense } from 'react'
import { RequireAuth } from '~/shared/lib'
import { privateRoutes, publicRoutes } from './routes'
import { BaseLayout } from '~/layouts'
import Spinner from '~/shared/ui/spinner'

const Routing = () => {
  return (
    <Routes>
      <Route
        element={
          <RequireAuth>
            <Suspense fallback={<Spinner />}>
              <BaseLayout />
            </Suspense>
          </RequireAuth>
        }
      >
        {privateRoutes.map(({ path, Component }) => (
          <Route
            key={path}
            path={path}
            element={
              <RequireAuth>
                <Suspense fallback={<Spinner />}>
                  <Component />
                </Suspense>
              </RequireAuth>
            }
          />
        ))}
      </Route>

      {publicRoutes.map(({ path, Component }) => (
        <Route key={path} path={path} element={<Component />} />
      ))}
    </Routes>
  )
}

export default Routing
