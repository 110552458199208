import { SelectOption } from '~/shared/ui'

export const mapDataForSelect = <T>(
  data: T[],
  valueKey: keyof T | null,
  labelKey: keyof T | null,
): SelectOption[] => {
  return data?.map((item) => ({
    label: String(item?.[labelKey as keyof T] || ''),
    value: String(item?.[valueKey as keyof T] || ''),
  }))
}
