import { useStore } from '~/app/store'
import { SVGLogout } from '~/shared/ui'

export const LogoutButton = () => {
  const { authStore } = useStore()

  const handleLogout = () => {
    authStore.logout()
  }

  return (
    <div onClick={handleLogout}>
      <SVGLogout className="cursor-pointer" />
    </div>
  )
}
