import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import css from 'classnames'
import { MenuModel } from '~/shared/lib'

interface Props {
  item: MenuModel
}

export const NavSub: FC<Props> = ({ item }) => {
  const { t } = useTranslation()

  return (
    <div className="p-0">
      <div className="absolute top-10 min-w-[280px] z-[999] hidden group-hover:md:block hover:md:block">
        <div className="py-3" />
        <div className="bg-white rounded shadow-md z-50 overflow-hidden">
          <ul>
            {item?.subLinks?.map((link) => (
              <li
                className="text-sm group relative text-gray-600"
                key={link.name}
              >
                <NavLink
                  to={link.link}
                  className={({ isActive }) => {
                    return css(
                      'p-3 pl-0 flex hover:bg-slate-100 group justify-between items-center',
                      {
                        '[&>span]:flex': isActive,
                      },
                    )
                  }}
                >
                  <span className="w-[2px] h-5 rounded-md bg-blue-600 hidden absolute opacity-0 group-hover:opacity-100 left-0 transition-all" />
                  <span className="ml-3">{t(link.localeTextName)}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
