import { Outlet, useLocation } from 'react-router-dom'
import css from 'classnames'
import { useNavType } from '~/shared/lib'
import { Header, SideBar } from '~/widgets'

export const BaseLayout = () => {
  const [navType] = useNavType()
  const location = useLocation()

  const nav = navType === 'horizontal' ? <Header /> : <SideBar />

  return (
    <div className="min-h-screen h-auto bg-[#F0F0F0] text-gray-700">
      <div
        className={css({ 'flex flex-col md:flex-row': navType === 'vertical' })}
      >
        {nav}
        <div
          className={`w-full ${
            location.pathname !== '/' && 'py-3 px-6'
          } overflow-x-auto`}
        >
          <Outlet />
        </div>
      </div>
    </div>
  )
}
