/* eslint-disable react/require-default-props */
import { useMemo, useState } from 'react'

interface SVGPencilProps extends React.SVGProps<SVGSVGElement> {
  size?: string
  onClick?: () => void
  disabled?: boolean
  className?: string
}

export const SVGPencil = (props: SVGPencilProps) => {
  const { size = '22', onClick, disabled = false, className } = props
  const [isHovered, setIsHovered] = useState(false)

  const fill = useMemo(() => {
    return isHovered ? 'black' : 'gray'
  }, [isHovered])

  const onClickHandler = () => {
    if (!disabled && onClick) {
      onClick()
    }
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClickHandler}
      className={`transition-opacity duration-300 ${
        disabled ? 'opacity-50' : 'opacity-100'
      } ${className}`}
    >
      <path
        d="M3.436 12.6668H4.26035L10.4335 6.49373L9.60912 5.66937L3.436 11.8425V12.6668ZM12.5719 5.77067L10.3219 3.54122L11.1976 2.66558C11.389 2.47414 11.6249 2.37842 11.9052 2.37842C12.1856 2.37842 12.4215 2.47414 12.6129 2.66558L13.4373 3.48993C13.6287 3.68138 13.7279 3.91385 13.7347 4.18735C13.7415 4.46085 13.6492 4.69332 13.4578 4.88477L12.5719 5.77067ZM3.03859 13.6668C2.8668 13.6668 2.72342 13.6093 2.60847 13.4944C2.49351 13.3794 2.43604 13.236 2.43604 13.0642V11.6681C2.43604 11.5878 2.45056 11.5113 2.47962 11.4387C2.50867 11.366 2.5544 11.2985 2.6168 11.2361L9.59885 4.25402L11.8488 6.50398L4.86675 13.486C4.80436 13.5484 4.73685 13.5942 4.6642 13.6232C4.59155 13.6523 4.51505 13.6668 4.4347 13.6668H3.03859Z"
        fill={fill}
      />
    </svg>
  )
}
