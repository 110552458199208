interface PropsMenu {
  // eslint-disable-next-line react/require-default-props
  size?: string
}

export const SVGMenu = ({ size }: PropsMenu) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
      fill="none"
      stroke="gray"
      strokeWidth={1}
    >
      <path
        d="M4.00001 17.6346C3.78751 17.6346 3.60939 17.5627 3.46566 17.4189C3.32191 17.2751 3.25003 17.0969 3.25003 16.8843C3.25003 16.6717 3.32191 16.4936 3.46566 16.35C3.60939 16.2064 3.78751 16.1346 4.00001 16.1346H20C20.2125 16.1346 20.3906 16.2066 20.5344 16.3504C20.6781 16.4942 20.75 16.6724 20.75 16.885C20.75 17.0976 20.6781 17.2756 20.5344 17.4192C20.3906 17.5628 20.2125 17.6346 20 17.6346H4.00001ZM4.00001 12.75C3.78751 12.75 3.60939 12.6781 3.46566 12.5343C3.32191 12.3905 3.25003 12.2123 3.25003 11.9997C3.25003 11.7871 3.32191 11.609 3.46566 11.4654C3.60939 11.3218 3.78751 11.25 4.00001 11.25H20C20.2125 11.25 20.3906 11.3219 20.5344 11.4657C20.6781 11.6096 20.75 11.7878 20.75 12.0003C20.75 12.2129 20.6781 12.391 20.5344 12.5346C20.3906 12.6782 20.2125 12.75 20 12.75H4.00001ZM4.00001 7.86539C3.78751 7.86539 3.60939 7.79348 3.46566 7.64967C3.32191 7.50587 3.25003 7.32767 3.25003 7.11507C3.25003 6.90248 3.32191 6.7244 3.46566 6.58082C3.60939 6.43722 3.78751 6.36542 4.00001 6.36542H20C20.2125 6.36542 20.3906 6.43733 20.5344 6.58114C20.6781 6.72496 20.75 6.90316 20.75 7.11574C20.75 7.32834 20.6781 7.50643 20.5344 7.65002C20.3906 7.7936 20.2125 7.86539 20 7.86539H4.00001Z"
        fill="#1C1B1F"
      />
    </svg>
  )
}
