import axios, {
  AxiosInstance,
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'

let isRefreshing = false
let subscribers: ((token: string) => void)[] = []

function onRefreshed(token: string) {
  subscribers.map((cb) => cb(token))
}

function subscribeTokenRefresh(cb: () => void) {
  subscribers.push(cb)
}

export class ApiBase {
  private client: AxiosInstance

  constructor() {
    this.client = axios.create({
      baseURL: `${import.meta.env.VITE_API_URL}/api/app`,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })

    // Adding token to each request
    this.client.interceptors.request.use(async (req) => {
      const isLogin = req.url?.includes('login')
      const isRefresh = req.url?.includes('refresh')

      if (isLogin) return req

      if (isRefresh) {
        const token = localStorage.getItem('refreshToken')

        if (token) {
          req.headers['X-Refresh-Token'] = `${token}`
        }
        return req
      }

      const token = localStorage.getItem('sessionToken')
      localStorage.removeItem('error404')

      if (token) {
        req.headers['X-Auth-Token'] = `${token}`
      }
      return req
    })

    this.client.interceptors.response.use(
      // Here we can update token using response. Need to validate with backend
      (response) => {
        return response
      },
      // Handling 498 error
      async (error) => {
        const originalRequest = error.config

        if (error instanceof AxiosError && error.response?.status === 404) {
          localStorage.setItem('error404', JSON.stringify(true))
        }

        if (error instanceof AxiosError && error.response?.status === 498) {
          // Refresh token
          if (!isRefreshing) {
            isRefreshing = true

            this.client
              .post<
                AxiosResponse<{
                  token: string
                  refreshToken: string
                }>
              >('/auth/refresh')
              .then(({ data }) => {
                onRefreshed(data.data.token)
                subscribers = []
                // Setting new tokens to localStorage
                localStorage.setItem('sessionToken', data.data.token)
                localStorage.setItem('refreshToken', data.data.refreshToken)
              })
          }

          return new Promise((resolve) => {
            subscribeTokenRefresh(() => {
              resolve(this.client.request(originalRequest))
            })
          })
        }

        // Handling 401 error
        if (error instanceof AxiosError && error.response?.status === 401) {
          localStorage.clear()
          window.location.replace('/login')
        }

        return Promise.reject(error)
      },
    )
  }

  public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.client.get<T>(url, config)
    return response?.data
  }

  public async post<T>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    const response = await this.client.post<T>(url, data, config)
    return response.data
  }

  public async put<T>(url: string, data?: unknown): Promise<T> {
    const response = await this.client.put<T>(url, data)
    return response.data
  }

  public async delete<T>(url: string): Promise<T> {
    const response = await this.client.delete<T>(url)
    return response.data
  }

  public async deleteWithBody<T>(url: string, data: unknown): Promise<T> {
    const config: AxiosRequestConfig = {
      data, // Тело запроса
    }
    const response: AxiosResponse<T> = await this.client.delete(url, config)
    return response.data
  }
}

export const apiService = new ApiBase()
