import { cva } from 'class-variance-authority'

export const spinner = cva([], {
  variants: {
    variant: {
      primary: 'text-gray-200 animate-spin  fill-blue-600 ',
    },
    size: {
      medium: 'w-6 h-6',
      big: 'w-10 h-10',
    },
  },

  defaultVariants: {
    size: 'medium',
    variant: 'primary',
  },
})
