import { FC } from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import css from 'classnames'
import { Props } from './types'
import { NavigationLink } from '../nav-link'

export const SubMenuItem: FC<Props> = ({ expanded, items }) => {
  const { t } = useTranslation()

  return (
    <motion.div
      animate={expanded ? { height: 'fit-content' } : { height: 0 }}
      className="overflow-hidden z-[999]"
    >
      {items.map((item) => (
        <div
          key={item.link}
          className={css('relative group hover:bg-gray-100 ')}
        >
          <NavigationLink
            text={t(item.localeTextName)}
            link={item.link}
            isOpen={expanded}
            className="pl-[53px] hover:bg-inherit"
            activeClass="bg-gray-100"
          />

          <span
            className={css(
              'w-[3px] h-4 rounded-md bg-blue-600 absolute left-0 top-4 opacity-0 group-hover:opacity-100 transition-all',
            )}
          />
        </div>
      ))}
    </motion.div>
  )
}
