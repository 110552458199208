import { FC } from 'react'
import css from 'classnames'
import { Props } from './types'
import { DeleteSVG } from '../SVG/DeleteSVG'

export const ImagePreview: FC<Props> = ({
  src,
  alt,
  className,
  removeButtonClasses,
  imageClasses,
  onRemove,
}) => {
  return (
    <div className={css('relative', className)}>
      <img
        className={css('w-full h-full object-cover rounded-lg', imageClasses)}
        src={src}
        alt={alt}
      />

      <div
        className={css(
          'w-8 h-8 rounded absolute top-2 right-2 bg-white border border-gray-400 flex items-center justify-center hover:bg-gray-400 transition-all cursor-pointer duration-75',
          removeButtonClasses,
        )}
        onClick={onRemove}
      >
        <DeleteSVG />
      </div>
    </div>
  )
}
