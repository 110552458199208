interface SVGMenuOpenProps extends React.SVGProps<SVGSVGElement> {
  className: string
}

export const SVGMenuOpen = ({ className }: SVGMenuOpenProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 24 24"
      fill="none"
      stroke="gray"
      strokeWidth={1}
    >
      <path
        d="M4.04807 17.6346C3.83559 17.6346 3.65747 17.5627 3.51372 17.4189C3.36997 17.2751 3.2981 17.0969 3.2981 16.8843C3.2981 16.6717 3.36997 16.4936 3.51372 16.35C3.65747 16.2064 3.83559 16.1346 4.04807 16.1346H15.0481C15.2606 16.1346 15.4387 16.2066 15.5824 16.3504C15.7262 16.4942 15.798 16.6724 15.798 16.885C15.798 17.0976 15.7262 17.2756 15.5824 17.4192C15.4387 17.5628 15.2606 17.6346 15.0481 17.6346H4.04807ZM4.04807 12.75C3.83559 12.75 3.65747 12.6781 3.51372 12.5343C3.36997 12.3905 3.2981 12.2123 3.2981 11.9997C3.2981 11.7871 3.36997 11.609 3.51372 11.4654C3.65747 11.3218 3.83559 11.25 4.04807 11.25H12.0481C12.2606 11.25 12.4387 11.3219 12.5824 11.4657C12.7262 11.6096 12.798 11.7878 12.798 12.0003C12.798 12.2129 12.7262 12.391 12.5824 12.5346C12.4387 12.6782 12.2606 12.75 12.0481 12.75H4.04807ZM4.04807 7.86539C3.83559 7.86539 3.65747 7.79348 3.51372 7.64967C3.36997 7.50587 3.2981 7.32767 3.2981 7.11507C3.2981 6.90248 3.36997 6.7244 3.51372 6.58082C3.65747 6.43722 3.83559 6.36542 4.04807 6.36542H15.0481C15.2606 6.36542 15.4387 6.43733 15.5824 6.58114C15.7262 6.72496 15.798 6.90316 15.798 7.11574C15.798 7.32834 15.7262 7.50643 15.5824 7.65002C15.4387 7.7936 15.2606 7.86539 15.0481 7.86539H4.04807ZM17.1019 12L20.175 15.0731C20.3134 15.2116 20.3843 15.3856 20.3875 15.5952C20.3907 15.8048 20.3199 15.9821 20.175 16.1269C20.0301 16.2718 19.8545 16.3442 19.6481 16.3442C19.4417 16.3442 19.266 16.2718 19.1212 16.1269L15.6269 12.6327C15.4462 12.4519 15.3558 12.241 15.3558 12C15.3558 11.759 15.4462 11.5481 15.6269 11.3673L19.1212 7.87312C19.2596 7.73465 19.4337 7.66382 19.6433 7.66062C19.8529 7.6574 20.0301 7.72823 20.175 7.87312C20.3199 8.01798 20.3923 8.19362 20.3923 8.40002C20.3923 8.60642 20.3199 8.78205 20.175 8.92692L17.1019 12Z"
        fill="gray"
        className={className}
      />
    </svg>
  )
}
