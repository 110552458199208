/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
import { NestedCategories } from '~/shared/api'

export function deepArrayComparison(
  arr1: NestedCategories[],
  arr2: NestedCategories[],
) {
  // Проверяем длину массивов
  if (arr1.length !== arr2.length) {
    return false
  }

  // Перебираем элементы массивов
  for (let i = 0; i < arr1.length; i += 1) {
    const obj1 = arr1[i]
    const obj2 = arr2[i]

    // Проверяем тип объектов
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return false
    }

    // Проверяем свойства объектов, кроме categories
    const keys1 = Object.keys(obj1).filter((key) => key !== 'categories')
    const keys2 = Object.keys(obj2).filter((key) => key !== 'categories')

    if (keys1.length !== keys2.length) {
      return false
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys1) {
      if (
        obj1[key as keyof NestedCategories] !==
        obj2[key as keyof NestedCategories]
      ) {
        return false
      }
    }

    // Рекурсивно вызываем функцию для свойства categories
    if (obj1.categories && obj2.categories) {
      if (!deepArrayComparison(obj1.categories, obj2.categories)) {
        return false
      }
    } else if (
      (obj1.categories && !obj2.categories) ||
      (!obj1.categories && obj2.categories)
    ) {
      return false
    }
  }

  return true
}

export function insertElementWithShift<T>(
  array: T[],
  index: number,
  newElement: T,
): T[] {
  if (index < 0 || index > array.length) {
    // Некорректный индекс, возвращаем исходный массив без изменений
    return array
  }

  const clonedArray = [...array]
  clonedArray.splice(index, 0, newElement)

  return clonedArray
}

export function moveElementInNestedArray<T extends { categories?: T[] }>(
  array: T[],
  toIndex: number,
): T[] {
  if (toIndex < 0 || toIndex >= array.length) {
    // Некорректный индекс, возвращаем исходный массив без изменений
    return array
  }

  const clonedArray = array.map((item) => {
    // Клонируем каждый объект в массиве, чтобы сохранить иммутабельность
    return {
      ...item,
      categories: item.categories
        ? moveElementInNestedArray(item.categories, toIndex)
        : [],
    }
  })

  const [itemToMove] = clonedArray.splice(toIndex, 1) // Удаляем элемент из исходного индекса
  clonedArray.push(itemToMove) // Вставляем элемент на новый индекс (в конец массива)

  return clonedArray
}

export function recursiveMove(
  items: NestedCategories[],
  sourceId: string,
  destinationId: string,
): NestedCategories[] {
  const clonedItems = items.map((item) => {
    // Клонируем каждый объект в массиве, чтобы сохранить иммутабельность
    return { ...item, categories: item.categories ? [...item.categories] : [] }
  })

  let sourceItem: NestedCategories | undefined

  // Функция для поиска элемента по его id и его удаления из исходного массива
  const findAndRemoveItemById = (
    id: string,
    items: NestedCategories[],
  ): NestedCategories[] => {
    for (let i = 0; i < items.length; i += 1) {
      const item = items[i]
      if (item.id === id) {
        sourceItem = item
        return [...items.slice(0, i), ...items.slice(i + 1)]
      }
      if (item.categories) {
        const updatedCategories = findAndRemoveItemById(id, item.categories)
        if (updatedCategories !== item.categories) {
          return [
            ...items.slice(0, i),
            { ...item, categories: updatedCategories },
            ...items.slice(i + 1),
          ]
        }
      }
    }
    return items
  }

  const updatedItemsWithoutSource = findAndRemoveItemById(sourceId, clonedItems)

  if (sourceItem) {
    const findAndInsertItemById = (
      id: string,
      items: NestedCategories[],
    ): NestedCategories[] => {
      for (let i = 0; i < items.length; i += 1) {
        const item = items[i]
        if (item.id === id) {
          return [
            ...items.slice(0, i),
            { ...item, categories: [...(item.categories || []), sourceItem] },
            ...items.slice(i + 1),
          ] as NestedCategories[]
        }
        if (item.categories) {
          const updatedCategories = findAndInsertItemById(id, item.categories)
          if (updatedCategories !== item.categories) {
            return [
              ...items.slice(0, i),
              { ...item, categories: updatedCategories },
              ...items.slice(i + 1),
            ]
          }
        }
      }
      return items
    }

    return findAndInsertItemById(destinationId, updatedItemsWithoutSource)
  }

  return clonedItems
}

export function findById(
  tree: NestedCategories[],
  nodeId: string,
): NestedCategories | null {
  for (let i = 0; i < tree.length; i += 1) {
    const node = tree[i]
    if (node.id === nodeId) return node

    if (node.categories) {
      const desiredNode = findById(node.categories, nodeId)
      if (desiredNode) return desiredNode
    }
  }

  return null
}
export function recursiveRemove(
  list: NestedCategories[],
  id: string,
): NestedCategories[] {
  // Клонируем каждый объект в массиве, чтобы сохранить иммутабельность
  return list
    .map((item) => {
      return { ...item }
    })
    .filter((item) => {
      // Рекурсивно вызываем функцию для удаления элемента с id из categories
      if ('categories' in item) {
        item.categories = recursiveRemove(item.categories || [], id)
      }
      // Фильтруем элементы, оставляя только те, у которых id не совпадает с заданным
      return item.id !== id
    })
}

export function moveElement<T>(array: T[], fromIndex: number, toIndex: number) {
  const arrayCopy = [...array]
  const element = arrayCopy.splice(fromIndex, 1)[0]

  arrayCopy.splice(toIndex, 0, element)

  return arrayCopy
}

export function replaceCategoriesById(
  tree: NestedCategories[],
  parentId: string,
  newCategories: NestedCategories[],
): NestedCategories[] {
  const newTree = tree.map((node) => {
    if (node.id === parentId) {
      return { ...node, categories: newCategories }
    }
    if (node.categories) {
      return {
        ...node,
        categories: replaceCategoriesById(
          node.categories,
          parentId,
          newCategories,
        ),
      }
    }
    return node
  })
  return newTree
}

export function flattenArrayWithCategories(
  arr: NestedCategories[],
): NestedCategories[] {
  const result: NestedCategories[] = []

  function recursiveFlatten(innerArr: NestedCategories[]) {
    for (let i = 0; i < innerArr.length; i += 1) {
      const item = innerArr[i]
      result.push(item)
      if (item.categories && Array.isArray(item.categories)) {
        recursiveFlatten(item.categories)
      }
    }
  }

  recursiveFlatten(arr)

  return result
}
