import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router-dom'
import { useEffectOnce, useUpdateEffect } from 'usehooks-ts'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useStore } from '~/app/store'
import { CategoriesCards } from '~/features/products/categories'
import { CategoriesListSkeleton } from './components'

export const CategoriesListWidget = observer(() => {
  const { categoriesStore } = useStore()
  const { getCategories, filteredCategories, categoriesLoading } =
    categoriesStore

  const { search } = useLocation()

  useEffectOnce(() => {
    if (search.length) {
      getCategories(search)
    }
  })

  useUpdateEffect(() => {
    getCategories(search)
  }, [getCategories, search])

  return (
    <DndProvider backend={HTML5Backend}>
      {categoriesLoading ? (
        <CategoriesListSkeleton />
      ) : (
        <CategoriesCards data={filteredCategories} />
      )}
    </DndProvider>
  )
})
