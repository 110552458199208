import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosError, AxiosResponse } from 'axios'
import { StoresService } from '~/shared/api/services/stores'

import {
  Announcements,
  CounriesSelect,
  Currencie,
  FakeShiping,
  Languages,
  MarketPlace,
  PaymentSystem,
  PaymentZone,
  PickUpPointsData,
  Regions,
  StoreByIdModel,
  UpdatedStore,
  SMTP,
} from '~/shared/api/services/stores/types'
import { convertLocalizations } from '~/features/stores/controllers/general-field/helper'
import { Store } from '~/shared/api/services/global/types'

const initStoreById = {
  accountingToken: '',
  address: '',
  categories: {},
  currencies: [],
  defaultCurrency: '',
  defaultLanguage: '',
  domain: '',
  email: '',
  id: '',
  languages: [],
  legalEntityId: '',
  logo: { id: 0, original: '', type: '', url: '' },
  name: '',
  notificationEmail: '',
  notifyNewOrders: false,
  payments: [],
  phone: '',
  shippingProviders: [
    {
      code: '',
      name: '',
      rates: '',
      state: '',
      tariffs: '',
    },
  ],
  smtp: {
    encryption: '',
    host: '',
    logo: {
      id: 0,
      original: '',
      type: '',
      url: '',
    },
    password: '',
    port: '',
    subject: '',
    username: '',
  },
  sortByAvailable: false,
}

export class StoresModel {
  hasStore = false

  stores: Store[] = []

  error: null | string = null

  storesLoading = false

  error404 = false

  countries: CounriesSelect[] = []

  countriesLoading = false

  paymentSystem: PaymentSystem[] = []

  currentStore: StoreByIdModel = initStoreById

  languages: Languages[] = []

  currencies: Currencie[] = []

  activeCurrencies: string[] = []

  fakeShipping: FakeShiping[] = []

  updatedFakeShippings: string[] = []

  loadShipping = false

  paymentZones: PaymentZone[] = []

  updatedPaymentZones: string[] = []

  pickUpPoints: PickUpPointsData[] = []

  updatedPickUpPoints: string[] = []

  regions: {
    [key: string]: Regions[]
  } = {}

  marketPlace: MarketPlace[] = []

  secrets: { secretKey: string; secret: string; newValue: string }[] = []

  marketSecrets: { name: string; value: string | null; newValue: string }[] = []

  announcements: Announcements[] = []

  updatedAnnouncements: string[] = []

  loading = false

  updatedStore: UpdatedStore = {
    settings: false,
    smtp: false,
    currencies: false,
    paymentZones: false,
    paymentSystems: false,
    fakeShipping: false,
    pickUpPoint: false,
    announcements: false,
  }

  validateErrors: { [key: string]: { [key: string]: boolean } } = {
    settings: {
      phone: false,
      currencies: false,
      name: false,
      address: false,
      logo: false,
    },
    smtp: {
      password: false,
      host: false,
      port: false,
      username: false,
      logo: false,
    },
    currencies: {},
    paymentZones: {},
    fakeShipping: {},
    pickUpPoints: {},
    announcements: {},
  }

  updatedStoreErrors: { status: boolean; updeter: string }[] = []

  constructor() {
    makeAutoObservable(this)
  }

  getLanguages = async () => {
    try {
      const { data } = await StoresService.getLanguages()

      runInAction(() => {
        this.languages = data
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }

    return []
  }

  getAnnouncements = async (id: string | undefined) => {
    try {
      if (id === undefined) {
        throw new Error('ID is undefined')
      }
      const { data } = await StoresService.getAnnouncements(id)

      runInAction(() => {
        this.announcements = data.map((e) => {
          return {
            ...e,
            localizations: convertLocalizations(e.localizations),
            activeTab: e.localizations[0].languageCode,
          }
        })
      })
      this.validator('announcements')
      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getCountries = async () => {
    this.countriesLoading = true
    try {
      const { data } = await StoresService.getCountries()

      runInAction(() => {
        this.countries = data
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.countriesLoading = false
      })
    }
  }

  getPaymentSystem = async () => {
    try {
      const { data } = await StoresService.getPaymentSystem()

      runInAction(() => {
        this.paymentSystem = data
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {})
    }
  }

  getFakeShiping = async (id: string) => {
    try {
      const { data } = await StoresService.getFakeShiping(id)

      runInAction(() => {
        this.fakeShipping = data
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.loadShipping = true
      })
      this.validator('fakeShipping')
    }
  }

  getPickUpPoints = async (id: string | undefined) => {
    try {
      const { data } = await StoresService.getPickUpPoints(id)

      if (data && data.length > 0) {
        runInAction(() => {
          const newData = data.map((el) => {
            const [countryCode, regionCode] = el.countryZoneCode.split('.')

            return {
              ...el,
              id: el.id,
              enable: el.enable,
              activeTab: this.currentStore.defaultLanguage,
              phone: el.phone,
              countryCode,
              regionCode,
              unpaidOrdersDisabled: el.unpaidOrdersDisabled,
              localizations: convertLocalizations(el.localizations),
              latitude: el.latitude,
              longitude: el.longitude,
              storeId: el.storeId,
              unpaidOrdersDays: el.unpaidOrdersDays,
              warehouseId: el.warehouseId,
            }
          })

          this.pickUpPoints = newData
          this.validator('pickUpPoints')
        })
      }

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {})
    }
  }

  getRegions = async () => {
    this.loading = true
    try {
      const regs = Object.keys(this.regions)
      if (
        regs.length === 0 ||
        !this.pickUpPoints.every((point) =>
          regs.some((reg) => point.countryCode === reg),
        )
      ) {
        const uniqCountryCodes = this.pickUpPoints.reduce(
          (acc: string[], el: PickUpPointsData) => {
            if (acc.includes(el.countryCode)) {
              return acc
            }
            return [...acc, el.countryCode]
          },
          [],
        )
        const data = await Promise.allSettled(
          uniqCountryCodes.map((e) => StoresService.getRegions(e)),
        )

        runInAction(() => {
          this.regions = data.reduce(
            (acc: { [key: string]: Regions[] }, el, i) => {
              if (el.status === 'fulfilled') {
                acc[uniqCountryCodes[i]] = el.value.data
              }
              return acc
            },
            {},
          )
        })

        return data
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  getMarketplace = async () => {
    try {
      const { data } = await StoresService.getMarketplace()

      runInAction(() => {
        this.marketPlace = data
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {})
    }
  }

  getPaymentZoneByStoreId = async (id: string | undefined) => {
    if (id === undefined) {
      return
    }
    try {
      const { data } = await StoresService.getPaymentZoneByStoreId(id)

      runInAction(() => {
        this.paymentZones = data
      })
      this.validator('paymentZones')

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getStoreById = async (id: string) => {
    this.currentStore = initStoreById
    this.hasStore = false
    this.storesLoading = true
    this.error404 = false
    this.activeCurrencies = []
    this.currencies = []
    this.fakeShipping = []
    this.loadShipping = false
    this.pickUpPoints = []
    try {
      const { data } = await StoresService.getStoreById(id)
      const currenciesBank = await StoresService.getCurrencies()
      const filterCurrencies = currenciesBank.data.filter(
        (cur) => !data.currencies.some((el: Currencie) => el.code === cur.code),
      )
      runInAction(() => {
        this.activeCurrencies = data.currencies.map((e: Currencie) => e.code)
        this.currentStore = {
          ...data,
          name: data.name || '',
          address: data.address || '',
          phone: data.phone ? data.phone.toString() : '',
          domain: data.domain || '',
          logo: data.logo || initStoreById.logo,
          smtp: data.smtp || initStoreById.smtp || '',
        }
        this.currencies = [
          ...data.currencies
            .map((activeCur: Currencie) => {
              if (activeCur.code === 'RUB') {
                return { ...activeCur, rateBank: 1, rateStore: 1 }
              }
              const findCurBank = currenciesBank.data.find(
                (e) => e.code === activeCur.code,
              )
              return {
                ...activeCur,
                rateStore: activeCur.rate,
                rateBank: findCurBank?.rate,
                rubles: findCurBank?.rubles,
              }
            })
            .sort((a, b) => {
              if (a.code === 'RUB') return -1
              if (b.code === 'RUB') return 1
              return a.code.localeCompare(b.code)
            }),
          ...filterCurrencies.map((e) => ({
            ...e,
            rateStore: '',
            rateBank: e.rate,
          })),
        ]
      })
      this.hasStore = true
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 404) {
          runInAction(() => {
            this.error404 = true
          })
        }

        throw error.response?.data
      }
    } finally {
      runInAction(() => {
        this.storesLoading = false
        this.validator('smtp')
      })
    }
  }

  getSecrets = async (system: PaymentSystem) => {
    runInAction(() => {
      this.loading = true
    })

    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const secrets: { secretKey: string; secret: any; newValue: string }[] = []
      const secretKeys = Object.keys(system.secrets)

      if (secretKeys.length) {
        runInAction(() => {
          this.secrets = []
        })

        const data = await Promise.allSettled(
          secretKeys.map(async (secretKey) => {
            try {
              const response = await StoresService.getSecret(
                this.currentStore.id,
                system.code,
                secretKey,
              )
              return response as AxiosResponse<{ secret: string }>
            } catch (error) {
              return { data: { secret: null } } as AxiosResponse<{
                secret: null
              }>
            }
          }),
        )

        secretKeys.forEach((key, i) => {
          const secretValue =
            data[i].status === 'fulfilled' ? data[i].value.data.secret : null

          secrets.push({
            secretKey: key,
            secret: secretValue || '',
            newValue: '',
          })
        })

        runInAction(() => {
          this.secrets = secrets
        })
      } else {
        runInAction(() => {
          this.secrets = []
        })
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  getMarketSecrets = async (marketplace: string) => {
    runInAction(async () => {
      try {
        this.loading = true
        const { data } = await StoresService.getMarketSecret(
          this.currentStore.id,
          marketplace,
        )
        this.marketSecrets = data.secrets.map((e) => ({
          ...e,
          newValue: '',
        }))
      } catch (error) {
        if (error instanceof AxiosError) {
          throw new Error(error.response?.config.data)
        }
      } finally {
        this.loading = false
      }
    })
  }

  validator = (flag: string) => {
    if (flag === 'currencies') {
      const checkOneInvalid = this.currencies?.some((e) => {
        if (this.activeCurrencies.includes(e.code)) {
          if (
            !e?.rateStore ||
            !(Number(e?.rateStore) >= 0 && Number(e?.rateStore) < 100)
          ) {
            return true
          }
        }
        return false
      })
      if (this.activeCurrencies.length > 0 && !checkOneInvalid) {
        this.validateErrors = {
          ...this.validateErrors,
          currencies: {},
        }
      } else {
        this.validateErrors = {
          ...this.validateErrors,
          currencies: { hasError: true },
        }
      }
    }
    if (flag === 'smtp') {
      const data = Object.keys(this.currentStore.smtp)

      data.forEach((smtpKey) => {
        const str = smtpKey as keyof SMTP
        if (smtpKey in this.validateErrors.smtp) {
          this.validateErrors = {
            ...this.validateErrors,
            smtp: {
              ...this.validateErrors.smtp,
              [smtpKey]: !this.currentStore.smtp[str],
            },
          }
        }
      })
    }
    if (flag === 'paymentZones') {
      const checkError = this.paymentZones.reduce((acc, e) => {
        if (
          !e.countries.length ||
          !e.name ||
          !(String(e.percent) && Number(e.percent) >= 0)
        ) {
          const key = e.id as string
          acc[key] = true
        }
        return acc
      }, {} as { [key: string]: boolean })
      this.validateErrors = {
        ...this.validateErrors,
        paymentZones: checkError,
      }
    }
    if (flag === 'fakeShipping') {
      const checkError = this.fakeShipping.reduce((acc, e) => {
        if (!e.countries.length || !e.name || !e.provider || !e.image) {
          const key = e.id as string
          acc[key] = true
        }
        return acc
      }, {} as { [key: string]: boolean })
      this.validateErrors = {
        ...this.validateErrors,
        fakeShipping: checkError,
      }
    }
    if (flag === 'pickUpPoints') {
      const checkError = this.pickUpPoints.reduce((acc, e) => {
        const localError = Object.values(e.localizations).some(
          (loc) => !loc.description || !loc.addressText || !loc.name,
        )
        if (!e.phone || !e.countryCode || !e.regionCode || localError) {
          const key = e.id as string
          acc[key] = true
        }
        return acc
      }, {} as { [key: string]: boolean })
      this.validateErrors = {
        ...this.validateErrors,
        pickUpPoints: checkError,
      }
    }
    if (flag === 'announcements') {
      const checkError = this.announcements.reduce((acc, e) => {
        const hasError = Object.values(e.localizations).some(
          (loc) => !loc.htmlText || !loc.languageCode,
        )

        const key = e.id as string
        acc[key] = hasError

        return acc
      }, {} as { [key: string]: boolean })

      this.validateErrors = {
        ...this.validateErrors,
        announcements: checkError,
      }
    }
  }
}
