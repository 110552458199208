import { FC } from 'react'
import { Button } from '~/shared/ui'
import { pagination } from './config'
import { PaginationProps } from './types'

const Pagination: FC<PaginationProps> = ({
  allPages,
  currentPage,
  className,
  firstPage,
  isPreviousPage,
  previousPage,
  nextPage,
  isNextPage,
  lastPage,
  handlePagination,
  pageSize,
}) => {
  return (
    <div className={pagination({ className })}>
      <Button
        className="border rounded p-1 disabled:border"
        size="small"
        onClick={firstPage}
        disabled={!isPreviousPage}
        intent="secondary"
      >
        {'<<'}
      </Button>

      <Button
        className="border rounded p-1 disabled:border"
        size="small"
        onClick={previousPage}
        disabled={!isPreviousPage}
        intent="secondary"
      >
        {'<'}
      </Button>

      <Button
        className="border rounded p-1 disabled:border"
        size="small"
        onClick={nextPage}
        disabled={!isNextPage}
        intent="secondary"
      >
        {'>'}
      </Button>

      <Button
        className="border rounded p-1 disabled:border"
        size="small"
        onClick={lastPage}
        disabled={!isNextPage}
        intent="secondary"
      >
        {'>>'}
      </Button>

      <span className="flex items-center gap-1 font-bold">
        {currentPage}
        <span className="text-black">of</span>
        {allPages}
      </span>

      <select
        value={pageSize}
        onChange={handlePagination}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 "
      >
        {[5, 10, 20, 30, 40, 50].map((size) => (
          <option key={size} value={size}>
            Show
            {size}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Pagination
