import css from 'classnames'
import { FC, useCallback } from 'react'
import { Props } from './types'
import { SVGNOtFoto } from '~/shared/ui/SVG/SVGNotFoto'

export const StoresFilter: FC<Props> = ({
  data = [],
  changeStores,
  selectedStores = [],
  className,
  cardClassName,
}) => {
  const handleClick = useCallback(
    (id: string) => {
      changeStores?.(id)
    },
    [changeStores],
  )

  if (data.length === 1) return null

  if (data.length === 0) {
    return null
  }

  return (
    <div className={css('flex items-center gap-4 flex-wrap', className)}>
      {data.map((item) => (
        <div
          key={item.id}
          className={css(
            'inline-flex min-w-[240px] gap-2 p-2 pr-4  bg-slate-100 cursor-pointer items-center box-border rounded-lg border border-[#E0E0E0] hover:bg-slate-200 transition ',
            {
              'bg-white outline outline-4 -outline-offset-4 outline-blue-600':
                selectedStores?.includes(item.id),
            },
            cardClassName,
          )}
          onClick={() => handleClick?.(item.id)}
        >
          {typeof item.logo === 'string' ? (
            <img className="w-16 h-16" src={item.logo} alt="store_logo" />
          ) : (
            <SVGNOtFoto size="64" />
          )}

          <div className="flex flex-col">
            <span className="text-[14px]">{item.name}</span>
          </div>
        </div>
      ))}
    </div>
  )
}
