import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce, useUpdateEffect } from 'usehooks-ts'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router-dom'
import { Props } from './types'
import { StoresFilter } from '~/entities/stores-filter'
import { Button, ToolTip } from '~/shared/ui'
import { useStore } from '~/app/store'
import { useFilters, useToast } from '~/shared/lib'
import { Input } from '~/shared/ui/input'

export const ProductsCategoriesFilters: FC<Props> = observer(({ data }) => {
  const { t } = useTranslation()
  const { categoriesStore } = useStore()
  const toast = useToast()

  const {
    filterCategoriesByName,
    getMappedData,
    updateCategories,
    isListUpdated,
    updatedCategories,
    isUpdateSuccess,
    isUpdatingCategories,
  } = categoriesStore

  const [name, setName] = useState('')
  const debouncedValue = useDebounce(name, 300)
  const { search } = useLocation()

  const { handleFilter, getSelectActiveData } = useFilters({
    savedFiltersKey: 'categoriesFilters',
    selectData: {
      stores: getMappedData.stores,
    },
  })

  const handleStore = useCallback(
    (id: string) => {
      handleFilter('stores', [id])

      localStorage.setItem('categoryStoreIdFilter', id)
    },
    [handleFilter],
  )

  const handleName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  useUpdateEffect(() => {
    filterCategoriesByName(debouncedValue)
  }, [debouncedValue])

  useUpdateEffect(() => {
    if (isUpdateSuccess) {
      toast.open(t('successfullyUpdated'), 'success')
    }
  }, [isUpdateSuccess])

  useEffect(() => {
    if (search.length) return

    if (data.length) {
      handleStore(data[0].id)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      <StoresFilter
        data={data}
        changeStores={(id) => handleStore(id)}
        selectedStores={getSelectActiveData('stores').map((item) => item.value)}
        className="mt-8"
        cardClassName="bg-transparent"
      />
      <div className="flex items-center justify-between mt-5">
        <div className="max-w-[320px] w-full">
          <Input
            label={t('name2')}
            value={name}
            labelType="floating"
            onChange={handleName}
            renderIconEnd={
              <ToolTip
                tooltipTitle={t('defaultToolTipTitle')}
                tooltipText={t('defaultToolTipText')}
              />
            }
          />
        </div>

        {isListUpdated ? (
          <Button
            isLoading={isUpdatingCategories}
            onClick={() =>
              updateCategories(
                updatedCategories,
                getSelectActiveData('stores')[0]?.value,
              )
            }
          >
            {t('update')}
          </Button>
        ) : null}
      </div>
    </>
  )
})
