import { FC } from 'react'

import { EmptyProps } from './type'
import { empty } from './config'
import { SVGFolder } from '../SVG/SVGFolder'

export const Empty: FC<EmptyProps> = ({ className, text = 'No data' }) => {
  return (
    <div className={empty({ className })}>
      <SVGFolder />

      <p>{text}</p>
    </div>
  )
}

export default Empty
