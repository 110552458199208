/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosError } from 'axios'
import { WarehousesService } from '~/shared/api/services/warehouses'
import {
  Countries,
  DataGenerateCSV,
  Incoming,
  IncomingNumber,
  Loaders,
  Order,
  Stores,
  Suppliers,
  Warehouses,
  IncomingProduct,
} from '~/shared/api/services/warehouses/types'

export class WarehousesModel {
  [key: string]: any

  warehouseError = false

  error404 = false

  incomingNumber: IncomingNumber | null = null

  incomingProductsCheck = false

  switcher = true

  incomingProducts: null | IncomingProduct[] = null

  currentDataWarehouse: Incoming[] = []

  suppliers: Suppliers[] = []

  countries: Countries[] = []

  warehouses: Warehouses[] = []

  stores: Stores[] = []

  data: Order | null = null

  dataGenerateCSV: DataGenerateCSV | null = null

  loaders: Loaders = {
    createNewEntrance: false,
    createSuppliers: false,
    loadMoreArrivalsById: false,
    loadArrivalsById: false,
    accept: false,
    delete: false,
  }

  requestSettings = {
    limit: 20,
    offset: 0,
    isAllLoaded: false,
    lastContentId: '',
  }

  constructor() {
    makeAutoObservable(this)
  }

  setSwitcher(state: boolean) {
    runInAction(() => {
      this.switcher = state
    })
  }

  setIncomingProductsCheck(value: boolean) {
    runInAction(() => {
      this.incomingProductsCheck = value
    })
  }

  setIncomingProducts(data: IncomingProduct[]) {
    runInAction(() => {
      this.incomingProducts = data
    })
  }

  activeLoader = (key: keyof Loaders) => {
    this.loaders = { ...this.loaders, [key]: true }
  }

  closeLoader = (key: keyof Loaders) => {
    this.loaders = { ...this.loaders, [key]: false }
  }

  getWarehouses = async () => {
    try {
      const { data } = await WarehousesService.getWarehouses()
      runInAction(() => {
        this.warehouses = data
      })
      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getWarehouseID = async (
    id: string,
    action: 'loadMoreArrivalsById' | 'loadArrivalsById',
  ) => {
    this.activeLoader(action)
    if (this.requestSettings.lastContentId !== id) {
      this.requestSettings = {
        ...this.requestSettings,
        isAllLoaded: false,
        offset: 0,
      }
    }
    try {
      if (this.requestSettings.isAllLoaded) return
      const { data } = await WarehousesService.getWarehouseID({
        params: {
          limit: this.requestSettings.limit,
          offset: this.requestSettings.offset,
          warehouseId: id,
        },
      })

      runInAction(() => {
        if (this.requestSettings.lastContentId === id) {
          this.currentDataWarehouse = [...this.currentDataWarehouse, ...data]
        } else {
          this.currentDataWarehouse = data
        }
      })
      this.requestSettings = {
        ...this.requestSettings,
        isAllLoaded: data.length === 0,
        offset: this.requestSettings.offset + this.requestSettings.limit,
        lastContentId: id,
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      this.closeLoader(action)
    }
  }

  getSuppliers = async () => {
    try {
      const { data } = await WarehousesService.getSuppliers()
      runInAction(() => {
        this.suppliers = data
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getCountries = async () => {
    try {
      const { data } = await WarehousesService.getCountries()
      runInAction(() => {
        this.countries = data.filter((el) => el.numberCode !== null)
      })
      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getIncomingNumber = async (number: string) => {
    try {
      const { data } = await WarehousesService.getIncomingNumber(number)
      runInAction(() => {
        this.incomingNumber = data
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  deleteEntrance = async (listNumber: string) => {
    try {
      await WarehousesService.deleteEntrance(listNumber, {
        incomingListNumber: this.incomingNumber?.number,
      })
      this.resetRequestSettings()
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.data?.error)
      }
    }
  }

  generateCSV = async (listNumber: string) => {
    try {
      this.activeLoader('delete')
      const { data } = await WarehousesService.generateCSV(listNumber, {
        incomingListNumber: this.incomingNumber?.number,
      })
      runInAction(() => {
        this.dataGenerateCSV = data
      })
      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.data?.error)
      }
    } finally {
      runInAction(() => {
        this.closeLoader('delete')
      })
    }
  }

  accept = async (data: any) => {
    try {
      this.activeLoader('accept')
      await WarehousesService.accept(data)
      this.requestSettings = {
        ...this.requestSettings,
        lastContentId: '',
        isAllLoaded: false,
        offset: 0,
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.closeLoader('accept')
      })
    }
  }

  resetRequestSettings = () => {
    this.requestSettings = {
      limit: 20,
      offset: 0,
      isAllLoaded: false,
      lastContentId: '',
    }
  }
}
