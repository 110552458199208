import { FC } from 'react'
import css from 'classnames'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Props } from './types'
import { SVGMenuOpen } from '~/shared/ui'
import { SVGLogo } from '~/shared/ui/SVG/SVGLogo'

export const SidebarHeader: FC<Props> = ({ isOpen, toggleMenu }) => {
  return (
    <motion.div
      variants={{
        open: {
          flexDirection: 'row',
        },
        closed: {
          height: '100px',
          flexDirection: 'column',
        },
      }}
      animate={isOpen ? 'open' : 'closed'}
      className={css('flex items-center px-2 gap-5 h-16')}
    >
      <Link to="/">
        <SVGLogo width="45" height="45" />
      </Link>

      <div
        className={css(
          'cursor-pointer w-6 h-6 flex items-center justify-center ml-auto transition-all duration-100',
          { 'p-0 m-0 mx-auto': !isOpen },
        )}
        onClick={toggleMenu}
      >
        <div>
          <SVGMenuOpen
            className={css({
              transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
              transition: 'transform 200ms ease',
            })}
          />
        </div>
      </div>
    </motion.div>
  )
}
