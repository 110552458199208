import { cva } from 'class-variance-authority'

export const toast = cva(
  ['flex items-center gap-3 rounded-lg p-4 mb-4 text-sm shadow'],
  {
    variants: {
      variant: {
        primary: 'text-blue-800  bg-blue-50 ',
        danger: 'text-red-800 rounded-lg bg-red-50',
        success: 'text-green-800 rounded-lg bg-green-50',
        warning: 'text-yellow-800 rounded-lg bg-yellow-50',
        secondary: 'text-gray-800 rounded-lg bg-gray-50 ',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
)
