/* eslint-disable react/require-default-props */
import { useMemo, useState } from 'react'

interface ArrowToDownProps extends React.SVGProps<SVGSVGElement> {
  size?: number
  color?: string
  disabled?: boolean
}
/* eslint-disable react/jsx-props-no-spreading */
export const ArrowToDown = ({
  size = 20,
  color,
  disabled = false,
  ...props
}: ArrowToDownProps) => {
  const [isHovered, setIsHovered] = useState(false)
  const fill = useMemo(() => {
    if (color) {
      return color
    }

    return isHovered ? 'black' : 'gray'
  }, [isHovered, color])
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size || '40'}
      viewBox="0 0 16 16"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
      className={`${disabled ? 'opacity-50' : 'opacity-100'}`}
    >
      <path
        d="M7.99999 9.77427C7.92393 9.77427 7.85021 9.76145 7.77884 9.73581C7.70748 9.71016 7.6406 9.66615 7.57821 9.60376L4.57179 6.59736C4.47522 6.50078 4.42864 6.3854 4.43206 6.25121C4.43547 6.11702 4.48547 6.00164 4.58206 5.90506C4.67864 5.80848 4.79573 5.76019 4.93333 5.76019C5.07093 5.76019 5.18802 5.80848 5.28459 5.90506L7.99999 8.62046L10.7256 5.89481C10.8222 5.79823 10.9376 5.75165 11.0718 5.75506C11.206 5.75848 11.3213 5.80848 11.4179 5.90506C11.5145 6.00164 11.5628 6.11873 11.5628 6.25634C11.5628 6.39394 11.5145 6.51103 11.4179 6.60761L8.42178 9.60376C8.35939 9.66615 8.29358 9.71016 8.22434 9.73581C8.15512 9.76145 8.08034 9.77427 7.99999 9.77427Z"
        fill={fill}
      />
    </svg>
  )
}
