import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStore } from '~/app/store'
import { useToast } from '~/shared/lib'
import { Button, IconSave } from '~/shared/ui'

export const SaveCategory = observer(() => {
  const { t } = useTranslation()

  const toast = useToast()
  const navigate = useNavigate()

  const { pathname } = useLocation()

  const paths = pathname.split('/')
  const lastPath = paths[paths.length - 1]
  const isEditable = lastPath === 'edit'

  const { categoriesStore } = useStore()
  const {
    createCategoryData,
    saveCategory,
    setIsErrorStoreId,
    setIsErrorCategoryName,
    currentCategoryCreateForm,
    isCreating,
    setIsErrorSlug,
  } = categoriesStore

  const handleClick = () => {
    setIsErrorStoreId(false)
    setIsErrorCategoryName(false)
    setIsErrorSlug(false)

    if (!isEditable && !createCategoryData.category.storeId?.length) {
      setIsErrorStoreId(true)

      return
    }

    if (
      !isEditable &&
      currentCategoryCreateForm === 'RU' &&
      !createCategoryData.localization?.RU?.name?.length
    ) {
      setIsErrorCategoryName(true)
      return
    }

    if (
      !isEditable &&
      currentCategoryCreateForm === 'EN' &&
      !createCategoryData.localization?.EN?.name?.length
    ) {
      setIsErrorCategoryName(true)
      return
    }

    if (
      currentCategoryCreateForm === 'EN' &&
      !createCategoryData.localization?.EN?.name?.length
    ) {
      setIsErrorCategoryName(true)
      return
    }

    if (
      currentCategoryCreateForm === 'RU' &&
      !createCategoryData.localization?.RU?.name?.length
    ) {
      setIsErrorCategoryName(true)
      return
    }

    if (!createCategoryData.category.slug.length) {
      setIsErrorSlug(true)
    }

    saveCategory(createCategoryData, createCategoryData.category.id).then(
      (res) => {
        if (res?.error?.length) {
          toast.open(res?.error, 'danger')
          return
        }

        navigate(-1)
      },
    )
  }

  return (
    <Button isLoading={isCreating} intent="success" onClick={handleClick}>
      <IconSave />
      {isEditable ? t('save') : t('create')}
    </Button>
  )
})
