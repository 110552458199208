import { cva } from 'class-variance-authority'

export const button = cva(
  [
    'border rounded-md',
    'active:bg-opacity-80',
    'disabled:opacity-50 disabled:cursor-not-allowed',
    'flex items-center gap-2',
    'transition-all duration-100',
    'font-normal leading-6',
  ],
  {
    variants: {
      intent: {
        primary:
          'bg-blue-600 text-white border-transparent hover:bg-blue-500 disabled:opacity-50 disabled:hover:bg-blue-600',
        secondary:
          'bg-white text-gray-800 border-gray-300 hover:bg-gray-100 disabled:hover:bg-white  rounded-lg',
        gray: 'bg-slate-200 text-black border-transparent hover:bg-slate-300 disabled:hover:bg-slate-200 ',
        danger:
          'bg-red-600 text-white border-transparent hover:bg-red-500 disabled:hover:bg-red-500',
        dark: 'bg-gray-800 text-white border-transparent hover:bg-gray-900 disabled:hover:bg-gray-800',
        success:
          'bg-green-600 text-white border border-transparent hover:bg-green-500 disabled:hover:bg-green-600',
      },
      size: {
        small: ['text-sm', 'py-1', 'px-2'],
        medium: ['text-base', 'py-2', 'px-4'],
        big: ['text-lg', 'py-3', 'px-6'],
        huge: ['text-lg', 'py-2', 'px-4'],
        new: ['text-sm', 'h-10', 'px-4'],
      },
    },
    compoundVariants: [
      {
        intent: 'primary',
        size: 'medium',
      },
      { intent: 'gray', size: 'huge' },
    ],
    defaultVariants: {
      intent: 'primary',
      size: 'medium',
    },
  },
)
