export const SVGProduct = () => {
  const fill = 'gray'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.99998 10.0385L11.3923 9.35C11.5897 9.2564 11.7923 9.2096 12 9.2096C12.2077 9.2096 12.4102 9.2564 12.6077 9.35L14 10.0385V4.99997H9.99998V10.0385ZM7.74995 16.75C7.53713 16.75 7.35893 16.6782 7.21535 16.5346C7.07177 16.391 6.99998 16.2128 6.99998 16C6.99998 15.7872 7.07177 15.609 7.21535 15.4654C7.35893 15.3218 7.53713 15.25 7.74995 15.25H11.25C11.4628 15.25 11.641 15.3218 11.7846 15.4654C11.9282 15.609 12 15.7872 12 16C12 16.2128 11.9282 16.391 11.7846 16.5346C11.641 16.6782 11.4628 16.75 11.25 16.75H7.74995ZM5.3077 20.5C4.80257 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.1974 3.5 18.6923V5.3077C3.5 4.80257 3.675 4.375 4.025 4.025C4.375 3.675 4.80257 3.5 5.3077 3.5H18.6923C19.1974 3.5 19.625 3.675 19.975 4.025C20.325 4.375 20.5 4.80257 20.5 5.3077V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H5.3077ZM5.3077 19H18.6923C18.7692 19 18.8397 18.9679 18.9038 18.9038C18.9679 18.8397 19 18.7692 19 18.6923V5.3077C19 5.23077 18.9679 5.16024 18.9038 5.09613C18.8397 5.03203 18.7692 4.99997 18.6923 4.99997H15.5V11.25C15.5 11.5371 15.3801 11.7541 15.1403 11.9009C14.9006 12.0477 14.6525 12.057 14.3961 11.9288L12 10.7307L9.60383 11.9288C9.34742 12.057 9.09936 12.0477 8.85962 11.9009C8.61987 11.7541 8.5 11.5371 8.5 11.25V4.99997H5.3077C5.23077 4.99997 5.16024 5.03203 5.09612 5.09613C5.03202 5.16024 4.99997 5.23077 4.99997 5.3077V18.6923C4.99997 18.7692 5.03202 18.8397 5.09612 18.9038C5.16024 18.9679 5.23077 19 5.3077 19Z"
        fill={fill}
      />
    </svg>
  )
}
