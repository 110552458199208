import { AxiosError } from 'axios'

const handleAxiosError = (error: unknown): string => {
  if (error instanceof AxiosError) {
    const errorData = error.response?.data

    if (errorData?.errors) {
      const errorMessages = Object.keys(errorData.errors)
        .map((key) => {
          const errorItem = errorData.errors[key]
          if (Array.isArray(errorItem)) {
            return errorItem.join('\n')
          }
          if (typeof errorItem === 'string') {
            return errorItem
          }
          return JSON.stringify(errorItem)
        })
        .join('\n')

      return errorMessages
    }

    if (errorData?.error) {
      return errorData.error
    }
  }

  return 'An unknown error occurred'
}
export default handleAxiosError
