import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import { generateUEID } from '../../helper-functions/ueid'
import { IContextModel, Props, ToastModel, ToastType } from './types'
import { Toast } from '~/shared/ui'

const initialState: IContextModel = {
  open: () => {},
}

export const ToastContext = createContext<IContextModel>(initialState)

export const ToastProvider: FC<Props> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastModel[]>([])

  const open = useCallback(
    (content: ReactNode, type: ToastType) =>
      setToasts((currentToasts) => [
        ...currentToasts,
        { id: generateUEID(), content, type },
      ]),
    [],
  )

  const close = useCallback(
    (id: string) =>
      setToasts((currentToasts) =>
        currentToasts.filter((toast) => toast.id !== id),
      ),
    [],
  )

  const value = useMemo(() => ({ open }), [open])

  return (
    <ToastContext.Provider value={value}>
      {children}

      {createPortal(
        <div className="fixed top-3 right-3 flex flex-col gap-3">
          {toasts.map((toast) => (
            <Toast
              key={toast.id}
              variant={toast.type}
              close={() => close(toast.id)}
            >
              {toast.content}
            </Toast>
          ))}
        </div>,
        document.body,
      )}
    </ToastContext.Provider>
  )
}
