import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import css from 'classnames'
import { Props } from './types'
import { UserInfo } from '~/shared/ui'
import { SubMenu } from '../sub-menu'
import { NavigationLink } from '../nav-link'
import { LogoutButton } from '~/features/auth'
import { useStore } from '~/app/store'

export const NavMenu: FC<Props> = ({ items, isOpen, openMenu }) => {
  const { t } = useTranslation()
  const { authStore } = useStore()
  const { user } = authStore

  const menu = items.map((l) => {
    return !l?.subLinks ? (
      <li className="group relative " key={l.link}>
        <NavigationLink
          link={l.link}
          icon={l.icon || ''}
          text={t(l.localeTextName)}
          isOpen={isOpen}
          activeClass="bg-gray-50"
        />

        <div
          className={css(
            'w-[2.5px]  h-4 rounded-md bg-blue-600 absolute left-0 top-4 opacity-0 group-hover:opacity-100 transition-all',
            {
              hidden: !isOpen,
            },
          )}
        />
      </li>
    ) : (
      <SubMenu key={l.link} item={l} isOpen={isOpen} openMenu={openMenu} />
    )
  })

  return (
    <ul className="py-4 px-3 h-full whitespace-pre text-black-700 flex flex-col justify-start overflow-x-hidden">
      {menu}

      <div className="flex justify-between items-center py-2 mt-auto ">
        <div
          className={css('flex items-center ', {
            'gap-3': isOpen,
            'gap-5': !isOpen,
          })}
        >
          <NavigationLink
            link="/settings"
            icon="settings"
            isOpen={isOpen}
            className=" [&>div]:hidden [&>p]:hidden"
            activeClass="bg-gray-100"
          />
          <UserInfo email={user?.email} name={user?.name} className="gap-3" />
        </div>
        <LogoutButton />
      </div>
    </ul>
  )
}
