import {
  StoreByIdModel,
  Localizations,
} from '~/shared/api/services/stores/types'

export const filterLanguage = (currentStore: StoreByIdModel) => {
  return currentStore.languages.filter(
    (curLang) =>
      curLang.code === currentStore.defaultLanguage || curLang.isDefault,
  )
}

export const convertLocalizations = <T extends { languageCode: string }>(
  arr: T[],
): Localizations<T> =>
  arr.reduce(
    (acc, loc) => ({
      ...acc,
      [loc.languageCode]: { ...loc },
    }),
    {} as Localizations<T>,
  )
