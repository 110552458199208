import { FC } from 'react'
import { ButtonProps } from './types'
import { button } from './config'
import { Spinner } from '../spinner'

export const Button: FC<ButtonProps> = ({
  className,
  intent,
  size,
  type = 'button',
  onClick,
  children,
  disabled = false,
  isLoading = false,
  hasBlueDot = false,
}) => (
  <button
    onClick={onClick}
    type={type}
    disabled={!!(isLoading || disabled)}
    className={button({ intent, size, className })}
    style={{ position: 'relative' }}
  >
    {isLoading ? <Spinner /> : null}
    {children}
    {hasBlueDot && (
      <span className="absolute top-[5px] right-[5px] w-[7px] h-[7px] rounded-[50%] bg-[#2563eb] " />
    )}
  </button>
)
