import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { apiService } from '../..'
import { Statistics, Store } from './types'

export class MainService {
  static getStatistics(storeId: string): Promise<AxiosResponse<Statistics>> {
    return apiService.get<AxiosResponse<Statistics>>(
      `/stores/${storeId}/statistics`,
    )
  }

  static getStores(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Store[]>> {
    return apiService.get<AxiosResponse<Store[]>>(`/stores`, config)
  }
}
