import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { apiService } from '../../api-base'
import {
  SalesData,
  Slider,
  UpdateSale,
  FormData,
  AvailabilityProduct,
  CouponsResponse,
  FormDataCoupon,
  UpdateCoupon,
  FormDataSlider,
  FormDataSlide,
  Slide,
} from './types'
import { Category, Product } from '../products/types'

export class MarketingService {
  static getSales(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<SalesData>> {
    return apiService.get<AxiosResponse<SalesData>>(`/sales`, config)
  }

  static getSliders(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Slider[]>> {
    return apiService.get<AxiosResponse<Slider[]>>(`/sliders`, config)
  }

  static getProductsForFilter(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Product[]>> {
    return apiService.get<AxiosResponse<Product[]>>(`/products`, config)
  }

  static getProductsForForm(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<AvailabilityProduct[]>> {
    return apiService.get<AxiosResponse<AvailabilityProduct[]>>(
      `/products/availabilities`,
      config,
    )
  }

  static getCategories(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Category[]>> {
    return apiService.get<AxiosResponse<Category[]>>(`/categories`, config)
  }

  static getCoupons(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<CouponsResponse>> {
    return apiService.get<AxiosResponse<CouponsResponse>>(`/coupons`, config)
  }

  static updateSale(sale: UpdateSale, path: 'product' | 'category') {
    return apiService.put(`/sales/${path}/${sale.id}`, {
      sale,
    })
  }

  static updateSlider(data: Slider): Promise<AxiosResponse> {
    return apiService.put<AxiosResponse>(`/sliders/${data.id}`, data)
  }

  static updateSlide(data: Slide, id: string): Promise<AxiosResponse> {
    return apiService.put<AxiosResponse>(
      `/sliders/${data.id}/slides/${id}`,
      data,
    )
  }

  static updateCoupon(coupon: UpdateCoupon) {
    return apiService.put(`/coupons/${coupon.id}`, {
      coupon,
    })
  }

  static createSale(data: FormData, path: 'product' | 'category') {
    return apiService.post(`/sales/${path}`, {
      sale: data,
    })
  }

  static createCoupon(
    data: FormDataCoupon & {
      type: 'Percent' | 'Fixed'
      typeArea: 'All' | 'Category'
    },
  ) {
    return apiService.post(`/coupons`, {
      coupon: data,
    })
  }

  static createSlider(data: FormDataSlider): Promise<AxiosResponse> {
    return apiService.post(`/sliders`, {
      ...data,
      storeId: data.storeId?.value,
    })
  }

  static createSlide(data: FormDataSlide, id: string): Promise<AxiosResponse> {
    return apiService.post(`/sliders/${id}/slides`, data)
  }

  static deleteSale(
    id: string,
    path: 'product' | 'category',
  ): Promise<AxiosResponse<SalesData>> {
    return apiService.delete<AxiosResponse<SalesData>>(`/sales/${path}/${id}`)
  }

  static deleteCoupon(id: string): Promise<AxiosResponse> {
    return apiService.delete<AxiosResponse>(`/coupons/${id}`)
  }

  static deleteSlider(id: string): Promise<AxiosResponse> {
    return apiService.delete<AxiosResponse>(`/sliders/${id}`)
  }
}
