import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useIntersectionObserver, useUpdateEffect } from 'usehooks-ts'
import { useMemo, useRef } from 'react'
import { TableContextMenu } from '~/shared/lib'
import { TableContainer } from '~/shared/ui'
import { columns } from './lib/config'
import { ListUser } from '~/shared/api'
import { useStore } from '~/app/store'
import GoToTop from '~/features/scroll-to-top'

export const UsersTableWidget = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { authStore } = useStore()
  const { usersList, isListLoading, getMoreUsers, isMoreLoading } = authStore

  const handleRowClick = (row: object) => {
    if ('id' in row) {
      navigate(`${pathname}/${row.id}/edit`)
    }
  }

  const tableBottom = useRef<HTMLDivElement>(null)

  const entry = useIntersectionObserver(tableBottom, {})
  const isVisible = useMemo(
    () => !!entry?.isIntersecting,
    [entry?.isIntersecting],
  )

  const contextMenu: TableContextMenu<ListUser>[] = [
    {
      text: t('edit'),
      handler: (row) => {
        navigate(`${pathname}/${row.id}/edit`)
      },
    },
    {
      text: t('delete'),
      handler: (row) => {
        // eslint-disable-next-line no-console
        console.log(`User ${row.id} successfully deleted!`)
      },
    },
  ]

  const anchor = useMemo(() => <div ref={tableBottom} />, [])

  useUpdateEffect(() => {
    if (!isListLoading && isVisible) {
      getMoreUsers()
    }
  }, [isVisible])

  return (
    <>
      <div className="mt-10">
        <TableContainer
          fixedWidth
          columns={columns}
          data={usersList}
          loadMore={isMoreLoading}
          isLoading={isListLoading}
          onRowClick={(row) => handleRowClick(row)}
          contextMenu={contextMenu}
        />
        {anchor}
      </div>
      <div className="fixed bottom-8 left-7">
        <GoToTop />
      </div>
    </>
  )
})
