import { AxiosError } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import {
  CategoriesService,
  CreateCategoryModel,
  Localization,
  NestedCategories,
  SingleCategoryResponse,
} from '~/shared/api'
import { mapDataForSelect, parseQuery, removeEmptyKeys } from '~/shared/lib'
import { flattenArrayWithCategories } from '../lib/helpers'
import { StoresService } from '~/shared/api/services/stores'
import { convertLocalizations } from '~/features/stores/controllers/general-field/helper'
import { Store } from '~/shared/api/services/global/types'

const defaultLocalization = {
  name: '',
  description: '',
  content: '',
  seoTitle: '',
  seoKeywords: '',
  seoDescription: '',
}

const initialCreateCategoryData = {
  category: {
    attachment: [],
    productAvailabilities: [],
    showInMenu: true,
    slug: '',
    name: '',
    sortOrder: 50,
    storeId: '',
    parentCategoryId: null,
  },
  localization: {},
}

export class CategoriesModel {
  stores: Store[] = []

  categories: NestedCategories[] = []

  createCategoryData: CreateCategoryModel = initialCreateCategoryData

  createCategoryDataValidation = {
    isErrorStoreId: false,
    isErrorCategoryName: false,
    isErrorSlug: false,
  }

  localeLang = 'RU'

  currentCategoryCreateForm = ''

  currentEditableCategory: SingleCategoryResponse | null = null

  selectedStores: string[] = []

  filteredCategories: NestedCategories[] = this.categories

  updatedCategories: NestedCategories[] = []

  storesLoading = false

  isCreating = false

  isDeleting = false

  isGettingCategory = false

  isListUpdated = false

  isUpdatingCategories = false

  categoriesLoading = false

  isUpdateSuccess = false

  error404 = false

  constructor() {
    makeAutoObservable(this)
  }

  setLocale = (locale: string) => {
    runInAction(() => {
      this.localeLang = locale
    })
  }

  setUpdatedCategories = (data: NestedCategories[]) => {
    runInAction(() => {
      this.updatedCategories = data
    })
  }

  setIsUpdatedList = (value: boolean) => {
    runInAction(() => {
      this.isListUpdated = value
    })
  }

  saveCategory = async (value: CreateCategoryModel, id?: string) => {
    runInAction(() => {
      this.isCreating = true
      this.error404 = false
    })

    const mappedLocal = Object.values(value.localization)
      .map((obj) => removeEmptyKeys(obj))
      .filter((item) => Object.keys(item).length)

    const payload = {
      ...value,
      localization: mappedLocal,
    }

    try {
      const { data } = !id
        ? await CategoriesService.createCategory(payload)
        : await CategoriesService.updateCategory(id, payload)

      runInAction(() => {
        this.createCategoryData = initialCreateCategoryData
      })
      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        return error.response?.data
      }
    } finally {
      runInAction(() => {
        this.isCreating = false
        this.currentEditableCategory = null
      })
    }
  }

  updateCategoryImage = (
    imageType: 'icon' | 'original',
    value: string | null,
  ) => {
    runInAction(() => {
      const {
        desktop = null,
        icon = null,
        mobile = null,
        original = null,
      } = this.createCategoryData.category.images || {}

      this.createCategoryData = {
        ...this.createCategoryData,
        category: {
          ...this.createCategoryData.category,
          images: {
            desktop,
            icon,
            mobile,
            original,
            [imageType]: value,
          },
        },
      }
    })
  }

  setCategoryImage = (imageType: 'icon' | 'original', url: string) => {
    this.updateCategoryImage(imageType, url)
  }

  removeCategoryImage = (imageType: 'icon' | 'original') => {
    this.updateCategoryImage(imageType, null)
  }

  deleteCategory = async (id: string) => {
    runInAction(() => {
      this.isDeleting = true
      this.error404 = false
    })

    try {
      await CategoriesService.deleteCategory(id)
    } catch (error) {
      if (error instanceof AxiosError) {
        return error.response?.data
      }
    } finally {
      runInAction(() => {
        this.isDeleting = false
      })
    }
  }

  getCategories = async (
    queryString: string,
    saveCurrentCategory?: boolean,
  ) => {
    runInAction(() => {
      if (!saveCurrentCategory) {
        this.currentEditableCategory = null
      }
      this.categoriesLoading = true
      this.error404 = false
    })

    const params = CategoriesModel.getParamsFromQueryString(queryString)

    try {
      const { data } = await CategoriesService.getCategories({
        params: {
          ...params,
          parentsOnly: 1,
        },
      })
      runInAction(() => {
        this.categories = data
        this.filteredCategories = data
      })
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.data)
      }
    } finally {
      runInAction(() => {
        this.categoriesLoading = false
      })
    }
  }

  getCategoryById = async (id: string) => {
    runInAction(() => {
      this.isGettingCategory = true
      this.error404 = false
    })

    try {
      const { data } = await CategoriesService.getCategoryById(id)
      const languages = await StoresService.getLanguages()
      const storeInfo = await StoresService.getStoreById(
        data.store.id as string,
      )
      const activeLocal = languages.data
        .filter((e) =>
          storeInfo.data.languages.some((lan) => lan.code === e.code),
        )
        .map((e) => e.code)
      const mappedLocalization = data.localizations.reduce((acc, curr) => {
        if (activeLocal.includes(curr.languageCode)) {
          Object.keys(curr).forEach((key) => {
            acc[curr.languageCode] = {
              ...acc[curr.languageCode],
              [key]: curr[key] || '',
            }
          })
        }
        return acc
      }, {} as Record<string, Localization>)

      runInAction(() => {
        this.currentEditableCategory = data

        this.createCategoryData = {
          ...this.createCategoryData,
          category: {
            ...this.createCategoryData.category,
            slug: data.slug,
            showInMenu: data.showInMenu,
            storeId: data.store.id,
            store: data.store,
            name: data.name,
            id: data.id,
            images: data.images,
            seo: data.seo,
          },
          localization: {
            ...mappedLocalization,
          },
        }
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 404) {
          runInAction(() => {
            this.error404 = true
          })
        }

        throw new Error(error.response?.data)
      }
    } finally {
      runInAction(() => {
        this.isGettingCategory = false
      })
    }
  }

  updateCategories = async (
    categories: NestedCategories[],
    storeId: string,
  ) => {
    const payload = {
      categories,
      storeId,
    }
    runInAction(() => {
      this.isUpdateSuccess = false
      this.isUpdatingCategories = true
      this.error404 = false
    })

    try {
      await CategoriesService.updateCategories(payload)

      runInAction(() => {
        this.updatedCategories = []
        this.isListUpdated = false
        this.isUpdateSuccess = true
      })
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.data)
      }
    } finally {
      runInAction(() => {
        this.isUpdatingCategories = false
        this.currentEditableCategory = null
      })
    }
  }

  filterCategoriesByName = (name: string) => {
    const copy = [...this.categories]

    const filtered = copy.filter((item) =>
      (item.localizations[0].name || item.name)
        .toLowerCase()
        .includes(name.toLowerCase()),
    )

    runInAction(() => {
      this.filteredCategories = filtered
    })
  }

  setCreateCategoryData = async (
    obj: Record<string, unknown>,
    key: keyof CreateCategoryModel,
  ) => {
    runInAction(() => {
      if (obj) {
        this.createCategoryData = {
          ...this.createCategoryData,
          [key]: {
            ...this.createCategoryData[key],
            ...obj,
          },
        }
      }
    })
    if (obj.storeId as string) {
      try {
        const { data } = await StoresService.getStoreById(obj.storeId as string)
        const languages = await StoresService.getLanguages()
        this.createCategoryData = {
          ...this.createCategoryData,
          localization: convertLocalizations<Localization>(
            languages.data
              .filter((e) =>
                data.languages.some((lang) => lang.code === e.code),
              )
              .map((e) => ({ ...defaultLocalization, languageCode: e.code })),
          ),
        }
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          throw new Error(error.response?.data)
        }
      }
    }
  }

  get getMappedData() {
    return {
      stores: mapDataForSelect(this.stores, 'id', 'name'),
      filteredCategories: mapDataForSelect(
        this.filteredCategories,
        'id',
        'name',
      ),
      categories: mapDataForSelect(
        flattenArrayWithCategories(this.categories),
        'id',
        'name',
      ),
    }
  }

  static getParamsFromQueryString(value?: string) {
    if (value?.length === 0) return {}

    const { q, ...res } = parseQuery(value || '')

    const productName = q && q.join('').length > 0 ? { q: q.join('') } : {}

    return {
      ...res,
      ...productName,
    }
  }

  setIsErrorStoreId = (value: boolean) => {
    runInAction(() => {
      this.createCategoryDataValidation = {
        ...this.createCategoryDataValidation,
        isErrorStoreId: value,
      }
    })
  }

  setIsErrorCategoryName = (value: boolean) => {
    runInAction(() => {
      this.createCategoryDataValidation = {
        ...this.createCategoryDataValidation,
        isErrorCategoryName: value,
      }
    })
  }

  setIsErrorSlug = (value: boolean) => {
    runInAction(() => {
      this.createCategoryDataValidation = {
        ...this.createCategoryDataValidation,
        isErrorSlug: value,
      }
    })
  }

  setCurrentCreateCategoryForm = (
    value: typeof this.currentCategoryCreateForm,
  ) => {
    runInAction(() => {
      this.currentCategoryCreateForm = value
    })
  }

  setDefaultCreateCategoryData = () => {
    runInAction(() => {
      this.createCategoryData = initialCreateCategoryData
    })
  }
}

export const sortOptions = {
  swap: true,
  animation: 200,
  delayOnTouchStart: true,
  delay: 2,
  fallbackOnBody: true,
  swapThreshold: 0.65,
}
