import { FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useEffectOnce } from 'usehooks-ts'
import { Props } from './types'
import { StoresFilter } from '~/entities/stores-filter'
import { useStore } from '~/app/store'

export const CreateCategoryStoresFilter: FC<Props> = observer(
  ({ data = [] }) => {
    const { categoriesStore } = useStore()
    const { setCreateCategoryData, createCategoryData, setIsErrorStoreId } =
      categoriesStore

    const storeId = useMemo(() => {
      return localStorage.getItem('categoryStoreIdFilter')
    }, [])

    const handleChange = (value: string) => {
      setIsErrorStoreId(false)
      setCreateCategoryData({ storeId: value }, 'category')
    }

    useEffectOnce(() => {
      if (data.length) {
        if (storeId) {
          setCreateCategoryData({ storeId }, 'category')
          return
        }

        handleChange(data[0].id)
      }
    })

    return (
      <StoresFilter
        data={data}
        selectedStores={[createCategoryData?.category?.storeId]}
        changeStores={handleChange}
      />
    )
  },
)
