import { useLocalStorage } from 'usehooks-ts'
import { NavType } from '../../types'
import { HandleChange } from './types'

export function useNavType(): [NavType, HandleChange] {
  const [navigationType, setNavType] = useLocalStorage<NavType>(
    'navType',
    'horizontal',
  )

  const handleChange = (type: NavType) => {
    if (type === 'horizontal') {
      setNavType('horizontal')
    } else {
      setNavType('vertical')
    }
  }

  return [navigationType, handleChange]
}
