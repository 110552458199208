/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { apiService } from '../../api-base'
import {
  EditableOrdertModel,
  HistoryArray,
  Infos,
  Orders,
  Related,
  RecipientRequestData,
  Shipping,
  Productavailable,
  GenerateDocumentData,
  Attachments,
  TransferRequestData,
  OrdersInWork,
} from './types'
import { Currencie } from '../stores/types'

export class OrdersService {
  static getOrders(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Orders[]>> {
    return apiService.get<AxiosResponse<Orders[]>>(`/order`, config)
  }

  static getDemandProductVariationId(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Orders[]>> {
    return apiService.get<AxiosResponse<Orders[]>>(`/order`, config)
  }

  static getOrdersInWork(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<OrdersInWork[]>> {
    return apiService.get<AxiosResponse<OrdersInWork[]>>(`/order/flow`, config)
  }

  static getOrderById(id: string): Promise<AxiosResponse<EditableOrdertModel>> {
    return apiService.get<AxiosResponse<EditableOrdertModel>>(`/order/${id}`)
  }

  static getInfos(id: string): Promise<AxiosResponse<Infos>> {
    return apiService.get<AxiosResponse<Infos>>(`/order/${id}/infos`)
  }

  static getHistory(id: string): Promise<AxiosResponse<HistoryArray>> {
    return apiService.get<AxiosResponse<HistoryArray>>(`/order/${id}/history`)
  }

  static getRelated(id: string): Promise<AxiosResponse<Related[]>> {
    return apiService.get<AxiosResponse<Related[]>>(`/order/${id}/related`)
  }

  static getAttachments(id: string): Promise<AxiosResponse<Attachments[]>> {
    return apiService.get<AxiosResponse<Attachments[]>>(
      `/order/${id}/attachments`,
    )
  }

  static getCurrencies(id: string): Promise<AxiosResponse<Currencie[]>> {
    return apiService.get<AxiosResponse<Currencie[]>>(`/stores/currencies`, {
      params: { storeId: id },
    })
  }

  static getAvailableProductOptions(
    id: string,
  ): Promise<AxiosResponse<Productavailable>> {
    return apiService.get<AxiosResponse<Productavailable>>(
      `/products/available/${id}`,
    )
  }

  static getProductVariation(
    id: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    return apiService.get<AxiosResponse>(
      `/products/available/${id}/variation`,
      config,
    )
  }

  static addComment(
    comment: string | null,
    id: string,
  ): Promise<AxiosResponse> {
    return apiService.put<AxiosResponse>(`/order/${id}/comment`, { comment })
  }

  static editRecipient(
    data: RecipientRequestData | null,
    id: string,
  ): Promise<AxiosResponse> {
    return apiService.put<AxiosResponse>(`/order/${id}/recipient`, {
      recipient: data,
    })
  }

  static updateShipping(
    id: string,
    data: { ddp: boolean; provider: Shipping },
  ): Promise<AxiosResponse> {
    return apiService.put<AxiosResponse>(`/order/${id}/updateShipping`, data)
  }

  static getShippingMethodsByProducts(data: {
    storeId: string
    region: string
    products: any[]
  }): Promise<AxiosResponse<Shipping[]>> {
    return apiService.post<AxiosResponse<Shipping[]>>(
      `/order/shippingMethodsByProducts`,
      data,
    )
  }

  static paymentSent(
    id: string,
    data: { isPaymentSent: string },
  ): Promise<AxiosResponse> {
    return apiService.put<AxiosResponse>(`/order/${id}/paymentSent`, data)
  }

  static addProductForOrder(
    id: string,
    data: { quantity: number; productVariationId: string },
  ): Promise<AxiosResponse> {
    return apiService.post<AxiosResponse>(`/order/${id}/items`, data)
  }

  static removeProductFromOrder(
    orderID: string,
    productID: string,
    data: { demand: boolean },
  ): Promise<AxiosResponse> {
    return apiService.deleteWithBody<AxiosResponse>(
      `/order/${orderID}/items/${productID}`,
      data,
    )
  }

  static cancelOrder(
    orderID: string,
    data: { message: string },
  ): Promise<AxiosResponse> {
    return apiService.deleteWithBody<AxiosResponse>(`/order/${orderID}`, data)
  }

  static confirmThePayment(
    id: string,
    data: { orderId: string; paymentId: string },
  ): Promise<AxiosResponse> {
    return apiService.post<AxiosResponse>(`/order/${id}/confirmPayment`, data)
  }

  static generateDocument(data: GenerateDocumentData): Promise<AxiosResponse> {
    return apiService.post<AxiosResponse>(`/order/generateDocument`, data)
  }

  static createReshipOrder(data: {
    comment: string
    orderId: string
  }): Promise<AxiosResponse> {
    return apiService.post<AxiosResponse>(`order/createReshipOrder`, data)
  }

  static setTransferItem(data: TransferRequestData): Promise<AxiosResponse> {
    return apiService.post<AxiosResponse>(
      `order/transfer/${data.demandId ? 'demand' : 'item'}`,
      data,
    )
  }
}
