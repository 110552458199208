/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosError } from 'axios'
import { MarketingService } from '~/shared/api/services/marketing'
import {
  Coupon,
  FiltersCouponType,
  FormDataCoupon,
  UpdateCoupon,
} from '~/shared/api/services/marketing/types'
import {
  TYPES_COUPON,
  parseFormData,
  VARIANT_TYPES,
  determineStatus,
  handleAxiosError,
} from '~/shared/lib'
import { store } from '~/app/store'
import { SelectOption } from '~/shared/ui'

export class CouponStore {
  requestSettingsCoupons = {
    limit: 15,
    offset: 0,
  }

  filtersСoupon: FiltersCouponType = {
    code: '',
    status: null,
    type: null,
    date: [],
    categoryId: null,
    storeId: null,
  }

  variantTypeId = 1

  typeIdCoupon = 1

  coupons: Coupon[] = []

  q = ''

  statesCoupon = [
    { value: 'Active', label: 'Активно' },
    { value: 'Inactive', label: 'Неактивно' },
    { value: 'Finished', label: 'Завершено' },
  ]

  formDataCoupon: FormDataCoupon = {
    dateEnd: null,
    dateStart: null,
    sale: '',
    legalEntityId: '',
    fixedDiscount: '',
    maxCountUse: '',
    storeId: null,
    categoryId: null,
    code: '',
    typeArea: '',
    type: '',
    minOrderPrice: '',
  }

  subContentOpens: string[] = []

  updateCoupons: UpdateCoupon[] = []

  totalPagesCoupons = 0

  constructor() {
    makeAutoObservable(this)
  }

  getCoupons = async (action?: 'loadMore') => {
    if (
      action === 'loadMore' &&
      this.requestSettingsCoupons.offset >=
        this.totalPagesCoupons * this.requestSettingsCoupons.limit
    ) {
      return
    }

    store.marketingStore.activeLoader(action ? 'loadMoreCoupons' : 'coupons')
    try {
      const params: { offset: number; limit: number; [key: string]: any } = {
        offset: action ? this.requestSettingsCoupons.offset : 0,
        limit: this.requestSettingsCoupons.limit,
      }

      const filterKeys = Object.keys(
        this.filtersСoupon,
      ) as (keyof FiltersCouponType)[]
      filterKeys.forEach((filterKey) => {
        if (filterKey === 'status') {
          if (
            this.filtersСoupon.status?.value &&
            this.filtersСoupon.status.value !== 'All'
          ) {
            params[filterKey] = this.filtersСoupon.status.value
          }
        } else if (filterKey === 'code' && this.filtersСoupon[filterKey]) {
          params[filterKey] = this.filtersСoupon.code
        } else if (
          filterKey !== 'date' &&
          filterKey !== 'code' &&
          (this.filtersСoupon[filterKey]?.value as string)
        ) {
          params[filterKey] = this.filtersСoupon[filterKey]?.value
        } else if (filterKey === 'date' && this.filtersСoupon.date?.length) {
          const [dateStart] = this.filtersСoupon.date
          params.dateStart = dateStart
          params.dateEnd =
            this.filtersСoupon.date[this.filtersСoupon.date.length - 1]
        }
      })

      const resp = await MarketingService.getCoupons({ params })
      const { data } = resp
      const { totalPages }: { totalPages: number } = resp as any

      runInAction(() => {
        this.coupons = action
          ? [...this.coupons, ...data.coupons]
          : data.coupons
        const emptyString: unknown = ''
        this.updateCoupons = this.coupons.map((e) => ({
          dateEnd: e.dateEnd ? e.dateEnd : (emptyString as Date),
          dateStart: e.dateStart ? e.dateStart : (emptyString as Date),
          maxCountUse: e.maxCountUse ? e.maxCountUse : '',
          minOrderPrice: e.minOrderPrice ? e.minOrderPrice : '',
          timeEnd: e.timeEnd || '',
          timeStart: e.timeStart || '',
          id: e.id,
          state: e.state,
          code: e.code,
        }))

        this.requestSettingsCoupons = {
          ...this.requestSettingsCoupons,
          offset:
            this.requestSettingsCoupons.offset +
            this.requestSettingsCoupons.limit,
        }
        this.totalPagesCoupons = totalPages
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw error.response?.data.error
      }
    }
    store.marketingStore.closeLoader(action ? 'loadMoreCoupons' : 'coupons')
  }

  setIdsSubContentOpens = (id: string) => {
    this.subContentOpens = this.subContentOpens.includes(id)
      ? this.subContentOpens.filter((e) => e !== id)
      : [...this.subContentOpens, id]
  }

  updateCoupon = async (id: string) => {
    try {
      const coupon = this.updateCoupons.find((e) => e.id === id)

      if (coupon) {
        const status = determineStatus(coupon.dateStart, coupon.dateEnd)

        coupon.state = status

        await MarketingService.updateCoupon(coupon)

        this.coupons = this.coupons.map((e) =>
          e.id === id ? { ...e, ...coupon } : e,
        )
      }
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    }
  }

  createCoupon = async () => {
    try {
      store.marketingStore.activeLoader('createCoupon')

      const variant = VARIANT_TYPES.find((e) => e.id === this.variantTypeId)
      const type = TYPES_COUPON.find((e) => e.id === this.typeIdCoupon)

      if (variant?.value && type?.value) {
        await MarketingService.createCoupon({
          ...parseFormData<FormDataCoupon>(this.formDataCoupon),
          typeArea: variant.value as 'All' | 'Category',
          type: type.value as 'Percent' | 'Fixed',
        })

        this.requestSettingsCoupons = { offset: 0, limit: 15 }
        await this.getCoupons()
        this.variantTypeId = 1
        this.typeIdCoupon = 1
      }
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      store.marketingStore.closeLoader('createCoupon')
    }
  }

  deleteCoupon = async (id: string) => {
    try {
      store.marketingStore.activeLoader('deleteCoupon')
      await MarketingService.deleteCoupon(id)
      await this.getCoupons()
      runInAction(() => {
        this.coupons = this.coupons.filter((coupon) => coupon.id !== id)
        this.updateCoupons = this.updateCoupons.filter(
          (coupon) => coupon.id !== id,
        )
      })
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      store.marketingStore.closeLoader('deleteCoupon')
    }
  }

  setUpdateCoupons = ({
    id,
    name,
    value,
  }: {
    id: string
    name: string
    value: Date | null | string
  }) => {
    this.updateCoupons = this.updateCoupons.map((coupon) =>
      coupon.id === id ? { ...coupon, [name]: value } : coupon,
    )
  }

  setFormDataCoupon = (
    name: keyof FormDataCoupon,
    value: string | SelectOption | number | Date,
  ) => {
    this.formDataCoupon = { ...this.formDataCoupon, [name]: value }
  }

  setFiltersCoupon = (
    name: keyof FiltersCouponType,
    value: Date[] | SelectOption | string | null,
  ) => {
    this.filtersСoupon = { ...this.filtersСoupon, [name]: value }
  }

  clearFiltersCoupons = () => {
    this.requestSettingsCoupons = {
      limit: 15,
      offset: 0,
    }
    this.filtersСoupon = {
      code: '',
      status: null,
      type: null,
      date: [],
      categoryId: null,
      storeId: null,
    }
  }

  clearFormDataCoupon = () => {
    this.formDataCoupon = {
      dateEnd: null,
      dateStart: null,
      sale: '',
      fixedDiscount: '',
      maxCountUse: '',
      storeId: null,
      categoryId: null,
      code: '',
      minOrderPrice: '',
    }
  }

  setVariantType = (value: number) => {
    runInAction(() => {
      this.variantTypeId = value
      this.formDataCoupon = {
        ...this.formDataCoupon,
        categoryId: null,
      }
    })
  }

  setTypeIdCoupon = (value: number) => {
    runInAction(() => {
      this.typeIdCoupon = value
      this.formDataCoupon = {
        ...this.formDataCoupon,
        sale: '',
        fixedDiscount: '',
      }
    })
  }
}

export const discountStore = new CouponStore()
