import { Table } from '@tanstack/react-table'
import React from 'react'

interface Props<T> {
  table: Table<T>
}

export function usePagination<T>({ table }: Props<T>) {
  const firstPage = () => table.setPageIndex(0)
  const isPreviousPage = table.getCanPreviousPage()
  const previousPage = () => table.previousPage()

  const nextPage = () => table.nextPage()
  const isNextPage = table.getCanNextPage()
  const lastPage = () => table.setPageIndex(table.getPageCount() - 1)

  const currentPage = table.getState().pagination.pageIndex + 1
  const allPages = table.getPageCount()

  const pageSize = Number(table.getState().pagination.pageSize)
  const handlePagination = (e: React.ChangeEvent<HTMLSelectElement>) => {
    table.setPageSize(Number(e.target.value))
  }

  return {
    firstPage,
    isNextPage,
    previousPage,
    nextPage,
    isPreviousPage,
    lastPage,
    currentPage,
    allPages,
    pageSize,
    handlePagination,
  }
}
