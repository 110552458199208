export const SVGFolder = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4.25003 19.5C3.76412 19.5 3.35098 19.3298 3.0106 18.9894C2.67022 18.649 2.50002 18.2359 2.50002 17.75V6.69238C2.50002 6.2065 2.67983 5.78375 3.03945 5.42413C3.39907 5.06451 3.82182 4.8847 4.30772 4.8847H9.7712C9.96735 4.8847 10.1558 4.92189 10.3366 4.99625C10.5173 5.0706 10.677 5.17701 10.8154 5.31548L12 6.50005H19.6923C20.1397 6.50005 20.5256 6.6446 20.85 6.9337C21.1743 7.2228 21.3621 7.57825 21.4134 8.00003H11.3865L9.7712 6.38468H4.30772C4.21797 6.38468 4.14425 6.41353 4.08655 6.47123C4.02885 6.52891 4 6.60263 4 6.69238V17.6923C4 17.7628 4.01763 17.8205 4.0529 17.8654C4.08815 17.9103 4.13462 17.9487 4.19232 17.9808L6.26347 11.0692C6.36476 10.7449 6.54969 10.4856 6.81827 10.2914C7.08686 10.0971 7.38333 10 7.70767 10H21.2135C21.7237 10 22.1266 10.2019 22.4221 10.6058C22.7176 11.0096 22.7961 11.4513 22.6577 11.9308L20.7615 18.2577C20.6474 18.6333 20.4336 18.9343 20.1202 19.1606C19.8067 19.3869 19.459 19.5 19.0769 19.5H4.25003ZM5.76343 18H19.2693L21.2135 11.5H7.70767L5.76343 18Z"
        fill="#bdbdbd"
      />
    </svg>
  )
}
