import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import css from 'classnames'
import { Props } from './types'
import { NavLinksSVG } from '~/shared/ui'

type IconType =
  | 'settings'
  | 'dashboard'
  | 'person'
  | 'notifications'
  | 'stores'
  | 'orders'
  | 'warehouse'
export const NavigationLink: FC<Props> = ({
  text,
  link,
  isOpen,
  icon,
  className,
  activeClass = '',
}) => {
  return (
    <NavLink
      className={({ isActive }) => {
        return css(
          'flex relative hover:bg-slate-50 p-3 rounded transition-all items-center gap-4 text-md',
          { [activeClass]: isActive },
          { '[&>span]:text-blue-600': isActive },
          { '[&~div]:opacity-100': isActive },
          { '[&~span]:opacity-100': isActive },
          className,
        )
      }}
      to={link}
    >
      {icon ? <NavLinksSVG icon={icon as IconType} /> : null}
      <p className={css({ hidden: !isOpen })}>{text}</p>
    </NavLink>
  )
}
