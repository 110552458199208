import { useEffect } from 'react'

interface Props {
  title?: string
}

export const usePageTitle = (props: Props) => {
  const { title = 'Default title' } = props

  useEffect(() => {
    const newTitle = `${title} - Mate Line`
    document.title = newTitle
  }, [title])
}
