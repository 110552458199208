/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from '~/app/store'
import { Suggestions } from '~/shared/api/services/registration/types'
import { debounce, useForm, usePageTitle, useToast } from '~/shared/lib'
import { generateUEID } from '~/shared/lib/helper-functions/ueid'
import { Button, CustomSelect, SelectOption } from '~/shared/ui'
import { SVGLogo } from '~/shared/ui/SVG/SVGLogo'
import { Input } from '~/shared/ui/input'
import { DadataInfo } from '../registrationDaData'
import logo from '../../shared/assets/mate-line-reg-preview.png'

export const Registration = observer(() => {
  const { registrationStore } = useStore()
  const [innOptions, setInnOptions] = useState([] as SelectOption[])
  const [originData, setOriginOptionData] = useState([] as Suggestions[])
  const [searchLoading, setSearchLoading] = useState<boolean>(false)
  const [isShowPassword, setIsShowPassword] = useState({
    password: false,
    passwordRepeat: false,
  })
  const [dadata, setDadata] = useState<Suggestions | null>(null)
  const [search, setSearch] = useState('')
  const [agree, setAgree] = useState(false)
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({})

  const navigate = useNavigate()
  const toast = useToast()
  usePageTitle({
    title: 'Регистрация',
  })

  const debouncedSearch = useMemo(() => {
    return debounce((value) => {
      try {
        fetch(`${import.meta.env.VITE_DADATA_URL}`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Token ${import.meta.env.VITE_DADATA_TOKEN}`,
          },
          body: JSON.stringify({ query: value }),
        })
          .then((response) => response.json())
          .then((data: { suggestions: Suggestions[] }) => {
            const { suggestions } = data
            setInnOptions(
              suggestions.map((el) => ({
                value: `${el.value}_${value}`,
                label: el.unrestricted_value,
              })),
            )
            setOriginOptionData(suggestions)
          })
      } catch (error: any) {
        toast.open(
          'Ошибка при загрузке данных. Пожалуйста, попробуйте позже.',
          'danger',
        )
      } finally {
        setSearchLoading(false)
      }
    }, 800)
  }, [toast])

  const {
    handleSubmit: handleSubmitRegistrationInfo,
    handleChange,
    errors: userInfoErrors,
  } = useForm({
    initialValues: {
      name: '',
      password: '',
      passwordRepeat: '',
      email: '',
    },
    validations: {
      email: {
        pattern: {
          value: /^\S+@\S+\.\S{2,}$/,
          message: 'Некорректный email',
        },
      },
      password: {
        custom: {
          isValid: (value) => value.length >= 8,
          message: 'Пароль должен содержать не менее 8 символов',
        },
      },
      name: {
        custom: {
          isValid: (value) => value.trim().length >= 1 && !/^\s/.test(value),
          message: 'Введите название магазина',
        },
      },
      passwordRepeat: {
        custom: {
          isValid: (value, values) => value === values.password,
          message: 'Пароли должны совпадать',
        },
      },
    },
    onSubmit: (inputsData) => {
      if (!dadata || dadata.data.inn.length !== 10) {
        toast.open('Введите корректный ИНН!', 'danger')
        return
      }
      const { email, password, name } = inputsData
      if (dadata) {
        registrationStore.validate({
          user: {
            email,
            password,
            name: dadata.data.management?.name || 'Отсутствует',
          },
          store: { name },
          legalEntity: {
            name,
            director: dadata.data.management?.name || 'Отсутствует',
            inn: dadata.data.inn,
            kpp: dadata.data.kpp,
            realAddress: dadata.data.address.unrestricted_value,
          },
        })
        registrationStore
          .updateRegistration({
            user: {
              email,
              password,
              name: dadata.data.management?.name || 'Отсутствует',
            },
            store: { name },
            legalEntity: {
              name,
              director: dadata.data.management?.name || 'Отсутствует',
              inn: dadata.data.inn,
              kpp: dadata.data.kpp,
              realAddress: dadata.data.address.unrestricted_value,
            },
          })
          .then(() => {
            navigate('/login')
          })
          .catch((err: string[]) => {
            toast.open(
              err.length ? err.join(', ') : 'Invalid user data!',
              'danger',
            )
          })
      }
    },
  })

  const togglePasswordVisibility = (type: keyof typeof isShowPassword) => {
    setIsShowPassword((prev) => {
      return {
        [type]: !prev[type],
      } as typeof isShowPassword
    })
  }
  return (
    <div className="flex flex-col md:flex-row justify-between p-8 sm:p-10 md:p-14 lg:p-24 xl:p-28 w-full  ">
      <div className="w-full md:w-1/2 lg:w-2/5  ">
        <div className="flex items-center gap-3 mb-4 md:mb-10 lg:pl-8 xl:pl-8">
          <SVGLogo />
          <div>
            <p className="text-[18px] text-[#242424]">Платформа Mate Line</p>
          </div>
        </div>

        <p className="text-[20px]   lg:text-[22px] xl:text-[30px] 2xl:text-[32px] text-[#242424] w-full md:w-[80%] lg:w-[80%] xl:w-[80%] lg:pl-8 xl:pl-8">
          Создайте свой собственный магазин за пару кликов.
        </p>
        <p className="text-[14px] lg:text-[14px] xl:text-[16px] my-3 md:mt-2 md:mb-2 w-full md:w-[80%] text-[#707070] lg:w-[80%] xl:w-[80%] lg:pl-8 xl:pl-8">
          Продавайте на сайте, маркетплейсах и в соц сетях из единой панели
          управления Mate Line.
        </p>
        <div className="w-[100%] h-[60%] md:w-[80%] md:h-[40%] lg:w-[90%] lg:h-[45%]">
          <img
            src={logo}
            alt="girl"
            className="hidden lg:block xl:block 2xl:block mt-4 md:mt-0 lg:mt-4 xl:mt-6  w-full h-full object-contain "
          />
        </div>
      </div>
      <form className="flex flex-col gap-4 border border-gray-200 shadow-md p-4 md:p-10 rounded-lg w-full md:w-[60%] lg:w-[55%] xl:h-[70%] h-full">
        <Input
          label="Название магазина"
          labelType="floating"
          name="name"
          className="rounded-lg"
          id={generateUEID()}
          helperText={userInfoErrors.name}
          status={userInfoErrors.name ? 'error' : undefined}
          onChange={handleChange('name')}
        />
        {dadata ? (
          <DadataInfo
            {...dadata}
            setAgree={setAgree}
            setDadata={setDadata}
            agree={agree}
          />
        ) : (
          <CustomSelect
            placeholder="Введите ИНН Вашей компании"
            labelType="floating"
            name="inn"
            value={null}
            onInputChange={(query) => {
              setSearchLoading(true)
              setSearch(query)
              debouncedSearch(query)
            }}
            onChange={(selectOption) => {
              if (selectOption) {
                const { label } = selectOption as SelectOption
                const findOriginData = originData.find(
                  (el) => el.unrestricted_value === label,
                )
                if (findOriginData) {
                  setDadata(findOriginData)
                }
              } else {
                setDadata(null)
              }
            }}
            inputValue={search}
            options={innOptions}
            isLoading={searchLoading}
            isClearable
          />
        )}

        {agree && (
          <>
            <hr className="hr p-2" />
            <Input
              label="Ваш email"
              id={generateUEID()}
              type="email"
              labelType="floating"
              className="rounded-lg"
              helperText={
                userInfoErrors.email ||
                validationErrors['user.email']?.replace(/^Error:\s*/, '')
              }
              status={
                userInfoErrors.email || validationErrors['user.email']
                  ? 'error'
                  : undefined
              }
              onChange={handleChange('email')}
              onBlur={(e) => {
                const email = e.target.value
                if (email.trim() === '') {
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    'user.email': 'Некорректный email',
                  }))
                } else {
                  registrationStore
                    .validate({ user: { email: e.target.value } })
                    .then((errors) => {
                      if (errors) {
                        setValidationErrors((prevErrors) => {
                          return {
                            ...prevErrors,
                            'user.email':
                              errors['user.email'] || errors.defaultError,
                          }
                        })
                      } else {
                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          'user.email': '',
                        }))
                      }
                    })
                    .catch((error: any) => {
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        'user.email': error.toString(),
                      }))
                    })
                }
              }}
            />

            <Input
              label="Пароль"
              name="password"
              id={generateUEID()}
              type={isShowPassword.password ? 'text' : 'password'}
              labelType="floating"
              className="rounded-lg"
              helperText={userInfoErrors.password}
              status={userInfoErrors.password ? 'error' : undefined}
              showPassword={() => togglePasswordVisibility('password')}
              onChange={handleChange('password')}
              autoComplete="new-password"
            />
            <Input
              label="Пароль еще раз"
              id={generateUEID()}
              name="newPassword"
              type={isShowPassword.passwordRepeat ? 'text' : 'password'}
              labelType="floating"
              className="rounded-lg"
              helperText={userInfoErrors.passwordRepeat}
              status={userInfoErrors.passwordRepeat ? 'error' : undefined}
              showPassword={() => togglePasswordVisibility('passwordRepeat')}
              onChange={handleChange('passwordRepeat')}
              autoComplete="new-password"
            />

            <Button
              size="medium"
              className="flex items-center justify-center"
              onClick={handleSubmitRegistrationInfo}
              disabled={!agree}
            >
              Создать магазин
            </Button>
          </>
        )}
      </form>
    </div>
  )
})

export default Registration
