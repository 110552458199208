/* eslint-disable react/require-default-props */
import { FC, HTMLAttributes } from 'react'

interface SVGLogoProps extends HTMLAttributes<SVGSVGElement> {
  width?: string
  height?: string
}

export const SVGLogo: FC<SVGLogoProps> = ({ width, height }) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width || '55'}
      height={height || '55'}
      viewBox="0 0 210 210"
      id="logo-light"
    >
      <rect x="12" width="28.6154" height="122.235" fill="#4579DE" />
      <rect
        x="51.3462"
        y="1.52588e-05"
        width="28.6154"
        height="122.235"
        fill="#4579DE"
      />
      <rect
        x="90.6922"
        y="1.52588e-05"
        width="28.6154"
        height="122.235"
        fill="#4579DE"
      />
      <rect
        x="130.038"
        y="1.52588e-05"
        width="28.6154"
        height="122.235"
        fill="#303030"
      />
      <rect
        x="169.385"
        y="92.8987"
        width="28.6154"
        height="29.3364"
        fill="#303030"
      />
      <g clipPath="url(#clip0_1000_51717)">
        <path
          d="M47.6993 174.307L47.5853 155.628L38.9233 170.618H32.9966L24.3345 156.272V174.307H12.0823V133.318H23.1378L36.1309 155.159L48.7821 133.318H59.8376L59.9515 174.307H47.6993ZM99.3357 167.163H84.1771L81.4987 174.307H67.8217L85.2598 133.318H98.4808L115.919 174.307H102.014L99.3357 167.163ZM95.6315 157.209L91.7564 146.903L87.8812 157.209H95.6315ZM130.423 144.034H118.74V133.318H155.554V144.034H143.872V174.307H130.423V144.034ZM197.912 163.884V174.307H164.746V133.318H197.172V143.741H177.967V148.542H194.835V158.497H177.967V163.884H197.912Z"
          fill="#4579DE"
        />
      </g>
      <g clipPath="url(#clip1_1000_51717)">
        <path
          d="M65.8808 185.357H71.4437V205.353H83.4965V210H65.8808V185.357ZM86.6046 185.357H92.1674V210H86.6046V185.357ZM119.917 185.357V210H115.35L103.366 195.038V210H97.8719V185.357H102.473L114.423 200.319V185.357H119.917ZM144.216 205.424V210H125.604V185.357H143.769V189.934H131.133V195.285H142.293V199.721H131.133V205.424H144.216Z"
          fill="#303030"
        />
      </g>
      <rect x="12" y="195.332" width="45.3077" height="4.8894" fill="#303030" />
      <rect
        x="152.692"
        y="195.332"
        width="45.3077"
        height="4.8894"
        fill="#303030"
      />
      <defs>
        <clipPath id="clip0_1000_51717">
          <rect
            width="186"
            height="41.071"
            fill="white"
            transform="translate(12 133.236)"
          />
        </clipPath>
        <clipPath id="clip1_1000_51717">
          <rect
            width="78.6923"
            height="24.6915"
            fill="white"
            transform="translate(65.6538 185.309)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
