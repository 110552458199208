import { FC } from 'react'
import { NavigationProps } from './types'
import { navigation } from './config'
import { NavMenuItem } from '../nav-item'
import { menuLinks } from '~/shared/lib'

export const Navigation: FC<NavigationProps> = ({ className }) => {
  return (
    <ul className={navigation({ className })}>
      {menuLinks?.map((item, i) => (
        <NavMenuItem key={item.link} item={item} index={i + 1} />
      ))}
    </ul>
  )
}
