import { forwardRef, useMemo } from 'react'
import css from 'classnames'
import { InputProps } from './types'
import { input } from './config'
import { InputType } from '~/shared/lib'
import { PresentSVG } from '..'
import { SVGEye } from '../SVG/SVGEye'
import { PlusSVG } from '../SVG/PlusSVG'
import { SVGCalendar } from '../SVG/SVGCalendar'
import RoundButton from '../button-rounded'
import { SVGMoney } from '../SVG/svgMoney'

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      disabled = false,
      placeholder = '',
      type = 'text',
      id = '',
      className,
      intent,
      showPassword,
      label,
      name,
      onChange,
      onFocus,
      onBlur,
      title,
      status,
      onKeyDown,
      helperText,
      postElement,
      labelType = 'custom',
      defaultValue,
      autoComplete = 'off',
      value,
      autoFocus = false,
      textfieldClasses,
      crossRemove,
      direction = 'column',
      labelClasses,
      addIcon,
      precent,
      money,
      date,
      size,
      addIconClick,
      renderIconEnd,
      errorHelperClasses = '',
      require,
      datePicker,
    },
    ref,
  ) => {
    const statuses = css({
      '': !status,
      'text-green-500': status === 'success',
      'text-red-500 border-red-500 hover:border-red-600 border-red-500 border-b-red-500':
        status === 'error' || value === '',
    })
    const inputValue = useMemo(() => (value ? { value } : {}), [value])

    return (
      <div
        className={css('flex gap-1 relative w-full', textfieldClasses, {
          'flex-col': direction === 'column',
        })}
      >
        <div className="flex gap-3 items-center relative w-full textfield">
          <input
            ref={ref}
            disabled={disabled}
            type={type}
            id={id}
            name={name}
            value={value}
            onKeyDown={onKeyDown}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder={placeholder}
            defaultValue={defaultValue}
            title={title}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...inputValue}
            className={css(
              input({ className, intent, size }),
              { 'border-error border-b-error': status === 'error' },
              {
                'pr-12': name === InputType.PASSWORD,
              },
              {
                'pt-[12px]': size === 'average',
                'pt-4': labelType === 'floating',
                'pt-2 h-[48px]': labelType === 'custom',
                'h-[48px]': labelType === 'floating' && !size,
              },
              'w-full peer ',
              { '[&~span]:hidden': inputValue?.value },
              {
                'placeholder:text-transparent':
                  label && labelType === 'floating',
              },
              { 'border-yellow-500': !value && require },
              { 'focus:border-b-yellow-500': !value && require },
              { 'border-b-yellow-500': !value && require },
              { 'pl-4': datePicker && value },
            )}
          />

          {(label && labelType === 'floating' && !value) ||
          (value && size !== 'small') ? (
            <label
              htmlFor={id}
              className={css(
                `absolute  text-gray-400  left-0 ${
                  size !== 'small' && 'top-[15px]'
                } ml-2 -translate-y-[15px] bg-none px-1 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-14px
               peer-placeholder-shown:text-gray-400 peer-focus:ml-2 ${
                 size === 'small'
                   ? 'peer-focus:-translate-y-[11px]'
                   : 'peer-focus:-translate-y-[15px] '
               } peer-focus:px-1 peer-focus:text-[12px] text-[12px]  overflow-hidden `,
                statuses,
              )}
            >
              {label}
            </label>
          ) : null}

          {label && labelType === 'custom' ? (
            <label
              htmlFor={id}
              className={css(statuses, 'text-sm text-slate-500', labelClasses, {
                'text-xs': size === 'small',
              })}
            >
              {label}
            </label>
          ) : null}

          {['password', 'newPassword', 'confirmPassword'].includes(
            String(name),
          ) ? (
            <button
              type="button"
              onClick={showPassword}
              tabIndex={-1}
              className="absolute right-3 flex items-center justify-center"
            >
              {type === 'password' ? (
                <SVGEye icon="visibility" />
              ) : (
                <SVGEye icon="visibilityOff" />
              )}
            </button>
          ) : null}

          {postElement}

          {renderIconEnd ? (
            <div className="absolute right-0">{renderIconEnd}</div>
          ) : null}
        </div>

        {precent ? (
          <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
            <PresentSVG />
          </div>
        ) : null}
        {money ? (
          <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
            <SVGMoney color="#707070" />
          </div>
        ) : null}

        {date ? (
          <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
            <SVGCalendar />
          </div>
        ) : null}
        {crossRemove ? (
          <div
            className={`${
              size === 'small' ? 'top-[24px]' : ' top-[26.5px]'
            } absolute right-2  transform -translate-y-1/2`}
          >
            <RoundButton onChange={onChange} />
          </div>
        ) : null}

        {addIcon ? (
          <div
            className={css('absolute right-3 top-5 cursor-pointer', {
              'top-[1rem]': size === 'average',
            })}
            onClick={addIconClick}
          >
            <PlusSVG size="18" />
          </div>
        ) : null}

        {helperText ? (
          <p className={css(statuses, errorHelperClasses, 'mt-1 text-[12px]')}>
            {helperText}
          </p>
        ) : null}
      </div>
    )
  },
)

export { Input }
