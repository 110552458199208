export const SVGDriveFileMove = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M16.0004 21.4666L20.1337 17.3332L16.0004 13.1999L15.3337 13.8666L18.3337 16.8666H11.8671V17.7999H18.3337L15.3337 20.7999L16.0004 21.4666ZM6.40039 24.9332C5.82261 24.9332 5.34484 24.7443 4.96706 24.3666C4.58928 23.9888 4.40039 23.511 4.40039 22.9332V10.3999C4.40039 9.82212 4.58928 9.34435 4.96706 8.96657C5.34484 8.58879 5.82261 8.3999 6.40039 8.3999H14.6671L16.0004 9.73324H25.6004C26.1782 9.73324 26.6559 9.92212 27.0337 10.2999C27.4115 10.6777 27.6004 11.1555 27.6004 11.7332V22.9332C27.6004 23.511 27.4115 23.9888 27.0337 24.3666C26.6559 24.7443 26.1782 24.9332 25.6004 24.9332H6.40039ZM5.33372 10.3999V22.9332C5.33372 23.2443 5.43372 23.4999 5.63372 23.6999C5.83372 23.8999 6.08928 23.9999 6.40039 23.9999H25.6004C25.9115 23.9999 26.1671 23.8999 26.3671 23.6999C26.5671 23.4999 26.6671 23.2443 26.6671 22.9332V11.7332C26.6671 11.4221 26.5671 11.1666 26.3671 10.9666C26.1671 10.7666 25.9115 10.6666 25.6004 10.6666H15.6337L14.3004 9.33324H6.40039C6.08928 9.33324 5.83372 9.43324 5.63372 9.63324C5.43372 9.83324 5.33372 10.0888 5.33372 10.3999ZM5.33372 10.3999V9.33324V23.9999V10.3999Z"
        fill="#707070"
      />
    </svg>
  )
}
