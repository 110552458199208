import { useDrop } from 'react-dnd'
import css from 'classnames'
import { useTranslation } from 'react-i18next'
import { NestedCategories } from '~/shared/api'
import { SVGDriveFileMove } from '~/shared/ui'

interface Props {
  parentElement: NestedCategories
  updateList: (id: string, data: NestedCategories) => void
  // eslint-disable-next-line react/require-default-props
  onDrop?: () => void
}

export const DropZone = ({ parentElement, updateList, onDrop }: Props) => {
  const { t } = useTranslation()

  const [collectedProps, drop] = useDrop(() => ({
    accept: ['category', 'sub-category', 'sub-category-2', 'sub-category-3'],
    drop: (item: NestedCategories) => {
      updateList(item.id, parentElement)
      onDrop?.()
    },

    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }))

  return (
    <div
      ref={drop}
      className={css(
        'w-full max-h-[200px] h-auto font-light border-gray-300  dashed px-6 rounded-lg flex items-center gap-3 justify-center',
        {
          'bg-[#D1D1D1] ': collectedProps.isOver,
        },
      )}
    >
      <SVGDriveFileMove />
      <span className="text-gray-600 text-[14px]">{t('dndCategory')}</span>
    </div>
  )
}
