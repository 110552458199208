import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Card, PlusSVG, SVGCloseButton } from '~/shared/ui'
import { Input } from '~/shared/ui/input'
import { Props } from './types'

export const ProductCreateChooseOption: FC<Props> = observer(
  ({ handleChange, closeAddNewOption, data, selectOption }) => {
    return (
      <Card className="min-w-[280px] rounded-[4px] border border-[#E0E0E0]">
        <div className="flex items-center gap-3 justify-between mb-3">
          <Input
            onChange={handleChange}
            label="Выбрать опцию"
            labelType="floating"
            size="average"
          />

          <div
            onClick={closeAddNewOption}
            className="flex justify-center items-center  p-2 cursor-pointer rounded transition"
          >
            <SVGCloseButton />
          </div>
        </div>

        <div className="flex flex-col gap-2 overflow-y-scroll min-h-[200px] max-h-[200px] pr-1">
          {data.length ? (
            data.map((item) => (
              <div
                onClick={() => selectOption(item)}
                key={item.id}
                className=" hover:bg-gray-200 cursor-pointer transition text-gray-950 text-[14px] p-2 w-full rounded"
              >
                {item.name || 'null'}
              </div>
            ))
          ) : (
            <h2 className="text-[14px] mx-auto mt-5">Ничего не найдено!</h2>
          )}
        </div>

        <div className="flex items-center gap-2 mt-3 cursor-pointer">
          <PlusSVG size="14" />

          <span className="text-[#424242] text-[12px]">
            Создать новую опцию
          </span>
        </div>
      </Card>
    )
  },
)
