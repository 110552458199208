import { FC, useEffect, useState } from 'react'
import css from 'classnames'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUpdateEffect, useEffectOnce } from 'usehooks-ts'
import { Props } from './types'
import { SubMenuItem } from '../sub-menu-item'
import { SVGProduct, ArrowToDown } from '~/shared/ui'

export const SubMenu: FC<Props> = ({ item, isOpen, openMenu }) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const { pathname } = useLocation()

  const toggleSubMenu = () => setExpanded((prev) => !prev)

  const activeLink = item.subLinks?.find((link) => link.link === pathname)
  const isSubLinkActive = !!item.subLinks?.filter((l) =>
    pathname.includes(l.link.toLowerCase()),
  ).length

  const handleClick = () => {
    toggleSubMenu()

    if (!isOpen) {
      openMenu()
    }
  }

  useEffect(() => {
    if (isSubLinkActive) {
      setExpanded(true)
    }
  }, [isSubLinkActive])

  useUpdateEffect(() => {
    if (!isOpen) {
      setExpanded(false)
    }

    if (isSubLinkActive && isOpen) {
      setExpanded(true)
    }
  }, [isOpen])

  useUpdateEffect(() => {
    if (!pathname.includes(item.link) && !activeLink && !isSubLinkActive) {
      setExpanded(false)
    }
  }, [pathname])

  useEffectOnce(() => {
    if (activeLink && !expanded) {
      setExpanded(true)
    }
  })
  return (
    <li
      className={css({
        'bg-gray-50 rounded relative first:[&>div>span]:text-blue-600 ':
          pathname.includes(item.link) || activeLink || expanded,
      })}
    >
      <div
        className={css(
          'flex cursor-pointer relative hover:bg-gray-50  p-3 rounded group transition-all text-black-700 items-center gap-4 text-md',
        )}
        onClick={handleClick}
      >
        <span
          className={css(
            'w-[3px] h-4 rounded-md bg-blue-600 absolute left-0 my-auto opacity-0 group-hover:opacity-100 transition-all ',
            {
              hidden: !isOpen,
            },
          )}
        />
        <div>
          <SVGProduct />
        </div>
        <p className={css({ hidden: !isOpen })}>{t(item.localeTextName)}</p>
        <ArrowToDown
          className={`ml-auto flex items-center justify-center duration-150  rotate-${
            expanded ? 180 : 0
          }`}
        />
      </div>

      {isOpen ? (
        <SubMenuItem items={item?.subLinks || []} expanded={expanded} />
      ) : null}
    </li>
  )
}
